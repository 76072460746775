import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Tabs, message, Avatar } from "antd";
import {
  IoCloudUploadOutline,
  IoCloudUploadSharp,
  IoIdCard,
} from "react-icons/io5";
import {
  ApiTwoTone,
  DeleteOutlined,
  IdcardOutlined,
  SettingOutlined,
  FileDoneOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { getCurrentUser } from "../../share/getProfile";
import { request } from "../../share/request";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { linkPathIDCard } from "../../share/helper";
import BotTele from "../telegrambot/BotPage";
import UserSystem from "./UserSystem";

const LeaveTemplate = ({ idorg }) => {
  const inputRefLeaveTemplate_v1 = useRef(null); // v1 is version 1.0

  const [file_LeaveTemplate_v1, setFileLeaveTemplate_v1] = useState(null);

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [ID_LeaveV1, setIDLeaveV1] = useState();

  const [imagePre_LeaveTemplate_v1, setImagePreLeaveTemplate_v1] =
    useState(null);
  const getProfileOrg = getCurrentUser();
  useEffect(() => {
    setFileLeaveTemplate_v1(null);
    setIDLeaveV1(null);
    setImagePreLeaveTemplate_v1(null);

    if (idorg) {
      getImageLinkOrganization();
    }
  }, [idorg]);

  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: idorg });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const leaveTemplateV1 = res.list_api.find(
          (item) => item.param === "leave_v1"
        );
        if (leaveTemplateV1) {
          setImagePreLeaveTemplate_v1(
            linkPathIDCard + leaveTemplateV1.imagelink
          );
          setIDLeaveV1(leaveTemplateV1.id);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  const stylesCard = () => {
    return {
      width: 390,
      height: 550,
      border: "2px solid #ccc", // Adds a border
      borderRadius: "8px", // Rounds the corners
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Adds shadow
      padding: "10px", // Adds padding inside the container
      backgroundColor: "#fff",
    };
  };

  const onHandleNew_Update_LeaveTemplateV1 = async () => {
    if (!file_LeaveTemplate_v1) return;
    const formData = new FormData();
    formData.append("id", ID_LeaveV1);
    formData.append("org_code", idorg);
    formData.append("param", "leave_v1");
    formData.append("image_name", file_LeaveTemplate_v1.name);
    formData.append("create_by", getProfileOrg.fullname);
    formData.append("file_image_card", file_LeaveTemplate_v1);
    setLoadingSpin(true);
    try {
      let method = ""; // Initialize method as let so we can reassign it
      method =
        ID_LeaveV1 == null || ID_LeaveV1 == undefined || ID_LeaveV1 == ""
          ? "post"
          : "put";
      const res = await request("settingorgidcard", method, formData);
      if (res) {
        openNotification("success", "Success", "Template Saved Successfully");
        setFileLeaveTemplate_v1(null);
        getImageLinkOrganization();
        // setImagePreLeaveTemplate_v1("");
      }
    } catch (error) {
      console.error("Save Template file failed:", error);
      openNotification("error", "Error", "Save failed");
    } finally {
      setLoadingSpin(false);
    }
  };
  const handFileChangeLeaveTemplateV1 = (e) => {
    try {
      const file = e.target.files[0];
      setFileLeaveTemplate_v1(file);
      setImagePreLeaveTemplate_v1(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {loadingSpin && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div
          className="khmer-font"
          style={{
            color: "rebeccapurple",
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          ក. ទម្រង់ច្បាប់សំរាប់ក្រោម​ V.1.0
        </div>

        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {imagePre_LeaveTemplate_v1 ? (
            <img
              src={imagePre_LeaveTemplate_v1}
              alt="Front Preview"
              style={stylesCard()}
            />
          ) : (
            <di style={stylesCard()}>Loading....</di>
          )}
          <input
            type="file"
            ref={inputRefLeaveTemplate_v1}
            style={{ display: "none" }}
            onChange={handFileChangeLeaveTemplateV1}
          />
          {file_LeaveTemplate_v1 && (
            <Button
              style={{ margin: "10px" }}
              type="primary"
              icon={<IoCloudUploadOutline />}
              onClick={onHandleNew_Update_LeaveTemplateV1}
            >
              Save Leave Template
            </Button>
          )}

          <div>
            <Space>
              <Button
                color="default"
                variant="solid"
                icon={<IoCloudUploadSharp />}
                style={{ marginTop: 10 }}
                onClick={() => inputRefLeaveTemplate_v1.current.click()}
              >
                Upload
              </Button>
              {imagePre_LeaveTemplate_v1 && (
                <Button
                  color="danger"
                  variant="solid"
                  style={{ marginTop: 10 }}
                  icon={<DeleteOutlined />}
                />
              )}
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaveTemplate;
