import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { request } from "../../share/request";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { auth } from "../../Firebase/firebase.config";
import "./ForgotPassword.css"; // Import your CSS file
import ChangePassword from "./ChangePassword";
import { openNotification } from "../../share/message";

const ForgotPassword = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  // ReCAPTCHA Verification
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // Captcha solved, allow OTP sending
            onOTPServer();
          },
          "expired-callback": () => {
            toast.error("ReCAPTCHA expired. Please try again.");
          },
        },
        auth
      );
    }
  }

  // Check if Username (Phone) exists in the system
  async function onCheckUserName() {
    let phoneNumber = onlyPhoneNumber;
    console.log("Checking", phoneNumber);
    if (!phoneNumber) {
      openNotification("error", "error", "Please enter a phone number");
      return;
    }
    try {
      if (phoneNumber.charAt(0) !== "0") {
        phoneNumber = "0" + phoneNumber;
      }

      setOnlyPhoneNumber(phoneNumber);
      console.log("Checking user befor change  ", onlyPhoneNumber);
      console.log("Phone number after changed:", phoneNumber);

      setLoading(true);
      const param = {
        username: phoneNumber,
      };

      const res = await request("user/loginverifyotp", "post", param);
      if (res && res.isSuccess === true) {
        // Assuming 'isSuccess' being false means user exists
        localStorage.setItem("profile", JSON.stringify(res.profileData)); // Save profile info locally
        // localStorage.setItem("token", JSON.stringify(res.access_token));
        onOTPServer(); // Proceed to send OTP
      } else {
        openNotification("error", "Error", "User Not Found "); // Handle case where user doesn't exist
        setLoading(false);
      }
    } catch (error) {
      openNotification("error", "Error", error);
    }
  }

  // Send OTP after ReCAPTCHA verification
  async function onOTPServer() {
    onCaptchVerify(); // Ensure ReCAPTCHA is verified first
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + ph;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formatPh,
        appVerifier
      );
      window.confirmationResult = confirmationResult;
      setShowOTP(true); // Show OTP input form
      toast.success("OTP sent successfully!");
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  // Verify OTP
  async function onOTPVerify() {
    setLoading(true);
    try {
      const res = await window.confirmationResult.confirm(otp);
      setUser(res.user);
      toast.success("Login Successful!");
    } catch (err) {
      console.error("Error verifying OTP:", err);
      toast.error("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  }
  const [onlyPhoneNumber, setOnlyPhoneNumber] = useState(null);
  const onChangePhoneNumber = (value, country) => {
    setPh(value); // Full phone number with country code
    const phoneWithoutCountryCode = value.substring(country.dialCode.length);

    setOnlyPhoneNumber(phoneWithoutCountryCode); // Store phone number without country code
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section className="forgot-password-section">
        <div>
          <Toaster toastOptions={{ duration: 4000 }} />
          <div id="recaptcha-container"></div>
          {user ? (
            <ChangePassword />
          ) : (
            <div className="form-container">
              {showOTP ? (
                <>
                  <div className="icon-container">
                    <BsFillShieldLockFill size={30} />
                  </div>
                  <label htmlFor="otp" className="otp-label">
                    Enter your OTP
                  </label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    inputClassName="otp-input"
                  />
                  <button onClick={onOTPVerify} className="submit-button">
                    {loading && (
                      <CgSpinner size={20} className="loading-spinner" />
                    )}
                    <span>Verify OTP</span>
                  </button>
                </>
              ) : (
                <>
                  <div className="icon-container">
                    <BsTelephoneFill size={30} />
                  </div>

                  <label className="phone-label">
                    Reset Password with: Verify your Phone Number
                  </label>
                  <PhoneInput
                    country={"kh"}
                    inputStyle={{
                      height: 50,
                      fontWeight: "bold",
                      fontSize: 22,
                      borderRadius: 4,
                    }}
                    value={ph}
                    onChange={onChangePhoneNumber}
                  />
                  <button onClick={onCheckUserName} className="submit-button">
                    {loading && (
                      <CgSpinner size={20} className="loading-spinner" />
                    )}
                    <span>Send code via SMS</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
