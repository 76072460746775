import React from "react";
import { Card, Typography, Avatar, Tag } from "antd";
import {
  PhoneOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { linkPath } from "../../share/helper";

const { Title, Text } = Typography;

const LeaderCard = ({ list }) => {
  return (
    <Card
      style={{
        margin: 5,

        borderRadius: 8,
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Avatar
        style={{ marginBottom: 10 }}
        className="ionavatar"
        src={linkPath + encodeURIComponent(list.image)}
        size={100}
      />
      <Title level={5} style={{ fontWeight: "bold", fontFamily: "Content" }}>
        {list.frontname} {list.fullname}
      </Title>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Text
          type="secondary"
          style={{ fontWeight: "bold", fontFamily: "Content" }}
        >
          {list.position_kh}
        </Text>
        <div>
          <Tag
            color="black"
            style={{ fontWeight: "bold", fontFamily: "Content" }}
          >
            {<WhatsAppOutlined />} {list.username}
          </Tag>
        </div>
      </div>
    </Card>
  );
};

export default LeaderCard;
