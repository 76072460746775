// import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import Icon, {
  ContactsOutlined,
  DownloadOutlined,
  IdcardFilled,
  TeamOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";

import { request } from "../../share/request";
import { Stack, TabContent } from "react-bootstrap";
import moment from "moment";
import dayjs from "dayjs";
import "./CardStaff.css";
import {
  IdcardOutlined,
  WomanOutlined,
  ManOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  UsergroupAddOutlined,
  UserDeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineIndeterminateCheckBox,
} from "react-icons/md";
import {
  Pagination,
  Drawer,
  Checkbox,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Dropdown,
  Spin,
  notification,
  Avatar,
  FloatButton,
  Upload,
  ConfigProvider,
  TreeSelect,
  DatePicker,
  Menu,
} from "antd";
import "../../styles/Home-module.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS
import {
  formateDateClient,
  formateDateClientTime,
  formateDateSystem,
  linkPath,
  linkPathUser,
} from "../../share/helper";
import { openNotification } from "../../share/message";

import { Circles } from "react-loader-spinner";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import CardComponentFront from "./CardComponentFront";
import CardComponentBack from "./CardComponentBack";

import { useReactToPrint } from "react-to-print";
import { getCurrentUser, getPermission } from "../../share/getProfile";

import "react-datepicker/dist/react-datepicker.css";

import { getJobStatus } from "../../share/getStatusContent";
import { CiCamera } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { BiAddToQueue, BiIdCard, BiUserCheck } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";
import UserDetail from "./userdetail";

import GetLoading from "../../share/getLoading";
import Loading from "../../share/Loading";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { GetListShiftWork } from "../../share/getShiftWork";
import CardUI from "./CardUI";
import { GetRandomColor } from "../../share/GetRamdomColor";
import { Cropper } from "react-cropper";

const { Option } = Select;

const UserPage = () => {
  const [isModalOpenCropImage, setModalOpenCropImage] = useState(false);
  const cropperRef = useRef(null);
  const componentRef = useRef(null);
  const getProfileOrg = getCurrentUser();
  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtSearch: "",
    orgcode: getProfileOrg.org_code,
  });
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [logoName, setLogoName] = useState("");
  const [visibleDraw, setVisableDraw] = useState(false);
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disableEditDetail, setDisableEditDetail] = useState(true);
  const [enable_car, setEnable_Car] = useState(false);
  const [disable_car, setDisable_Car] = useState(false);

  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
  const [getPostionList, setGetPositionList] = useState([]);
  const [ListLevelAPI, setListLevel] = useState([]);
  const [ListSkillAPI, setListSkillAPI] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visabledelete, setvisabledelete] = useState(false);
  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [staffname, setStaffname] = useState("");
  const [gender, setGender] = useState([]);
  const [roleid, setRoleID] = useState([]);
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [frontname, setFrontname] = useState("");
  const [listFrontName, setListFrontName] = useState([]);
  const [status, setStatus] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [departmentlist, setDepartmentList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [textSearch, setTextSearch] = useState("");

  const [positionslist, setPositionslist] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const [idnumber, setIDNumber] = useState();
  const [staffname_eng, setStaffnameEnglish] = useState();
  const [dob, setDOB] = useState(dayjs());
  const [dob_value, setDOBValue] = useState(dayjs());
  const [level, setLevel] = useState([]);
  const [joinwork, setJoinWork] = useState(dayjs());
  const [skill, setSkill] = useState([]);
  const [updateleveldate, setUpgradeLevelDate] = useState(dayjs());
  const [stafftype, setStaffType] = useState(null);
  const [no_update, setNoUpdate] = useState(null);
  const [listCard, setListCard] = useState([]);

  const componentRefFront = useRef(null);
  const componentRefBack = useRef(null);
  const [shouldPrintFront, setShouldPrintFront] = useState(false);
  const [shouldPrintBack, setShouldPrintBack] = useState(false);
  const [dataFetchedFront, setDataFetchedFront] = useState(false);
  const [dataFetchedBack, setDataFetchedBack] = useState(false);
  const [datarole, setDataRole] = useState([]);
  const [deparment_id, setDeparmentID] = useState();
  const [position_id, setPositionID] = useState();
  const [skill_id, setSkillID] = useState();
  const [shiftid, setShiftID] = useState([]);
  // setting for page
  const itemsPerPage = 9;
  // -------------------------------------
  const [selectOrgCodeSave, setSelectOrgCodeSave] = useState(
    getProfileOrg.org_code
  );

  const [ShiftWork, SetShiftWork] = useState([]);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const getTokenData = localStorage.getItem("token");
  const [printNone, setPrintNone] = useState(0);
  const [fletchShiftWork, setFletchShiftWork] = useState([]);
  const staffValue = userPermission.find(
    (permission) => permission.pagename === "staff"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: displayPrint,
  } = staffValue;
  // -----------------  end permisstion page --------------------
  const printFunctionFront = useReactToPrint({
    content: () => componentRefFront.current,
  });
  const printFunctionBack = useReactToPrint({
    content: () => componentRefBack.current,
  });

  useEffect(() => {
    // This effect runs when the component mounts
    setShouldPrintFront(false);
    setShouldPrintBack(false); // Reset shouldPrint after mounting
    setPrintNone(displayPrint ? "" : "none");
  }, []); // Empty dependency array means it runs once on mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  useEffect(() => {
    getMasterGroups();
    getListWorkStation();
    getListSkill(objFilter);
    const fetchDataAndPrint = async () => {
      try {
        await getList(objFilter);
        setDataFetchedFront(true);
        setDataFetchedBack(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndPrint();
  }, [textSearch, listCard]);

  useEffect(() => {
    if (dataFetchedFront && shouldPrintFront) {
      printFunctionFront();
      setShouldPrintFront(false);
      setDataFetchedFront(false); // Reset dataFetched after printing
    }
    if (dataFetchedBack && shouldPrintBack) {
      printFunctionBack();
      setShouldPrintBack(false);
      setDataFetchedBack(false); // Reset dataFetched after printing
    }
  }, [
    shouldPrintFront,
    shouldPrintBack,
    printFunctionFront,
    printFunctionBack,
    dataFetchedFront,
    dataFetchedBack,
  ]);
  useEffect(() => {
    if (textSearch !== "") {
      var objTmp = {
        ...objFilter,
        txtSearch: textSearch,
        page: textSearch === "" ? 1 : objFilter.page,
      };
      setObjFilter(objTmp);
      getList(objTmp);
      setLoading(false);
    } else {
      var objTmp = {
        ...objFilter,
        txtSearch: "",
        page: 1,
      };

      setObjFilter(objTmp);
      getList(objTmp);
    }

    if (getProfileOrg.org_code) {
      console.log("Updated getProfileOrg:", getProfileOrg.org_code); // Check if idorg updates
      setFrontImage(""); // Reset images on idorg change
      setBackImage("");
      (async () => {
        await getImageLinkOrganization();
      })();
      console.log("front card :", frontImage);
      console.log("back card :", backImage);
    }
  }, [textSearch]);

  const getList = async (objFilter) => {
    const params = new URLSearchParams({
      page: objFilter.page,
      txtSearch: objFilter.txtSearch,
      orgcode: objFilter.orgcode,
    });

    try {
      const res = await request("user?" + params, "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));

      console.log(res);
      if (res) {
        setList(res.list);
        setTotal(res.total[0].Totalstaff);
        setDepartmentList(res.listdepartment);
        setListFrontName(res.listfrontname);
        setDataRole(res.listrole);
        SetShiftWork(res.api_sqlshiftwork);
        if (objFilter.page === 1) {
          setTotal(res.total[0].Totalstaff);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getListCreateNewStaff = async (objFilter) => {
    const params = new URLSearchParams({
      org_code: objFilter.org_code,
    });

    try {
      const res = await request("userdepartmentposition?" + params, "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setDepartmentList(res.api_department_list);
        SetShiftWork(res.api_sqlshiftwork);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [ListMasterGroup, setListMasterGroup] = useState([]);
  const getMasterGroups = async () => {
    const param = new URLSearchParams({ org_code: getProfileOrg.org_code });

    const res = await request("usermastergroup?" + param.toString(), "get", {});
    if (res) {
      setListMasterGroup(res.list_api_master_group[0]);
    }
  };
  const getListSkill = async (objFilter) => {
    const param = new URLSearchParams({
      org_code: objFilter.org_code,
    });

    // create function success
    const res = await request("userlevel?" + param.toString(), "get", {});
    if (res) {
      setListSkillAPI(res.ListSkill);
      setListLevel(res.ListLevel);
    }
  };
  const [treeValueSelect, setTreeValueSelect] = useState(null);

  const treeData = ListMasterGroup.reduce((acc, item) => {
    const groupId = item.group_id || "Unknown"; // Use "Unknown" as a fallback
    const nameUnknow = item.org_name_kh;
    const groupName = item.group_name || nameUnknow; // Use "Unknown" as a fallback
    const group = acc.find((g) => g.value === groupId);
    if (group) {
      group.children.push({
        title: item.org_name_kh,
        value: item.org_code,
        key: item.organization_id,
      });
    } else {
      acc.push({
        title: `${groupName}`, // Use fallback or default group name
        value: groupId,
        key: `${groupId}`,
        children: [
          {
            title: item.org_name_kh,
            value: item.org_code,
            key: item.organization_id,
          },
        ],
      });
    }
    return acc;
  }, []);

  const getListWorkStation = async () => {
    // // create function success
    // const res = await request("userlocationwork", "get", {});
    // if (res) {
    //   const workLocations = res.get_API_WorkLocation[0];
    //   const formattedTreeData = buildTreeData(workLocations);
    //   setTreeData(formattedTreeData);
    // }
  };

  const onCloseModal = () => {
    SetShiftWork([]);
    inputRef.current.value = null;
    setVisible(false);
    setId("");
    setPhone("");
    setIDNumber("");
    setStaffnameEnglish("");
    setStaffname("");
    setRoleID("");
    setDepartment("");
    setSelectedPosition("");
    setFrontname("");
    setStatus("");
    setGender("");
    setDOB(null);
    setSkill(null);
    setLevel(null);
    setJoinWork(null);
    setStaffType(null);
    setUpgradeLevelDate(null);
    setImagesave(null);
    setSelectedFile(null);
    setImagePreview(null);
    setShiftID(null);
    setEnable(false);
    setDisable(true);
    setEnable_Car(false);
    setDisable_Car(true);
    getList(objFilter);
  };

  const onSave = async (item) => {
    if (selectedIndex == undefined) {
      openNotification(
        "warning",
        "បញ្ហា",
        "សូមជ្រើសរើស ឈ្មោះអង្គភាពជាមុនសិន !"
      );
    } else {
      const fields = [
        { value: phone, message: "សូមបញ្ជូល លេខទូស័ព្ទដៃ " },
        { value: staffname, message: "សូមបញ្ចូល ឈ្មោះបុគ្គលិក" },
        { value: roleid, message: "សូមជ្រើសរើស សិទ្ធប្រើប្រាស់ប្រព័ន្ធ" },
        { value: department, message: "សូមជ្រើសរើស ការិយាល័យ រឺផ្នែគ" },
        { value: selectedPosition, message: "សូមជ្រើសរើស តួនារទី" },
        { value: frontname, message: "សូមបញ្ជូលឈ្មោះខាងមុខ" },
        { value: status, message: "សូមជ្រើសរើស ស្ថានភាពការងារ " },
        { value: idnumber, message: "សូមបញ្ជូល អត្តលេខមន្ត្រី រឺបុគ្គលិក" },
        { value: dob, message: "សូមជ្រើសរើស ថ្ងៃខែកំណើត" },
        { value: level, message: "សូមជ្រើសរើស ប្រភេទកាំប្រាក់" },
        { value: skill, message: "សូមជ្រើសរើស ជំនាញ" },
        { value: joinwork, message: "សូមជ្រើសរើស ថ្ងៃខែឆ្នាំ ចូលបំរើការងារ" },
        {
          value: updateleveldate,
          message: "សូមជ្រើសរើស ឡើងកាំប្រាក់ចុងក្រោយ ",
        },
        { value: stafftype, message: "សូមជ្រើសរើស ប្រភេទការងាររបស់បុគ្គលិក" },
        { value: shiftid, message: "សូមជ្រើសរើស ម៉ោងបំពេញការងារ " },
      ];

      for (const field of fields) {
        if (
          !field.value ||
          (Array.isArray(field.value) && field.value.length === 0)
        ) {
          openNotification("warning", "បញ្ហា", field.message);
          return; // Stop execution if any field is empty
        }
      }
      const formData = new FormData();
      formData.append("username", phone);
      formData.append("fullname", staffname);
      formData.append("gender", gender);
      formData.append("roleid", roleid);
      formData.append("department", deparment_id);
      formData.append("position", position_id);
      formData.append("frontname", frontname);
      formData.append("jobstatus", status);
      formData.append("dob", dob);
      formData.append("idnumber", idnumber);
      formData.append("staffname_eng", staffname_eng);
      formData.append("joinwork", joinwork);
      formData.append("skill", skill_id);
      formData.append("level", level);
      formData.append("updateleveldate", updateleveldate);
      formData.append("stafftype", stafftype);
      formData.append("shiftid", shiftid);
      formData.append("no", no_update);
      formData.append("image", form.getFieldValue("image"));
      formData.append(
        "showadmin",
        enable == true ? 1 : disable == true ? 0 : 0
      );
      formData.append(
        "car_data",
        enable_car == true ? 1 : disable_car == true ? 0 : 0
      );
      formData.append("orgcode", selectOrgCodeSave);

      if (id !== "") {
        formData.append("id", id);
      }

      if (imageSave !== null) {
        const blob = await fetch(imageSave).then((res) => res.blob());
        const imageFile = new File([blob], "logo.png", {
          type: "image/png",
        });
        formData.append(
          id !== "" ? "fileimage_update" : "file_image",
          imageFile,
          imageFile.filename
        );
      }

      // Log FormData content

      for (let [key, value] of formData.entries()) {
        console.table(`${key}: ${value}`);
      }

      try {
        setVisible(false);
        const method = id === "" ? "post" : "put";
        const res = await request("user", method, formData);
        //  onCloseModal();
        setLoadingSpin(true);
        if (res.success) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          openNotification("success", "Success", res.message);
          getList(objFilter);
          inputRef.current.value = null;
        } else {
          openNotification("error", "Error", res.message);
          getList(objFilter);
        }
      } catch (error) {
        openNotification("error", "Error", error.message);
      } finally {
        setLoadingSpin(false);
        onCloseModal();
      }
    }
  };

  // Handle page changes

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const onChangeStaffname = (event) => {
    setStaffname(event.target.value);
  };
  const onChangeGender = (value) => {
    setGender(value);
  };

  const onChangeProvider = (value) => {
    //  setProvider(providerData[value]);
    setRoleID(value);
    console.log("this id role: " + value);
  };

  const onChangeDepartment = async (value) => {
    setDeparmentID(value);
    const param = new URLSearchParams({
      id: value,
      org_code: selectOrgCodeSave,
    });
    console.log("this id department param: " + param);
    setDepartment(value);

    try {
      const res = await request(
        "userdepartment?" + param.toString(),
        "get",
        {}
      );

      // Log the entire response to understand its structure
      console.log("API Response:", res);

      if (res && Array.isArray(res.listPosition)) {
        // Assuming res.listPosition is an array of positions
        const positions = res.listPosition; // Directly assign since it's already an array
        setPositionslist(positions);
      } else {
        console.error("Invalid response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching department positions:", error);
    }
  };
  const onClickPostion = async () => {
    console.log(" department id : ", deparment_id);

    const param = new URLSearchParams({
      id: deparment_id,
      org_code: selectOrgCodeSave,
    });
    try {
      const res = await request(
        "userdepartment?" + param.toString(),
        "get",
        {}
      );

      // Log the entire response to understand its structure
      console.log("API Response:", res);

      if (res && Array.isArray(res.listPosition)) {
        // Assuming res.listPosition is an array of positions
        const positions = res.listPosition; // Directly assign since it's already an array
        setPositionslist(positions);
      } else {
        console.error("Invalid response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching department positions:", error);
    }
  };
  const onChangeFrontName = (event) => {
    setFrontname(event);
  };
  const onChangeEnglishName = (event) => {
    setStaffnameEnglish(event.target.value);
  };
  const onChangeIDNumber = (event) => {
    setIDNumber(event.target.value);
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  const [imageDelete, setImageDelete] = useState(null);
  const onDelete = (item) => {
    setId(item.id);
    setNoUpdate(item.no);
    setImageDelete(item.image);
    setStaffname(item.fullname);
    setvisabledelete(true);
  };

  const onClickEdit = async (item) => {
    console.log(" new paramter for click edit: ", objFilter);
    setSelectedIndex("unknown");

    setDeparmentID(item.department);
    setPositionID(item.position);
    setSkillID(item.skill);

    const param = new URLSearchParams({
      id: item.department,
      org_code: selectOrgCodeSave,
    });
    console.log(" edit param : ", param.toString());
    try {
      setLoadingSpin(true);
      setDisableEditDetail(false);
      if (selectOrgCodeSave) {
        const res = await request(
          "userdepartment?" + param.toString(),
          "get",
          {}
        );

        if (res && res.listPosition) {
          // Extract positions from the nested structure
          const positions = Array.isArray(res.listPosition[0])
            ? res.listPosition[0]
            : [];
          setPositionslist(positions);
          setListSkillAPI(res.api_list_skill);
          //   setShiftID(res.api_sqlshiftwork);
          console.log("This is the list:", JSON.stringify(positions));
        } else {
          console.error("Invalid response structure:", res);
        }
      }

      setId(item.id);
      setPhone(item.username);
      setIDNumber(item.idnumber);
      setStaffnameEnglish(item.staffname_eng);
      setStaffname(item.fullname);
      setRoleID(item.role_id);
      setDepartment(item.department_name);
      setSelectedPosition(item.position_name);
      setFrontname(item.frontname);
      setStatus(item.jobstatus);
      setGender(item.gender);
      setEmptyUser(item.image);
      setImagePreview(linkPath + item.image);
      setDOB(item.dob);
      setDOBValue(item.dob);

      setJoinWork(item.joinwork);
      setUpgradeLevelDate(item.date_last_upgrad);
      setLevel(item.level);
      setSkill(item.skill_name);
      setStaffType(item.stafftype);
      setNoUpdate(item.no);
      setSelectedFile(null);
      setVisible(true); // just opent
      //shift of work item
      if (item.shift_id) {
        const shiftArray = item.shift_id.split(",").map(Number); // Convert "1,2" to [1, 2]
        setShiftID(shiftArray);
      }

      if (item.showadmin === 1) {
        console.log(" car data", item.showadmin);
        setEnable(true);
        setDisable(false);
      } else if (item.showadmin === 0) {
        console.log(" car data", item.showadmin);
        setEnable(false);
        setDisable(true);
      }
      if (item.car_data === 1) {
        console.log(" car data", item.car_data);
        setEnable_Car(true);
        setDisable_Car(false);
      } else if (item.car_data === 0) {
        console.log(" car data", item.car_data);
        setEnable_Car(false);
        setDisable_Car(true);
      }
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const onclickDeleteNo = () => {
    setvisabledelete(false);
  };
  const onClickDeleteYes = async () => {
    console.log("ID delete is : " + id);
    const res = await request("user/" + id, "delete", {
      id,
      imageDelete,
      no_update,
    });
    if (res) {
      setvisabledelete(false);
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      openNotification("success", "Success", "Remove Success!");
      console.log("file name : " + imageDelete);
      getList(objFilter);
    } else {
      alert("Error!");
    }

    setLoadingSpin(false);
  };

  const buttonStyle = {
    fontFamily: "khmer OS Battambang ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const styleTageStatus = {
    fontSize: "14px",
    height: "30px",
    borderRadius: "30px",
    paddingTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
  };

  const getStatusContent = (status) => {
    switch (status) {
      case "ធ្វើការ":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            ធ្វើការ{" "}
          </Button>
        );
      case "ព្យួរ":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            ព្យួរ{" "}
          </Button>
        );
      case "ឈប់":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            danger
            shape="round"
            icon={<CloseOutlined />}
          >
            {" "}
            ឈប់{" "}
          </Button>
        );
      default:
        return null;
    }
  };
  const getGenderContet = (gender) => {
    switch (gender) {
      case "ស្រី":
        return (
          <Button
            className="styleTageStatus"
            style={{ height: "30px" }}
            type="primary"
            shape="round"
            icon={<WomanOutlined />}
          >
            {" "}
            ស្រី{" "}
          </Button>
        );

      case "ប្រុស":
        return (
          <Button
            className="styleTageStatus"
            style={{ height: "30px" }}
            type="primary"
            shape="round"
            ghost
            icon={<ManOutlined />}
          >
            {" "}
            ប្រុស{" "}
          </Button>
        );
      default:
        return null;
    }
  };
  const getProviderContet = (provider) => {
    switch (provider) {
      case "Admin":
        return (
          <Tag
            icon={<UsergroupAddOutlined />}
            style={styleTageStatus}
            color="green"
          >
            Admin
          </Tag>
        );

      case "User":
        return (
          <Tag
            icon={<UserDeleteOutlined />}
            style={styleTageStatus}
            color="gold"
          >
            User
          </Tag>
        );

      case "Leader":
        return (
          <Tag
            icon={<UsergroupAddOutlined />}
            style={styleTageStatus}
            color="green"
          >
            Leader
          </Tag>
        );

      default:
        return (
          <Tag
            icon={<UserDeleteOutlined />}
            style={styleTageStatus}
            color="gold"
          >
            {provider}
          </Tag>
        );
    }
  };
  // const onGenderChange = (value: string) => {
  //     switch (value) {
  //       case 'male':
  //         formRef.current?.setFieldsValue({ note: 'Hi, man!' });
  //         break;
  //       case 'female':
  //         formRef.current?.setFieldsValue({ note: 'Hi, lady!' });
  //         break;
  //       case 'other':
  //         formRef.current?.setFieldsValue({ note: 'Hi there!' });
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  //-----------------
  //-----------search ........
  const OnSearch = (value) => {
    getList(objFilter);
  };

  const OnSearchText = async (e) => {
    setTextSearch(e.target.value);
    const inputValue = e.target.value;
    console.log("OnSearchText = ", inputValue);
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      var objTmp = {
        ...objFilter,
        txtSearch: e.target.value,
        page: e.target.value === "" ? 1 : objFilter.page,
      };
      setObjFilter(objTmp);
      getList(objTmp);
      console.log("objTmp = ", objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  //------------ upload file -----------
  const [selectedFile, setSelectedFile] = useState(null);
  const [Empty_User, setEmptyUser] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageSave, setImagesave] = useState(null);
  const [disablePrint, setDisablePrint] = useState(false);
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const handleFileChange = (e) => {
    try {
      var file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setModalOpenCropImage(true); //
          console.log("Image loaded");
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // for orientation file
  // const getCroppedImage = () => {
  //   if (cropperRef.current) {
  //     const cropper = cropperRef.current.cropper;
  //     const croppedDataUrl = cropper.getCroppedCanvas()?.toDataURL();

  //     if (!croppedDataUrl) {
  //       console.error(
  //         "Cropped canvas returned null. Check image source and CORS."
  //       );
  //     } else {
  //       //setLogoOrgPreview(croppedDataUrl);
  //       setImagesave(croppedDataUrl);
  //       setImagePreview(croppedDataUrl); // for pre view image
  //       setEmptyUser(croppedDataUrl);
  //       console.log("Cropped image data URL:", croppedDataUrl);
  //     }

  //     setModalOpenCropImage(false);
  //   }
  // };

  const getCroppedImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();

      if (!croppedCanvas) {
        console.error(
          "Cropped canvas returned null. Check image source and CORS."
        );
        return;
      }

      // Compress image using the quality parameter
      croppedCanvas.toBlob(
        (blob) => {
          if (blob) {
            const compressedImageUrl = URL.createObjectURL(blob);
            setImagesave(compressedImageUrl); // Save compressed image URL
            setImagePreview(compressedImageUrl); // Preview image
            setEmptyUser(compressedImageUrl); // Update other states
            console.log("Compressed image Blob URL:", compressedImageUrl);

            // Close the modal
            setModalOpenCropImage(false);
          }
        },
        "image/jpeg", // Format (use 'image/png' if transparency is needed)
        0.5 // Compression quality (adjust between 0.5 - 0.9 for optimal size/quality balance)
      );
    }
  };

  const onChangePostionsData = (value) => {
    setPositionID(value);
    setSelectedPosition(value);
    console.log("value = ", selectedPosition);
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onCancelDelete = () => {
    setvisabledelete(false);
  };
  // Step 1: State for Checkbox Status
  const [checkboxes, setCheckboxes] = useState({});

  useEffect(() => {
    // Flatten the checkbox states across all pages
    const flattenedCheckboxes = Object.values(checkboxes).reduce(
      (acc, pageCheckboxes) => {
        return { ...acc, ...pageCheckboxes };
      },
      {}
    );

    // Get all checked IDs
    const checkedIds = Object.keys(flattenedCheckboxes).filter(
      (id) => flattenedCheckboxes[id]
    );

    // Update the disablePrint state based on checked items
    setDisablePrint(checkedIds.length !== 0);

    console.log("This is checked IDs: ", checkedIds);
  }, [checkboxes]);

  // Handle Checkbox Change for "Select All" Checkbox

  const onOpenModal = async () => {
    // onCloseModal();
    setSelectedIndex(null);
    setSelectOrgCodeSave(null);
    console.log("onOpenModal index: ", selectedIndex);
    setShiftID(null);
    setVisible(true);
    setId("");
    setPhone("");
    setIDNumber("");
    setStaffnameEnglish("");
    setStaffname("");
    setRoleID("");
    setDepartment("");
    setSelectedPosition("");
    setFrontname("");
    setStatus("");
    setGender("");
    setDOB(dayjs().format("YYYY-MM-DD"));
    console.log(" date dob is : ", dayjs().format("YYYY-MM-DD"));
    setSkill(null);
    setLevel(null);
    setJoinWork(dayjs().format("YYYY-MM-DD"));
    setStaffType(null);
    setUpgradeLevelDate(dayjs().format("YYYY-MM-DD"));
    setImagesave(null);
    setSelectedFile(null);
    // default photox
    setImagePreview(linkPath + "empty_user.png");
    setEnable(false);
    setDisable(true);
    setEnable_Car(false);
    setDisable_Car(true);
  };
  const onPrintCardFrontSite = async () => {
    const id = Object.values(checkboxes).flatMap((pageCheckboxes) =>
      Object.keys(pageCheckboxes).filter((id) => pageCheckboxes[id])
    );
    console.log("id need to print : ", id);

    try {
      setLoadingSpin(true);
      const res = await request("usercard/" + id.join(","), "get", {});
      setListCard(res.datacard);

      // Additional logic to handle the response as needed
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error, show an error message, etc.
    } finally {
      setLoadingSpin(false);
      setShouldPrintFront(true);
    }
  };
  const onPrintCardBackSite = async () => {
    const id = Object.values(checkboxes).flatMap((pageCheckboxes) =>
      Object.keys(pageCheckboxes).filter((id) => pageCheckboxes[id])
    );
    console.log("id need to print : ", id);

    try {
      setLoadingSpin(true);
      const res = await request("usercard/" + id.join(","), "get", {});
      setListCard(res.datacard);
      // Additional logic to handle the response as needed
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error, show an error message, etc.
    } finally {
      setLoadingSpin(false);
      setShouldPrintBack(true);
    }
  };
  class MyComponentToPrintFrontSite extends React.Component {
    render() {
      const { listCard } = this.props;
      return (
        <div className="print-content front-paper">
          {listCard && listCard.length > 0 ? (
            listCard.map((item, index) => (
              <CardComponentFront
                key={index}
                className="front-paper"
                staffname={item.fullname}
                deparment={item.department_name}
                position={item.position_name}
                image={item.image}
                frontImage={frontImage}
                idnumber={item.idnumber}
                logoName={logoName}
                impage_profile={
                  item.image == "" ? "empty_user.png" : item.image
                }
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      );
    }
  }

  class MyComponentToPrintBackSite extends React.Component {
    render() {
      const { listCard } = this.props;
      return (
        <div className="print-content-card back-paper">
          {listCard && listCard.length > 0 ? (
            listCard.map((item, index) => (
              <CardComponentBack
                key={index}
                className="back-paper"
                staffname={item.fullname}
                deparment={item.department}
                position={item.position}
                image={item.image}
                backImage={backImage}
                profileqrcode={getProfileOrg.domain}
                logoName={logoName}
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      );
    }
  }

  const [printData, setPrintData] = useState({
    item: null,
    shouldPrint: false,
    index: null,
  });
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => console.log("Preparing to print..."),
    onAfterPrint: () => console.log("Print completed."),
  });

  useEffect(() => {
    if (printData.shouldPrint && componentRef.current) {
      setPrintData({ item: null, shouldPrint: false, index: null });
      printFunction();
    }
  }, [printFunction, printFunction]);

  const [itemPrint, setItemPrint] = useState(null);

  const handlePrint = async (item, index) => {
    const id = Object.values(checkboxes)
      .flatMap((pageCheckboxes) =>
        Object.keys(pageCheckboxes).filter((id) => pageCheckboxes[id])
      )
      .join(",");
    console.log("id need to print : ", id);

    try {
      setLoadingSpin(true);
      const res = await request("usercard/" + id, "get", {});
      setListCard(res.datacard);
      setItemPrint(item);
      setPrintData({ item: res.datacard, shouldPrint: true, index });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoadingSpin(false);
    }
  };

  const handleDateChange_DOB = (date) => {
    setDOB(dayjs(date).format("YYYY-MM-DD"));
    console.log("day is change : ", dayjs(date).format("YYYY-MM-DD"));
  };

  const handleDateChange_JoinWork = (date) => {
    setJoinWork(dayjs(date).format("YYYY-MM-DD"));
  };

  const handleDateChange_UpgradeLevel = (date) => {
    setUpgradeLevelDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const onChangeSkill = (value) => {
    setSkillID(value);
    setSkill(value);
  };

  const onChangeLevel = (value) => {
    setLevel(value);
  };
  const dateFormat = "YYYY-MM-DD";
  const onChangeStafftype = (value) => {
    setStaffType(value);
  };

  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  const handleCheckboxChange_Enable_Car = (e) => {
    setEnable_Car(e.target.checked);
    setDisable_Car(false);
  };
  const handleCheckboxChange_Diable_Car = (e) => {
    setDisable_Car(e.target.checked);
    setEnable_Car(false);
  };
  //--------------------------------------------

  const onClickMoreData = async (item) => {
    setId(item.id);
    setIDNumber(item.idnumber);
    console.log("ID statff:", idnumber);
    if (!visibleDraw) {
      setVisableDraw(true);
    }
  };

  const onClose = () => {
    setVisableDraw(false);
    setIDNumber("");
  };
  const [selectAll, setSelectAll] = useState(false);
  const onChangePageNumber = (page) => {
    setCurrentPage(page);
    setLoading(true);
    var objTmp = {
      ...objFilter,
      page: page,
    };
    setObjFilter(objTmp);
    getList(objTmp);
    // Check if "Select All" should be checked for the new page
    setCheckboxes((prevCheckboxes) => {
      const pageSelections = prevCheckboxes[page] || {};
      const allSelected = list.every((item) => pageSelections[item.id]); // Check if all are selected
      setSelectAll(allSelected); // Update "Select All" state
      return prevCheckboxes; // Retain previous selections
    });
    console.log("Select all action by page : ", checkboxes);
  };

  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: getProfileOrg.org_code });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const frontSide = res.list_api.find(
          (item) => item.param === "fs-idcard"
        );
        const backSide = res.list_api.find(
          (item) => item.param === "bs-idcard"
        );
        const logoName = res.list_api.find((item) => item.param === "logo");
        if (frontSide) setFrontImage(frontSide.imagelink);
        if (backSide) setBackImage(backSide.imagelink);
        if (logoName) setLogoName(logoName.imagelink);
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  const onHandleSelectShiftWork = (value) => {
    setShiftID(value);
    console.log("onHandleSelectShift", value);
  };

  // Handle individual checkbox changes
  const handleCheckboxChange = (id) => {
    setCheckboxes((prev) => {
      const updatedPageCheckboxes = {
        ...prev[currentPage],
        [id]: !prev[currentPage]?.[id], // Toggle checkbox
      };

      const updatedCheckboxes = {
        ...prev,
        [currentPage]: updatedPageCheckboxes,
      };

      // Flatten the checkboxes to a single array of all selected IDs
      const allCheckboxes = Object.values(updatedCheckboxes).flatMap(
        (pageCheckboxes) => Object.values(pageCheckboxes)
      );

      // Determine if all items are selected across pages
      const allSelected =
        allCheckboxes.length > 0 && allCheckboxes.every(Boolean);
      setSelectAll(allSelected);

      return updatedCheckboxes;
    });
  };

  const handleSelectAll = () => {
    // Determine if the current page is fully selected
    const allSelected = selectAll;

    // Toggle "Select All" state for the current page
    setSelectAll(!allSelected);

    // Update the checkboxes for the current page
    setCheckboxes((prev) => ({
      ...prev,
      [currentPage]: list.reduce((acc, item) => {
        acc[item.id] = !allSelected; // Toggle all checkboxes
        return acc;
      }, {}),
    }));
  };
  useEffect(() => {
    if (list.length > 0) {
      const currentSelections = checkboxes[currentPage] || {}; // Selections for the current page
      const allSelected = list.every((item) => currentSelections[item.id]); // Check all items on the page
      setSelectAll(allSelected); // Dynamically update "Select All"
    }
  }, [list, currentPage, checkboxes]); // Run this effect when the page, list, or checkboxes change

  // Set up effect to update window width on resize
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update state with the new window width
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [selectedGroupInfo, setSelectedGroupInfo] = useState(null); // To store selected group info
  const [selectedIndex, setSelectedIndex] = useState(null); // Store the selected index

  // Helper function to find the selected org by value (org_code)
  const findOrgByValue = (value, data) => {
    let foundOrg = null;
    data.some((node) => {
      if (node.value === value) {
        foundOrg = node;
        return true;
      }
      // Recursively search in the children
      if (node.children && node.children.length > 0) {
        foundOrg = findOrgByValue(value, node.children);
        return foundOrg ? true : false;
      }
      return false;
    });
    return foundOrg;
  };

  // Helper function to find the index of the selected org by its value (org_code)
  const findOrgIndex = (value, data, parentIndex = null) => {
    let index = -1;
    data.some((node, idx) => {
      // Check if the current node matches the selected value
      if (node.value === value) {
        index = parentIndex !== null ? `${parentIndex}-${idx}` : idx;
        return true;
      }

      // If there are children, recursively search in the children
      if (node.children && node.children.length > 0) {
        index = findOrgIndex(value, node.children, idx); // Pass current index as parentIndex
        return index !== -1;
      }
      return false;
    });
    return index;
  };
  const [select_type, setSelectType] = useState(null);

  const onChangeOrganization_NewStaff = async (value) => {
    setShiftID(null);
    setDeparmentID(null);
    setPositionID(null);
    const selectedOrg = findOrgByValue(value, treeData);

    if (selectedOrg) {
      const groupId = selectedOrg.groupId; // Get the groupId (parent)
      const orgCode = selectedOrg.value; // The orgCode of the selected organization

      // Find the index of the selected organization
      const orgIndex = findOrgIndex(value, treeData);
      setSelectOrgCodeSave(orgCode);

      setSelectedGroupInfo({ groupId, orgCode }); // Store both groupId and orgCode
      setSelectedIndex(orgIndex); // Store the index of the selected org
      setSelectType(groupId);
      // Log the selected values
      console.table({ groupId, orgCode, index: orgIndex });
      console.log("this is index number: ", selectedIndex);
      console.log(" selected type : ", selectedOrg);
      const newfilter = {
        ...objFilter,
        org_code: orgCode,
      };
      console.table("new fileter : ", newfilter);
      getListSkill(newfilter);
      getListCreateNewStaff(newfilter);
    }
  };

  const onChangeOrganization = async (value) => {
    const selectedOrg = findOrgByValue(value, treeData);

    if (selectedOrg) {
      const groupId = selectedOrg.groupId; // Get the groupId (parent)
      const orgCode = selectedOrg.value; // The orgCode of the selected organization

      // Find the index of the selected organization
      const orgIndex = findOrgIndex(value, treeData);
      setSelectOrgCodeSave(orgCode);

      setSelectedGroupInfo({ groupId, orgCode }); // Store both groupId and orgCode
      setSelectedIndex(orgIndex); // Store the index of the selected org
      setSelectType(groupId);
      // Log the selected values
      console.table({ groupId, orgCode, index: orgIndex });
      console.log("this is index number: ", selectedIndex);
      console.log(" selected type : ", selectedOrg);

      if (orgIndex.length == undefined) {
        return;
      } else {
        try {
          setLoadingSpin(true);
          await new Promise((resolve) => setTimeout(resolve, 400));
          const newfilter = {
            ...objFilter,
            orgcode: orgCode,
          };
          console.table("new fileter : ", newfilter);
          setObjFilter(newfilter);
          getList(newfilter);
        } catch (error) {
        } finally {
          setLoadingSpin(false);
        }
      }
    }
  };

  const items = [
    {
      label: "ព្រីនកាត បុគ្គលិកខាងមុខ",
      key: "0",
      // icon: <UserOutlined />,
    },
    {
      label: "ព្រីនកាត បុគ្គលិកខាងក្រោយ",
      key: "1",
    },
  ];
  const menu = (id) => (
    <Menu
      items={items.map((item, index) => ({
        ...item,
        ...index,
        onClick: () => handlePrint(id, index),
      }))}
    />
  );
  const PrintWrapper = React.forwardRef(({ data }, ref) => {
    if (!data) return null; // Avoid rendering null
    return (
      <div ref={ref}>
        {data.index === 0 && (
          <MyComponentToPrintFrontSite listCard={data.item} />
        )}
        {data.index === 1 && (
          <MyComponentToPrintBackSite listCard={data.item} />
        )}
      </div>
    );
  });
  return (
    <div>
      {/* when click save or update data  */}
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && <div className="sprinSave">{GetLoading()}</div>}
      </div>
      <div>
        <UserDetail
          visible={visibleDraw}
          onClose={onClose}
          idNumber={idnumber}
          userid={id}
        />

        {/* -----------end save or updagte  */}

        <div>
          <div
            style={{
              ...boxHeader,
              flexDirection: windowWidth <= 1280 ? "column" : "row", // Conditionally set flexDirection
              marginBottom: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <Space>
                {/* {boxHeaderTagText("ការគ្រប់គ្រង់ ព៏តមានបុគ្គលិក")} */}
                <TreeSelect
                  style={{ width: 300 }}
                  className="styleTageStatusForm"
                  // value={treeValueSelect} // Controlled value
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  treeData={treeData} // Dynamically generated treeData
                  placeholder="Select Organization"
                  treeDefaultExpandAll
                  showSearch
                  allowClear
                  onChange={onChangeOrganization}
                />
                <Input
                  className="styleTageStatusForm"
                  placeholder="Search..."
                  onChange={OnSearchText}
                  allowClear
                />
              </Space>
            </div>

            <div
              style={{
                marginTop: 10,
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: windowWidth <= 900 ? "column" : "row",
                  // Conditionally set flexDirection
                }}
              >
                <div
                  style={{
                    display: "flex",
                    display: printNone,
                    marginBottom: 10,
                    marginLeft: 5,
                  }}
                >
                  <Space>
                    <div className="print-container">
                      <PrintWrapper data={printData} ref={componentRef} />
                      <Dropdown
                        overlayStyle={{ fontFamily: "Content" }}
                        overlay={menu(items)}
                        trigger={["click"]}
                      >
                        <Button
                          className="buttonstylekhmer"
                          danger
                          type="primary"
                          disabled={!disablePrint}
                          icon={<DownloadOutlined />}
                        >
                          ព្រីនកាតបុគ្គលិក
                        </Button>
                      </Dropdown>
                    </div>
                  </Space>
                </div>

                <div style={{ marginLeft: 5 }}>
                  <Space>
                    <Button
                      className="buttonstylekhmer"
                      type="primary"
                      icon={
                        <IoDocumentTextOutline
                          style={{ width: 20, height: 20 }}
                        />
                      }
                    >
                      ព្រីនប្រវត្តរូបសង្ខេប
                    </Button>
                    <Button
                      className="buttonstylekhmer"
                      type="primary"
                      disabled={!addvalue}
                      icon={<PlusCircleOutlined />}
                      onClick={onOpenModal}
                    >
                      បន្ថែមបុគ្គលិកថ្មី
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {loading ? (
            <div className="sprinSave">{GetLoading()}</div>
          ) : (
            //  <div>
            //     <Table
            //       // striped
            //       // bordered
            //       // hover

            //       className="rounded-table"
            //       itemsPerPage={10}
            //     >
            //       <thead>
            //         <tr
            //           style={{ fontFamily: "Content" }}
            //           class="table-success"
            //         >
            //           <th>
            //             <div
            //               style={{
            //                 paddingLeft: 20,
            //                 display: "flex",
            //                 alignItems: "center",
            //               }}
            //             >
            //               <span style={{ cursor: "pointer" }}>
            //                 {Object.values(checkboxes).some(
            //                   (value) => value === true
            //                 ) ? (
            //                   <MdOutlineCheckBox
            //                     // When clicked, all checkboxes should be unchecked
            //                     onClick={() => {
            //                       // Logic to uncheck all checkboxes
            //                       const updatedCheckboxes = Object.keys(
            //                         checkboxes
            //                       ).reduce((acc, key) => {
            //                         acc[key] = false; // Uncheck all checkboxes
            //                         return acc;
            //                       }, {});
            //                       setCheckboxes(updatedCheckboxes); // Update state
            //                     }}
            //                     style={{ width: 25, height: 25 }}
            //                   />
            //                 ) : (
            //                   <MdOutlineCheckBoxOutlineBlank
            //                     // When clicked, all checkboxes should be checked
            //                     onClick={() => {
            //                       // Logic to check all checkboxes
            //                       const updatedCheckboxes = Object.keys(
            //                         checkboxes
            //                       ).reduce((acc, key) => {
            //                         acc[key] = true; // Check all checkboxes
            //                         return acc;
            //                       }, {});
            //                       setCheckboxes(updatedCheckboxes); // Update state
            //                     }}
            //                     style={{ width: 25, height: 25 }}
            //                   />
            //                 )}
            //               </span>

            //             </div>
            //           </th>
            //           <th>លេខទូស័ព្ទ</th>

            //           <th>គោត្ត នាម</th>
            //           <th>ភេទ</th>
            //           <th>ប្រភេទ</th>
            //           <th>ផ្នែក</th>
            //           <th>តួរនាទី</th>

            //           <th>ស្ថានភាព</th>
            //           <th>សកម្មភាព</th>
            //         </tr>
            //       </thead>
            //       <tbody style={{ fontFamily: "Content" }}>
            //         {list.map((item, index) => (
            //           <tr key={index}>
            //             <td>
            //               <div
            //                 style={{
            //                   display: "flex",
            //                   justifyItems: "center",
            //                   alignItems: "center",
            //                 }}
            //               >
            //                 <div>
            //                   <Tag>{10 * (currentPage - 1) + (index + 1)}</Tag>
            //                 </div>

            //                 <div style={{ marginLeft: 1 }}>
            //                   <Checkbox
            //                     checked={checkboxes[item.id] || false}
            //                     onChange={() => handleCheckboxChange(item.id)}
            //                     style={{ transform: "scale(1.3)" }}
            //                   ></Checkbox>
            //                 </div>
            //               </div>
            //             </td>
            //             <td>
            //               <div style={{ display: "flex" }}>
            //                 <div>
            //                   <Tag
            //                     color="#f50"
            //                     style={{
            //                       borderRadius: 50,
            //                       padding: 3,
            //                       fontWeight: "bold",
            //                     }}
            //                   >
            //                     <Space>
            //                       <FiPhoneCall />
            //                       {item.username}
            //                     </Space>
            //                   </Tag>
            //                 </div>
            //                 <div>
            //                   <Tag
            //                     color="#108ee9"
            //                     style={{
            //                       borderRadius: 50,
            //                       padding: 3,
            //                       fontWeight: "bold",
            //                     }}
            //                   >
            //                     <Space>
            //                       <BiIdCard />
            //                       {item.idnumber}
            //                     </Space>
            //                   </Tag>
            //                 </div>
            //               </div>
            //             </td>

            //             <td>
            //               <div
            //                 style={{
            //                   display: "flex",
            //                   backgroundColor: "#f2f3f4",
            //                   padding: 5,
            //                   borderRadius: 20,
            //                   borderWidth: 1,
            //                   boarderColor: "#2c3e50",
            //                 }}
            //               >
            //                 <div>
            //                   <Avatar
            //                     className="ionavatar1"
            //                     src={
            //                       item.image != ""
            //                         ? linkPath + item.image
            //                         : linkPath + "empty_user.png"
            //                     }
            //                   />

            //                 </div>
            //                 <div style={{ paddingLeft: 10, paddingTop: 5 }}>
            //                   {item.fullname}
            //                 </div>
            //               </div>
            //             </td>
            //             <td>{getGenderContet(item.gender)}</td>

            //             <td>{getProviderContet(item.namerole)} </td>
            //             <td>{item.department_name}</td>
            //             <td>{item.position_name}</td>

            //             <td>{getJobStatus(item.jobstatus)}</td>

            //             <td style={{ width: 100 }}>
            //               <Stack gap={1} direction="horizontal">
            //                 <Button
            //                   type="primary"
            //                   disabled={!Editvalue}
            //                   icon={<FaRegEye />}
            //                   onClick={() => onClickMoreData(item)}
            //                 />
            //                 <Button
            //                   type="primary"
            //                   disabled={!Editvalue}
            //                   icon={<MdOutlineEdit />}
            //                   onClick={() => onClickEdit(item)}
            //                 />
            //                 <Button
            //                   disabled={!deletevalue}
            //                   icon={<MdOutlineDelete />}
            //                   onClick={() => onDelete(item)}
            //                   type="primary"
            //                   danger
            //                 />
            //               </Stack>
            //             </td>
            //           </tr>
            //         ))}
            //       </tbody>
            //     </Table>
            //     <Pagination
            //       onChange={onChangePageNumber}
            //       className="custom-pagination"
            //       size="large"
            //       defaultCurrent={currentPage}
            //       pageSize={10}
            //       total={total}
            //     />
            //   </div>

            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    marginLeft: 10,
                    backgroundColor: "#239b56",
                    borderRadius: 20,
                    paddingBottom: 5,
                    paddingTop: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {selectAll ? (
                      <MdOutlineCheckBox
                        onClick={handleSelectAll} // Deselect all
                        style={{ width: 25, height: 25 }}
                      />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        onClick={handleSelectAll} // Select all
                        style={{ width: 25, height: 25 }}
                      />
                    )}
                    Select All
                  </span>
                </div>
                <Pagination
                  onChange={onChangePageNumber}
                  className="custom-pagination"
                  size="large"
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={total}
                />
              </div>
              <div className="Staff-list">
                {list.map((item, index) => (
                  <div
                    style={{
                      margin: "10px",
                      padding: "15px",
                      borderRadius: "5px",
                      backgroundColor: "#fff",
                      boxShadow: "0 1px 8px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      alignItems: "center",
                      transition: "0.3s",
                      backgroundColor: "#ebf5fb",
                    }}
                    className="card-container"
                  >
                    <div style={{ marginRight: "10px" }}>
                      <Avatar
                        size={120}
                        src={
                          item.image != ""
                            ? linkPath + item.image
                            : linkPath + "empty_user.png"
                        }
                        style={{ border: "2px solid rgb(255, 250, 250)" }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Checkbox
                        key={item.id}
                        checked={checkboxes[currentPage]?.[item.id] || false}
                        onChange={() => handleCheckboxChange(item.id)}
                        style={{ transform: "scale(1.3)", marginRight: 5 }}
                      ></Checkbox>
                      <span
                        style={{
                          margin: "0",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {item.fullname} | {item.staffname_eng}
                      </span>
                      <p
                        style={{
                          margin: "5px 0",
                          color: "#666",
                          fontWeight: "bold",
                        }}
                      >
                        {item.department_name} | {item.position_name}
                      </p>
                      <Space>
                        <Tag
                          color="black"
                          icon={<UserSwitchOutlined />}
                          style={{ borderRadius: "10px", fontWeight: "bold" }}
                        >
                          {item.idnumber}
                        </Tag>
                        <Tag
                          color="green"
                          icon={<UserAddOutlined />}
                          style={{ borderRadius: "10px", fontWeight: "bold" }}
                        >
                          {item.stafftype}
                        </Tag>
                      </Space>
                      {/* <div style={{ marginTop: "5px" }}>
                        <Space>
                          {getGenderContet(item.gender)}
                          {getProviderContet(item.namerole)}
                        </Space>
                      </div> */}
                      <div style={{ marginTop: "5px" }}>
                        <Space>
                          <Button
                            style={{ borderRadius: "50%" }}
                            disabled={!Editvalue}
                            icon={<FaRegEye />}
                            onClick={() => onClickMoreData(item)}
                          />
                          <Button
                            color="default"
                            variant="solid"
                            style={{ borderRadius: "50%" }}
                            disabled={!Editvalue}
                            icon={<MdOutlineEdit />}
                            onClick={() => onClickEdit(item)}
                          />
                          <Button
                            style={{ borderRadius: "50%" }}
                            disabled={!deletevalue}
                            icon={<MdOutlineDelete />}
                            onClick={() => onDelete(item)}
                            color="danger"
                            variant="outlined"
                          />
                        </Space>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <Modal
          title={
            <div>
              <Space>
                <TeamOutlined />

                {id == "" ? "បង្កើតថ្មី  " : "កែប្រែ  "}
                {id == "" && (
                  <TreeSelect
                    style={{ width: 300 }}
                    className="styleTageStatusForm"
                    value={selectOrgCodeSave} // Controlled value
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={treeData} // Dynamically generated treeData
                    placeholder="Select Organization"
                    treeDefaultExpandAll
                    onChange={onChangeOrganization_NewStaff}
                  />
                )}
              </Space>
            </div>
          }
          open={visible}
          width={windowWidth <= 720 ? 400 : 900}
          footer={[
            <div>
              <Space>
                <Button
                  style={{ width: 150 }}
                  onClick={onCloseModal}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: 150 }}
                  type="primary"
                  onClick={onSave}
                  variant="primary"
                >
                  {id == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
          style={buttonStyle}
        >
          <div
            className="Model-Setting-Grid-User"
            disable={false}
            style={
              id == "" && selectedIndex == undefined
                ? { pointerEvents: "none", opacity: 0.5 }
                : {}
            }
          >
            <div style={{ margin: 5 }}>
              <div>
                <div>
                  {/* Display the selected image preview */}
                  {imagePreview && (
                    <div style={{ position: "relative" }}>
                      <div style={{ paddingLeft: 50, paddingBottom: 10 }}>
                        <Avatar
                          className="ionavatar"
                          src={
                            Empty_User == ""
                              ? linkPath + "empty_user.png"
                              : imagePreview
                          }
                          alt="Preview"
                        />
                      </div>
                    </div>
                  )}

                  {/* You can use the selectedFile state as needed (e.g., upload it to a server) */}
                </div>

                <div style={{ position: "absolute", top: 170, left: 190 }}>
                  <label for="fileInput" className="custom-file-label">
                    <Avatar
                      icon={<CiCamera />}
                      style={{ backgroundColor: "#4fa94d" }}
                    ></Avatar>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    defaultValue=""
                  />
                </div>

                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  លេខទូស័ព្ទ / PhoneNumber
                </p>

                <Input
                  className="styleTageStatusForm"
                  placeholder="Input Staff Phone Number"
                  onChange={onChangePhone}
                  value={phone}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  លេខមន្ត្រីរាជការ / IDNumber
                </p>
                <Input
                  type="text"
                  className="styleTageStatusForm"
                  placeholder="Input ID Number"
                  onChange={onChangeIDNumber}
                  value={idnumber}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះបុគ្គលិក / staff name
                </p>
                <Input
                  className="styleTageStatusForm"
                  placeholder="Input Staff Name"
                  onChange={onChangeStaffname}
                  value={staffname}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះ ឡាតាំង / English name
                </p>
                <Input
                  type="text"
                  className="styleTageStatusForm"
                  placeholder="Input Staff Name"
                  onChange={onChangeEnglishName}
                  value={staffname_eng}
                />
              </div>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ការកំណត់ស្គែនម៉ោង /Shift
                </p>
                <Select
                  value={shiftid}
                  //   onClick={onHandleClickSelectShiftWorkspace}
                  onChange={onHandleSelectShiftWork}
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Shift works"
                  options={ShiftWork.map((item) => ({
                    label: item.shift_name, // Displayed text
                    value: item.id, // Value used for selection
                  }))}
                />
              </div>
            </div>

            <div style={{ margin: 5 }}>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ភេទ / Gender
                </p>

                <Select
                  className="styleTageStatusForm"
                  placeholder="Select Gender"
                  onChange={onChangeGender}
                  style={{
                    width: "100%",
                  }}
                  value={gender}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="ស្រី">
                    ស្រី
                  </Option>
                  <Option className="styleTageStatusForm" value="ប្រុស">
                    ប្រុស
                  </Option>
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  សិទ្ធ / Provider
                </p>
                <Select
                  className="styleTageStatusForm"
                  onChange={onChangeProvider}
                  style={{
                    width: "100%",
                  }}
                  value={roleid}
                  allowClear
                >
                  {datarole
                    .filter((item) => {
                      // Show all roles if username is "0885290240"
                      if (getProfileOrg.username === "0885290240") {
                        return true;
                      }
                      // Otherwise, exclude "DBMA"
                      return item.name !== "DBMA";
                    })
                    .map((item) => (
                      <Option
                        className="styleTageStatusForm"
                        key={item.role_id}
                        value={item.role_id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ផ្នែក / Department
                </p>
                <Select
                  className="styleTageStatusForm"
                  placeholder="Select a Department" // Fixed typo ("Deparment" to "Department")
                  onChange={onChangeDepartment}
                  style={{
                    width: "100%",
                  }}
                  value={department} // Uncomment if you want to control the selected value
                >
                  {departmentlist.map((option) => (
                    <Option
                      className="styleTageStatusForm"
                      key={option.code} // Set a unique key for each option
                      value={option.code}
                    >
                      {option.department_name}{" "}
                      {/* Ensure this matches your MySQL field */}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  តួនារទី / Position
                </p>

                <Select
                  value={selectedPosition}
                  className="styleTageStatusForm"
                  onChange={onChangePostionsData}
                  onClick={onClickPostion}
                  style={{
                    width: "100%",
                  }}
                  disabled={!department} // Disable if no department is selected
                >
                  <Option value="">Select Position</Option>{" "}
                  {/* Use Option here */}
                  {positionslist.map((position) => (
                    <Option // Use Option instead of option
                      className="styleTageStatusForm"
                      key={position.id} // Set a unique key for each option
                      value={position.id}
                    >
                      {position.position_kh}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះ ខាងមុខ
                </p>
                <Select
                  className="styleTageStatusForm"
                  placeholder="Select"
                  onChange={onChangeFrontName}
                  style={{
                    width: "100%",
                  }}
                  value={frontname}
                >
                  {listFrontName.map((option) => (
                    <Option
                      className="styleTageStatusForm"
                      key={option.id}
                      value={option.frontname_kh}
                    >
                      {option.frontname_kh}{" "}
                      {/* Replace with the field from your MySQL table */}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ស្ថានភាពការងារ / Status
                </p>

                <Select
                  className="styleTageStatusForm"
                  onChange={onChangeStatus}
                  value={status}
                  style={{
                    width: "100%",
                  }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="ធ្វើការ">
                    ធ្វើការ
                  </Option>
                  <Option className="styleTageStatusForm" value="ព្យួរ">
                    ព្យួរ
                  </Option>
                  <Option className="styleTageStatusForm" value="បណ្តុះបណ្តាល">
                    បណ្តុះបណ្តាល
                  </Option>
                  <Option className="styleTageStatusForm" value="ឈប់">
                    ឈប់
                  </Option>
                </Select>
              </div>

              <div>
                <p
                  style={{
                    color: "red",
                    paddingTop: 5,
                    fontFamily: "Content ,sans-serif",
                  }}
                >
                  អនុញ្ញាតច្បាប់ / Approved Leave
                </p>

                <Tag color="magenta">
                  <div style={{ padding: 5 }}>
                    <Checkbox
                      checked={enable}
                      onChange={handleCheckboxChange_Enable}
                    >
                      Enable
                    </Checkbox>
                    <Checkbox
                      style={{ color: "red" }}
                      checked={disable}
                      onChange={handleCheckboxChange_Diable}
                    >
                      Disable
                    </Checkbox>
                  </div>
                </Tag>
              </div>
            </div>

            <div style={{ margin: 5 }}>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃខែកំណើត / DateOfBirth
                </p>
                <div>
                  <DatePicker
                    name={dob}
                    style={{
                      width: "100%",
                    }}
                    value={dayjs(dob)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_DOB}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃចូលធ្វើការ / Join Work
                </p>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    name={joinwork}
                    value={dayjs(joinwork)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_JoinWork}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ជំនាញ / Skill
                </p>
                <Select
                  className="styleTageStatusForm"
                  id="skill"
                  value={skill}
                  style={{
                    width: "100%",
                  }}
                  onChange={onChangeSkill}
                >
                  <option value="">ជ្រើសរើស ជំនាញ</option>
                  {ListSkillAPI !== null &&
                    ListSkillAPI.map((skill_item) => (
                      <option
                        className="styleTageStatusForm"
                        key={skill_item.id}
                        value={skill_item.id}
                      >
                        {skill_item?.skill_kh}
                      </option>
                    ))}
                </Select>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  កាំប្រាក់ / Level
                </p>
                <Select
                  className="styleTageStatusForm"
                  value={level}
                  style={{
                    width: "100%",
                  }}
                  onChange={onChangeLevel}
                >
                  <option value="">ជ្រើសរើស កាំប្រាក់</option>
                  {ListLevelAPI !== null &&
                    ListLevelAPI.map((level_item) => (
                      <option
                        className="styleTageStatusForm"
                        key={level_item.id}
                        value={level_item.level_kh}
                      >
                        {level_item.level_kh}
                      </option>
                    ))}
                </Select>
              </div>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃចុងក្រោយឡើងកាំប្រាក់
                </p>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    name={updateleveldate}
                    value={dayjs(updateleveldate)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_UpgradeLevel}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ប្រភេទបុគ្គលិក / Staff Type
                </p>
                <Select
                  name={stafftype}
                  style={{
                    width: "100%",
                  }}
                  className="styleTageStatusForm"
                  onChange={onChangeStafftype}
                  value={stafftype}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="រដ្ឋ">
                    រដ្ឋ
                  </Option>
                  <Option className="styleTageStatusForm" value="កិច្ចសន្យា">
                    កិច្ចសន្យា
                  </Option>
                  <Option className="styleTageStatusForm" value="ជួល">
                    ជួល
                  </Option>
                  <Option className="styleTageStatusForm" value="ផ្សេងៗ">
                    ផ្សេងៗ
                  </Option>
                </Select>
              </div>
              <div>
                <p
                  style={{
                    color: "red",
                    paddingTop: 5,
                    fontFamily: "Content ,sans-serif",
                  }}
                >
                  បញ្ចូលទិន្នន័យបញ្ជូនឡានជំងឺ ៖
                </p>

                <Tag color="magenta">
                  <div style={{ padding: 5 }}>
                    <Checkbox
                      checked={enable_car}
                      onChange={handleCheckboxChange_Enable_Car}
                    >
                      Enable
                    </Checkbox>
                    <Checkbox
                      style={{ color: "red" }}
                      checked={disable_car}
                      onChange={handleCheckboxChange_Diable_Car}
                    >
                      Disable
                    </Checkbox>
                  </div>
                </Tag>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title="លុបបុគ្គលិក "
          style={buttonStyle}
          open={visabledelete}
          onCancel={onCancelDelete}
          footer={[
            <div>
              <Space>
                <Button onClick={onclickDeleteNo}>No</Button>
                <Button onClick={() => onClickDeleteYes()} type="primary">
                  Yes
                </Button>
              </Space>
            </div>,
          ]}
        >
          <p style={buttonStyle}>
            <p style={{ color: "red" }}>តើអ្នកចង់លុប បុគ្គលិកឈ្មោះ </p>
            {staffname} ?
          </p>
        </Modal>

        <Modal
          visible={isModalOpenCropImage}
          // onRequestClose={() => setIsModalOpen(false)}
          title="Crop Image"
          onOk={getCroppedImage}
          onCancel={() => setModalOpenCropImage(false)}
          okText="Crop"
          cancelText="Cancel"
        >
          {image && (
            <Cropper
              crossOrigin="anonymous"
              src={image}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
              key={image} // Add this line
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default UserPage;
export const onDeleteFunction = (post) => {
  // setId(post.id);
  // setNoUpdate(post.no);
  // setImageDelete(post.image);
  // setStaffname(item.fullname);
  // setvisabledelete(true);
};
