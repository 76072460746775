import { Button, Drawer, Space, Table, Input, Popconfirm, Tag } from "antd";
import { useEffect, useState, useRef } from "react";
import { request } from "../../share/request";
import "../../styles/Home-module.css";
import TextArea from "antd/es/input/TextArea";
import { openNotification } from "../../share/message";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { Tabs, Modal } from "antd";
import UserAddress from "./useraddress";
import { Circles } from "react-loader-spinner";
import GetLoading from "../../share/getLoading";
import SuccessRequest from "../../share/SuccessRequest";
import { getCurrentUser } from "../../share/getProfile";
import UserFamilyPage from "./UserFamilyPage";

const { TabPane } = Tabs;

const UserDetail = ({ visible, onClose, idNumber, userid }) => {
  const getUsers = getCurrentUser();
  const successRequestRef = useRef();
  const [comfirmDelete, setComfirmDelete] = useState(false);
  const [comfirmDeleteStudy, setComfirmDeleteStudy] = useState(false);
  const [comfirmDeleteGrade, setComfirmDeleteGrade] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [tabKey, setTabKey] = useState("1"); // State variable to track the active tab key
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false); // State variable to trigger refresh

  const [dataSource, setDataSource] = useState([]);
  const [dataSource_Study, setDataSource_Study] = useState([]);
  const [dataSource_Grade, setDataSource_Grade] = useState([]);

  const [editingKey, setEditingKey] = useState("");

  const [editingKey_study, setEditingKey_study] = useState("");

  const [editingKey_grade, setEditingKey_grade] = useState("");
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isAddingRow, setIsAddingRow] = useState(false); // State variable to track if adding row
  const showAddButton = !dataSource || dataSource.length === 0;
  const showAddButton_study =
    !dataSource_Study || dataSource_Study.length === 0;
  const showAddButton_Grade =
    !dataSource_Grade || dataSource_Grade.length === 0;

  useEffect(() => {
    setTabKey(1);
    // Check if dataSource is null or empty
    if (!dataSource || dataSource.length === 0) {
      setIsAddingRow(true); // If dataSource is null or empty, set adding row flag to true
    }
    // Check if dataSource is null or empty
    if (!dataSource_Study || dataSource_Study.length === 0) {
      setIsAddingRow(true); // If dataSource is null or empty, set adding row flag to true
    }
  }, [dataSource, dataSource_Study]);

  useEffect(() => {
    getlist();
    // Fetch data from MySQL when component mounts
  }, [idNumber]);
  useEffect(() => {
    // Set the default tab key based on the drawer visibility
    if (visible) {
      console.log("is loading drawer");
      setRefreshFlag(true);
    }
  }, [visible, tabKey]);

  const getlist = async () => {
    console.log("Fetching data for ID:", idNumber);
    if (!idNumber) return; // Ensure idNumber is not null or undefined

    try {
      const res = await request(`userdetail` + idNumber, "get", {});
      console.log("API response:", res);

      if (res && res.API_Server_JobHistory) {
        setDataSource(res.API_Server_JobHistory);
        setDataSource_Study(res.API_Server_StudyHistory);
        setDataSource_Grade(res.API_Server_GradeHistory);
        console.log("Data:", res.API_Server_JobHistory);
      } else {
        console.error("Invalid API response:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsAddingRow(true);
    }
  };

  const handleSave = async (record) => {
    successRequestRef.current.success();
    try {
      const recoeds = {
        id: record.id,
        staff_id: idNumber,
        date_start: record.date_start,
        date_end: record.date_end,
        Office_Unit: record.Office_Unit,
        department: record.department,
        grade: record.grade,
        capital: record.capital,
        province_name: record.province_name,
        create_by: getUsers.fullname,
        status: 1,
      };

      let method = record.id ? "put" : "post";
      const res = await request("userdetail", method, recoeds);
      if (res) {
        openNotification(
          "success",
          "Success",
          record.id ? "Data updated successfully " : "Insert  successfully "
        );
        getlist();
      }
      setEditingKey("");
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      // Close the SuccessRequest message after the operation
      setTimeout(() => {
        successRequestRef.current.destroy(); // Close the loading message
      }, 2500); // Adjust this duration as needed
    }
  };
  const handleSave_study = async (record) => {
    successRequestRef.current.success();
    try {
      const records = {
        id: record.id,
        staff_id: idNumber,
        course_name: record.course_name,
        training_name: record.training_name,
        date_start: record.date_start,
        date_end: record.date_end,
        total_year: record.total_year,
        support_by: record.support_by,
        create_by: getUsers.fullname,
        status: 1,
      };

      let method = record.id ? "put" : "post";
      const res = await request("userdetailstudy", method, records);
      if (res) {
        openNotification(
          "success",
          "Success",
          record.id ? "Data updated successfully " : "Insert  successfully "
        );
        getlist();
      }
      setEditingKey_study("");
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      // Close the SuccessRequest message after the operation
      setTimeout(() => {
        successRequestRef.current.destroy(); // Close the loading message
      }, 2500); // Adjust this duration as needed
    }
  };
  const handleSave_grade = async (record) => {
    successRequestRef.current.success();
    try {
      const records = {
        id: record.id,
        staff_id: idNumber,
        grade_name: record.grade_name,
        date_start: record.date_start,
        date_end: record.date_end,
        date_letters: record.date_letters,
        letters_number: record.letters_number,
        create_by: getUsers.fullname,
        status: 1,
      };

      let method = record.id ? "put" : "post";
      const res = await request("userdetailgrade", method, records);
      if (res) {
        openNotification(
          "success",
          "Success",
          record.id ? "Data updated successfully " : "Insert  successfully "
        );
        getlist();
      }
      setEditingKey_grade("");
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      // Close the SuccessRequest message after the operation
      setTimeout(() => {
        successRequestRef.current.destroy(); // Close the loading message
      }, 2500); // Adjust this duration as needed
    }
  };

  const handleAdd = () => {
    // Create a new row object with default values or prompt the user for input
    const newRow = {
      id: "", // Generate a unique ID for the new row
      date_start: "",
      date_end: "",
      Office_Unit: "",
      department: "",
      grade: "",
      capital: "",
      province_name: "",
    };

    // Update the dataSource state by adding the new row
    setDataSource([...dataSource, newRow]);

    // Optionally, update the editingKey state if necessary
    setEditingKey(newRow.id);

    // Optionally, send the new data to your backend API to persist it
    // You can use your request function here to send a POST request with the new row data
  };
  const handleAdd_study = () => {
    // Create a new row object with default values or prompt the user for input
    const newRow = {
      id: "",
      staff_id: "",
      course_name: "",
      training_name: "",
      date_start: "",
      date_end: "",
      total_year: "",
      support_by: "",
      create_by: "",
      status: "",
    };

    // Update the dataSource state by adding the new row
    setDataSource_Study([...dataSource_Study, newRow]);

    // Optionally, update the editingKey state if necessary
    setEditingKey_study(newRow.id);

    // Optionally, send the new data to your backend API to persist it
    // You can use your request function here to send a POST request with the new row data
  };
  const handleAdd_grade = () => {
    // Create a new row object with default values or prompt the user for input
    const newRow = {
      id: "",
      staff_id: "",
      grade_name: "",
      date_start: "",
      date_letters: "",
      letters_number: "",
      date_end: "",
      create_by: "",
      status: "",
    };

    // Update the dataSource state by adding the new row
    setDataSource_Grade([...dataSource_Grade, newRow]);

    // Optionally, update the editingKey state if necessary
    setEditingKey_grade(newRow.id);

    // Optionally, send the new data to your backend API to persist it
    // You can use your request function here to send a POST request with the new row data
  };

  const actionTitle = showAddButton ? (
    <span>
      Action
      <Button
        type="primary"
        onClick={handleAdd}
        className="buttonstylekhmer"
        icon={<PlusCircleOutlined />}
        style={{ marginLeft: "8px" }}
      />
    </span>
  ) : (
    "Action"
  );
  const actionTitle_study = showAddButton_study ? (
    <span>
      Action
      <Button
        type="primary"
        onClick={handleAdd_study}
        className="buttonstylekhmer"
        icon={<PlusCircleOutlined />}
        style={{ marginLeft: "8px" }}
      />
    </span>
  ) : (
    "Action"
  );
  const actionTitle_Grade = showAddButton_Grade ? (
    <span>
      Action
      <Button
        type="primary"
        disabled={editingKey_grade !== ""}
        onClick={handleAdd_grade}
        className="buttonstylekhmer"
        icon={<PlusCircleOutlined />}
        style={{ marginLeft: "8px" }}
      />
    </span>
  ) : (
    "Action"
  );

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      editable: false,
    },
    {
      title: "ថ្ងៃខែឆ្នាំ ចូលបំរើការងារ",
      dataIndex: "date_start",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "date_start"),
    },
    {
      title: "ថ្ងៃខែឆ្នាំ បញ្ចប់ការងារ",
      dataIndex: "date_end",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "date_end"),
    },

    {
      title: "អង្គភាព",
      dataIndex: "Office_Unit",
      width: "auto",

      editable: true,
      render: (text, record) => renderEditableCell(text, record, "Office_Unit"),
    },
    {
      title: "ការរិយាល័យ-ផ្នែក",
      dataIndex: "department",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "department"),
    },
    {
      title: "ប្រភេទក្របខ័ណ្ឌ",
      dataIndex: "grade",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "grade"),
    },
    {
      title: " ក្រុង/ស្រុក/ខ័ណ",
      dataIndex: "capital",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "capital"),
    },
    {
      title: "រាជធានីខេត្ត",
      dataIndex: "province_name",
      editable: true,
      render: (text, record) =>
        renderEditableCell(text, record, "province_name"),
    },

    {
      title: "បង្កើតដោយ",
      dataIndex: "create_by",
      render: (_, record, index) => {
        return (
          <div>
            <Tag color="green">{record.create_by}</Tag>
          </div>
        );
      },
    },
    // Add similar render function for other editable columns
    {
      title: actionTitle,
      dataIndex: "action",
      width: "10%",
      render: (_, record, index) => {
        const editable = isEditing(record);
        const isDeleting = index === deletingIndex;
        console.log("is deleteting : " + isDeleting);
        return (
          <div>
            {editable ? (
              <Space size="middle">
                <Button type="primary" onClick={() => handleSave(record)}>
                  Save
                </Button>
                <Button onClick={handleCancel} type="primary" danger>
                  Cancel
                </Button>
              </Space>
            ) : (
              <>
                <Space>
                  <Button
                    disabled={editingKey !== ""}
                    onClick={() => handleEdit(record)}
                    type="primary"
                    icon={<MdOutlineEdit />}
                  />

                  <Popconfirm
                    placement="topRight"
                    title="Delete Record"
                    description="Do you want to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handDelete(record.id)}
                  >
                    <Button
                      disabled={editingKey !== ""}
                      type="primary"
                      danger
                      icon={<MdOutlineDelete />}
                    />
                  </Popconfirm>

                  {index === dataSource.length - 1 && isAddingRow && (
                    <Button
                      type="primary"
                      disabled={editingKey !== ""}
                      onClick={handleAdd}
                      className="buttonstylekhmer"
                      icon={<PlusCircleOutlined />}
                    ></Button>
                  )}
                </Space>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const columns_study = [
    {
      title: "No",
      dataIndex: "id",
      editable_study: false,
    },
    {
      title: "ឈ្មោះវគ្គសិក្សា",
      dataIndex: "course_name",
      width: "auto",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "course_name"),
    },
    {
      title: "គ្រឹះស្ថានបណ្តុះបណ្តាល",
      dataIndex: "training_name",
      width: "auto",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "training_name"),
    },
    {
      title: "ថ្ងៃខែឆ្នាំ ចូលសិក្សា",
      dataIndex: "date_start",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "date_start"),
    },
    {
      title: "ថ្ងៃខែឆ្នាំ បញ្ចប់ការសិក្សា",
      dataIndex: "date_end",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "date_end"),
    },
    {
      title: "រយៈពេលសិក្សា",
      dataIndex: "total_year",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "total_year"),
    },
    {
      title: "ឧបត្ថម្ភដោយ",
      dataIndex: "support_by",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_studyhistory(text, record, "support_by"),
    },
    {
      title: "បង្កើតដោយ",
      dataIndex: "create_by",
      render: (_, record, index) => {
        return (
          <div>
            <Tag color="green">{record.create_by}</Tag>
          </div>
        );
      },
    },
    // Add similar render function for other editable columns
    {
      title: actionTitle_study,
      dataIndex: "action",
      width: "10%",
      render: (_, record, index) => {
        const editable_study = isEditing_study(record);
        return (
          <div>
            {editable_study ? (
              <Space size="middle">
                <Button type="primary" onClick={() => handleSave_study(record)}>
                  Save
                </Button>
                <Button onClick={handleCancel_study} type="primary" danger>
                  Cancel
                </Button>
              </Space>
            ) : (
              <>
                <Space>
                  <Button
                    disabled={editingKey_study !== ""}
                    onClick={() => handleEdit_study(record)}
                    type="primary"
                    icon={<MdOutlineEdit />}
                  />

                  <Popconfirm
                    placement="topRight"
                    title="Delete Record"
                    description="Do you want to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handDelete_Study(record.id)}
                  >
                    <Button
                      disabled={editingKey_study !== ""}
                      type="primary"
                      danger
                      icon={<MdOutlineDelete />}
                    />
                  </Popconfirm>

                  {index === dataSource_Study.length - 1 && (
                    <Button
                      disabled={editingKey_study !== ""}
                      type="primary"
                      onClick={handleAdd_study}
                      className="buttonstylekhmer"
                      icon={<PlusCircleOutlined />}
                    ></Button>
                  )}
                </Space>
              </>
            )}
          </div>
        );
      },
    },
  ];
  const columns_grade = [
    {
      title: "No",
      dataIndex: "id",
      editable_study: false,
    },

    {
      title: "ប្រភេទកាំប្រាក់",
      dataIndex: "grade_name",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_gradehistory(text, record, "grade_name"),
    },
    {
      title: "ថ្ងៃខែឆ្នាំ ចាប់ប្តូរកាំប្រាក់",
      dataIndex: "date_start",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_gradehistory(text, record, "date_start"),
    },
    {
      title: "ថ្ងៃខែឆ្នាំ បញ្ចប់ប្តូរកាំប្រាក់",
      dataIndex: "date_end",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_gradehistory(text, record, "date_end"),
    },

    {
      title: "ថ្ងៃខែឆ្នាំ លិខិតប្រកាស",
      dataIndex: "date_letters",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_gradehistory(text, record, "date_letters"),
    },
    {
      title: "លិខិតប្រកាស លេខ ",
      dataIndex: "letters_number",
      editable_study: true,
      render: (text, record) =>
        renderEditableCell_gradehistory(text, record, "letters_number"),
    },

    {
      title: "បង្កើតដោយ",
      dataIndex: "create_by",
      render: (_, record, index) => {
        return (
          <div>
            <Tag color="green">{record.create_by}</Tag>
          </div>
        );
      },
    },
    // Add similar render function for other editable columns
    {
      title: actionTitle_Grade,
      dataIndex: "action",
      width: "10%",
      render: (_, record, index) => {
        const editable_grade = isEditing_grade(record);
        return (
          <div>
            {editable_grade ? (
              <Space size="middle">
                <Button type="primary" onClick={() => handleSave_grade(record)}>
                  Save
                </Button>
                <Button onClick={handleCancel_grade} type="primary" danger>
                  Cancel
                </Button>
              </Space>
            ) : (
              <>
                <Space>
                  <Button
                    disabled={editingKey_grade !== ""}
                    onClick={() => handleEdit_grade(record)}
                    type="primary"
                    icon={<MdOutlineEdit />}
                  />

                  <Popconfirm
                    placement="topRight"
                    title="Delete Record"
                    description="Do you want to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handDelete_Grade(record.id)}
                  >
                    <Button
                      disabled={editingKey_grade !== ""}
                      type="primary"
                      danger
                      icon={<MdOutlineDelete />}
                    />
                  </Popconfirm>

                  {index === dataSource_Grade.length - 1 && (
                    <Button
                      type="primary"
                      disabled={editingKey_grade !== ""}
                      onClick={handleAdd_grade}
                      className="buttonstylekhmer"
                      icon={<PlusCircleOutlined />}
                    ></Button>
                  )}
                </Space>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handDelete = async (record) => {
    //
    try {
      const res = await request(`userdetail/${record}`, "delete", {});

      if (res) {
        openNotification("success", "Success", "Record deleted successfully");
        getlist(); // Refresh the list after deletion
      } else {
        openNotification("error", "Error", "Failed to delete record");
      }
    } catch (error) {
      openNotification("error", "Error", error);
    }
  };

  const handDelete_Study = async (record) => {
    try {
      const res = await request(`userdetailstudy/${record}`, "delete", {});

      if (res) {
        openNotification("success", "Success", "Record deleted successfully");
        getlist(); // Refresh the list after deletion
      } else {
        openNotification("error", "Error", "Failed to delete record");
      }
    } catch (error) {
      openNotification("error", "Error", error);
    }
  };

  const handDelete_Grade = async (record) => {
    //userdetailgrade

    try {
      const res = await request(`userdetailgrade/${record}`, "delete", {});

      if (res) {
        openNotification("success", "Success", "Record deleted successfully");
        getlist(); // Refresh the list after deletion
      } else {
        openNotification("error", "Error", "Failed to delete record");
      }
    } catch (error) {
      openNotification("error", "Error", error);
    }
  };
  const handleEdit = (record) => {
    setEditingKey(record.id);
  };
  const handleEdit_study = (record) => {
    setEditingKey_study(record.id);
  };
  const handleEdit_grade = (record) => {
    setEditingKey_grade(record.id);
  };

  const handleCancel = () => {
    setEditingKey("");
    getlist();
  };
  const handleCancel_study = () => {
    setEditingKey_study("");
    getlist();
  };
  const handleCancel_grade = () => {
    setEditingKey_grade("");
    getlist();
  };
  const isEditing = (record, dataIndex) =>
    record.id === editingKey && dataIndex !== editingKey;
  const isEditing_study = (record, dataIndex) =>
    record.id === editingKey_study && dataIndex !== editingKey_study;
  const isEditing_grade = (record, dataIndex) =>
    record.id === editingKey_grade && dataIndex !== editingKey_grade;

  const renderEditableCell = (text, record, dataIndex) => {
    const editable = isEditing(record);
    console.log("key: " + editable);
    return editable ? (
      <TextArea
        value={text}
        className="word-wrap_textinput"
        onChange={(e) => {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => record.id === item.id);
          if (index > -1) {
            newData[index][dataIndex] = e.target.value;
            setDataSource(newData);
          }
        }}
      />
    ) : (
      text
    );
  };
  const renderEditableCell_studyhistory = (text, record, dataIndex) => {
    const editable_study = isEditing_study(record);

    return editable_study ? (
      <TextArea
        value={text}
        className="word-wrap_textinput"
        onChange={(e) => {
          const newData = [...dataSource_Study];
          const index = newData.findIndex((item) => record.id === item.id);
          if (index > -1) {
            newData[index][dataIndex] = e.target.value;
            setDataSource_Study(newData);
          }
        }}
      />
    ) : (
      text
    );
  };
  const renderEditableCell_gradehistory = (text, record, dataIndex) => {
    const editable_grade = isEditing_grade(record);

    return editable_grade ? (
      <TextArea
        value={text}
        className="word-wrap_textinput"
        onChange={(e) => {
          const newData = [...dataSource_Grade];
          const index = newData.findIndex((item) => record.id === item.id);
          if (index > -1) {
            newData[index][dataIndex] = e.target.value;
            setDataSource_Grade(newData);
          }
        }}
      />
    ) : (
      text
    );
  };

  function callback(key) {
    setTabKey(key);
  }

  const handleTabClick = (key) => {
    // If the clicked tab is "2", set the refresh flag to true
    if (key === "2") {
      setRefreshFlag(true);
    }
  };

  const handleRefreshComplete = () => {
    // Once the content is refreshed, reset the refresh flag to false
    setRefreshFlag(false);
  };

  const drawerTitle = (
    <span>
      <FaRegCheckCircle style={{ width: 20, height: 20 }} />
      ព័ត៏មានទូទៅរបស់បុគ្គលិក
    </span>
  );

  return (
    <div style={{ position: "relative" }}>
      {/* when click save or update data  */}

      {/* Your other components */}

      {/*  
        <Popconfirm
          open={comfirmDeleteStudy}
          title="Delete the Record"
          description="Are you sure to delete this recorde?"
          okText="Yes"
          cancelText="No"
          onCancel={()=>  setComfirmDeleteStudy(false)}
          onConfirm={() => handDeleteisOK_Study()} // Call handleDelete function when user confirms
        >
          <Button danger>Delete</Button>
        </Popconfirm> */}
      <Drawer
        width={1400}
        title={drawerTitle}
        placement="right"
        closable={false}
        open={visible}
        onClose={onClose}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              danger
              onClick={onClose}
              style={{ marginRight: 8, width: 150 }}
            >
              Close
            </Button>
          </div>
        }
      >
        <SuccessRequest ref={successRequestRef} />
        <Tabs defaultActiveKey={tabKey} onChange={handleTabClick}>
          <TabPane tab="ប្រវត្តិការងារ និងការសិក្សា" key="1">
            <div className="span-styels">
              <IoCheckmarkDoneOutline />
              ក. ប្រវត្តិការងារ(សូមបំពេញតាមលំដាប់ ពីថ្មីទៅចាស់)
            </div>

            <Table
              rowKey="id"
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={{
                pageSize: 10, // Set the number of rows per page
                // You can also include other pagination options here, such as total, defaultCurrent, etc.
              }}
              rowClassName={(record) =>
                isEditing(record) ? "editing-row" : ""
              } // Apply custom class to editing rows
            />

            <div className="span-styels">
              <IoCheckmarkDoneOutline />
              ខ. សញ្ញាបត្រ ឫវិញ្ញាបនបត្របណ្តុះបណ្តាលបន្តក្នុងពេលបំពេញការងារ
            </div>

            <Table
              bordered
              dataSource={dataSource_Study}
              columns={columns_study}
              pagination={{
                pageSize: 10, // Set the number of rows per page
                // You can also include other pagination options here, such as total, defaultCurrent, etc.
              }}
              rowClassName={(record) =>
                isEditing_study(record) ? "editing-row" : ""
              } // Apply custom class to editing rows
            />
          </TabPane>
          <TabPane tab="ប្រវត្តប្តូកាំប្រាក់" key="3">
            <div className="span-styels">
              <IoCheckmarkDoneOutline />
              ក. ប្រវត្តប្តូកាំប្រាក់ របស់បុគ្គលិក
            </div>
            <Table
              bordered
              dataSource={dataSource_Grade}
              columns={columns_grade}
              pagination={{
                pageSize: 10, // Set the number of rows per page
                // You can also include other pagination options here, such as total, defaultCurrent, etc.
              }}
              rowClassName={(record) =>
                isEditing_study(record) ? "editing-row" : ""
              } // Apply custom class to editing rows
            />
          </TabPane>
          <TabPane tab="អាស័យដ្ឋាន" key="2">
            <UserAddress
              idNumber={idNumber}
              refreshFlag={refreshFlag}
              onRefreshComplete={handleRefreshComplete}
            />
          </TabPane>
          <TabPane tab="ការសរសើរជូនរវង្វាន់ និងការដាក់វិន័យ" key="4">
            <p> ការសរសើរជូនរវង្វាន់ </p>
          </TabPane>
          <TabPane tab="ប្រវត្តផ្ទាល់ខ្លួន និងគ្រួសារ" key="5">
            <UserFamilyPage userid={userid} />
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

export default UserDetail;
