import React, { useEffect, useState } from "react";
import { Card, Typography, Avatar, Tag, Space, Button } from "antd";
import { Skeleton } from "antd";
import {
  MailOutlined,
  GithubOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  UserOutlined,
  WeiboSquareOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import LeaderCard from "./LeaderCard";
import { request } from "../../share/request";
import "../../styles/Home-module.css";
import "./Card.css";
import { getCurrentUser } from "../../share/getProfile";
import DepartmenList from "./DepartmenList";

const { Title, Text } = Typography;

const ProfileCard = () => {
  const getProfile = getCurrentUser();
  const [loading, setLoading] = useState(true);
  const [getListCard, setListCard] = useState([]);

  const [getListDepartments, setListDepartments] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      GetlistLeaderCard();
      setLoading(false);
    }, 2500);
  }, []);
  const GetlistLeaderCard = async () => {
    const param = new URLSearchParams({ idOrg: getProfile.org_code });

    const res = await request("userleadercard?" + param, "get", {});
    console.log(res);
    if (res) {
      console.log("list card: ", res.api_list_leader);
      setListCard(res.api_list_leader || []);
      setListDepartments(res.api_list_countdepartment);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        // borderRadius: 8,
        textAlign: "center",

        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Avatar size={124} icon={<UserOutlined />} style={{ marginBottom: 16 }} />
      <Title
        level={2}
        style={{
          color: "#1e8449",
          fontWeight: "bold",
          fontFamily: "Content",
        }}
      >
        {getProfile.org_name_kh}
      </Title>
      <Text type="secondary" style={{ color: "#1e8449", fontWeight: "bold" }}>
        {getProfile.org_name_eng}
      </Text>

      <div style={{ marginTop: 14 }}>
        <Title
          level={4}
          style={{
            fontFamily: "Content",
          }}
        >
          អំពីថ្នាក់ដឹកនាំ
        </Title>
        <div className="Leader-Card">
          {loading ? (
            <Skeleton active /> // Show Skeleton while loading
          ) : (
            getListCard.map((list, index) => (
              <LeaderCard key={index} list={list} loading={loading} /> // Show actual cards after loading
            ))
          )}
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <Title
          level={4}
          style={{
            fontFamily: "Content",
          }}
        >
          អំពីការិយាល័យ
        </Title>
        <div className="Department-Card">
          {loading ? (
            <Skeleton active /> // Show Skeleton while loading
          ) : (
            getListDepartments.map((list, index) => (
              <DepartmenList key={index} list={list} loading={loading} /> // Show actual cards after loading
            ))
          )}
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <Title level={4} style={{ fontWeight: "bold", fontFamily: "Content" }}>
          ទំនាក់ទំង និងតាមដានយើងខ្ញុំ
        </Title>
        <Space size="large">
          <Button
            type="link"
            icon={<MailOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<GithubOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<LinkedinOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<FacebookOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<YoutubeOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<TwitterOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<InstagramOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
          <Button
            type="link"
            icon={<LinkOutlined />}
            style={{ backgroundColor: "#f8f9f9" }}
          />
        </Space>
      </div>
    </div>
  );
};

export default ProfileCard;
