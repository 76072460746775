import React, { useEffect, useState, useRef } from "react";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  message,
  Upload,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Popconfirm,
  DatePicker,
  Pagination,
} from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";

import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission } from "../../share/getProfile";
import {
  getLettersContent,
  getCarActivityContent,
  getStatusContent,
  getTageStyle,
} from "../../share/getStatusContent";
import {
  formateDateClient,
  formateDateSystem,
  linkPath,
  linkPathPhotoDocument,
  toKhmerDay,
  toKhmerMonth,
  toKhmerYear,
} from "../../share/helper";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { FaRegEye } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import MyDatePicker from "../../share/MyDatePicker";
import { BsDownload } from "react-icons/bs";
import { BiCheckDouble } from "react-icons/bi";
import ComponentToPrint from "../rightmanager/PrintComponents";
import { useReactToPrint } from "react-to-print";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { getCurrentUser, getDataOrgnization } from "../../share/getProfile";
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RightManagerPage = () => {
  const datLinkOrgnization = getCurrentUser();
  const getProfileOrg = getCurrentUser();

  const itemsPerPage = 5; // 5 items per page in table
  const [objFilter, setObjFilter] = useState({
    page: 1,
    itemPerPage: itemsPerPage,
    txtSearch: "",
    org_code: getProfileOrg.org_code,
  });

  const [list, setList] = useState([]);

  const [UserID, setUserID] = useState("");

  const [RightManager_type, setRightManager_type] = useState();
  const [RightManager_Transfer_In_id, setRightManager_Transfer_In_id] =
    useState(null);
  const [RightManager_Transfer_Out_id, setRightManager_Transfer_Out_id] =
    useState(null);
  const [UserRightManager_ReasonJoin, setRightManager_ReasonJoin] = useState();
  const [UserRightManager_DateStart, setRightManager_DateStart] = useState();
  const [UserRightManager_DateEnd, setRightManager_DateEnd] = useState();
  const [
    UserRightManager_Date_Do_In_Documents,
    setRightManager_Date_Do_In_Documents,
  ] = useState();

  const [UserRightManager_Location, setRightManager_location] = useState();

  const [codIn, setCodeIN] = useState("");
  const [codOut, setCodeOut] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const [total_item, setTotalItem] = useState(0);
  const RightManagerPageValue = userPermission.find(
    (permission) => permission.pagename === "rightmanager"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = RightManagerPageValue;
  // -----------------  end permisstion page --------------------

  const [listGetRigthManagerIn, setListGetRigthManagerIn] = useState([]);
  const [listGetRigthManagerOut, setListGetRigthManagerOut] = useState([]);
  //--------------------------- end list photo
  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      itemPerPage: itemsPerPage,
      txtSearch: objFilter.txtSearch,
      org_code: getProfileOrg.org_code,
    });
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("rightmanager?" + param.toString(), "get", {});
      if (res) {
        setList(res.API_Server_RighManager[0] || []);
        setListGetRigthManagerIn(res.API_Server_RighManagerIn || []);
        setListGetRigthManagerOut(res.API_Server_RighManagerOut || []);
        setTotalItem(res.API_Server_RighManager[1][0].total_item);
        console.log("total_items):", total_item);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList(objFilter);
  }, []);
  const [Org_ImagePath, setOrgImagePath] = useState(
    "https://www.pngkit.com/png/full/981-9818240_your-logo-retulp-bus-stop.png"
  );
  useEffect(() => {
    const profileOrgData = JSON.parse(localStorage.getItem("profileOrgData"));
    if (profileOrgData) {
      const logoOrg = profileOrgData.find((item) => item.param === "logo");
      if (logoOrg && logoOrg.imagelink) {
        const fullImagePath = linkPath + logoOrg.imagelink;
        setOrgImagePath(fullImagePath); // Assuming SetOrgImagePath updates the state with the image path
      } else {
        console.warn("Logo not found in the profile data.");
      }
    }
  }, []); // This will run when ListOrganizationsImage is updated

  //-------------------------------------------

  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);

  //------------------- save data to letters --------

  const onSaveData = async () => {
    console.log(
      UserID,
      RightManager_type,
      codIn,
      codOut,
      UserRightManager_ReasonJoin,
      UserRightManager_DateStart,
      UserRightManager_DateEnd,
      UserRightManager_Location
    );
    if (codIn === "" || codOut === "") {
      openNotification(
        "error",
        "Error",
        codIn === "" ? "សូមជ្រើសរើស ប្រធាន" : " សូមជ្រើសរើស អនុប្រធានផ្ទេរសិទ្ទ"
      );
    } else {
      var data = {
        id: UserID,
        transfer_type: RightManager_type,
        transfer_in_id: codIn,
        transfer_out_id: codOut,
        reason: UserRightManager_ReasonJoin,
        date_do_in_documents: UserRightManager_Date_Do_In_Documents,
        date_start: UserRightManager_DateStart,
        date_end: UserRightManager_DateEnd,
        location: UserRightManager_Location,
        org_code: getProfileOrg.org_code,
      };

      let method = "post";
      if (UserID !== "" && UserID !== null) {
        method = "put";
      }

      const res = await request("rightmanager", method, data);
      setVisableNewModel(false);
      setLoadingSpin(true);
      if (res) {
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification(
          "success",
          "Success",
          method === "post" ? "Save Success" : "Update Success"
        );
        getList(objFilter); // re call function list
      } else {
        alert("Error!");
      }

      setLoadingSpin(false);
    }
  };

  const [DownloadReport, setDownloadReport] = useState(false);
  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };

  //-------------------------
  const onCancelModel = () => {
    setVisableNewModel(false);
  };

  const onChange_RightManager_Type = (value) => {
    setRightManager_type(value);
  };
  const onChange_RightManager_Transfer_In_id = (value) => {
    setCodeIN(value);
    setRightManager_Transfer_In_id(value);
  };
  const onChange_RightManager_Transfer_Out_id = (value) => {
    setCodeOut(value);
    setRightManager_Transfer_Out_id(value);
  };

  const onChange_UserRightManager_ReasonJoin = (event) => {
    setRightManager_ReasonJoin(event.target.value);
  };
  const onChange_UserRightManager_DateStart = (value) => {
    setRightManager_DateStart(formateDateSystem(value));
  };
  const onChange_UserRightManager_Date_Do_In_Documents = (value) => {
    setRightManager_Date_Do_In_Documents(formateDateSystem(value));
  };
  const onChange_UserRightManager_DateEnd = (value) => {
    setRightManager_DateEnd(formateDateSystem(value));
  };
  const onChange_UserRightManager_Location = (event) => {
    setRightManager_location(event.target.value);
  };

  const onEditData = async (item) => {
    setUserID(item.id);
    setRightManager_type(item.transfer_type);
    setCodeIN(item.transfer_in_id);
    setCodeOut(item.transfer_out_id);
    setRightManager_Transfer_In_id(item.tus_in_fullname);
    setRightManager_Transfer_Out_id(item.tus_out_fullname);
    setRightManager_ReasonJoin(item.reason);
    setRightManager_DateStart(formateDateSystem(item.date_start));
    setRightManager_DateEnd(formateDateSystem(item.date_end));
    setRightManager_Date_Do_In_Documents(
      formateDateSystem(item.date_do_in_document)
    );
    setRightManager_location(item.location);

    setVisableNewModel(true);
  };
  const onOpenSaveNew = () => {
    const today = new Date();
    // Formatting the date if needed (e.g., "YYYY-MM-DD")
    const formattedDate = today.toISOString().split("T")[0];
    setUserID("");
    setCodeOut("");
    console.log("this is id : " + UserID);
    setRightManager_type("លិខិតឧទ្ទេសនាមបេសកកម្ម");
    setRightManager_Transfer_In_id("");
    setRightManager_Transfer_Out_id("");

    setRightManager_ReasonJoin("");
    setRightManager_location("");
    setRightManager_DateStart(formattedDate);
    setRightManager_DateEnd(formattedDate);
    setRightManager_Date_Do_In_Documents(formattedDate);
    setVisableNewModel(true);
  };
  // print component
  const componentRef = useRef(null);
  const [itemPrint, setItemPrint] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (dataFetched && shouldPrint) {
      printFunction();
      setDataFetched(false); // Reset dataFetched after printing
      setShouldPrint(false); // Reset shouldPrint after printing
    }
  }, [dataFetched, shouldPrint]);

  const handlePrint = async (item) => {
    // const res = await request("letters/" + item.id, "get", {});
    // if (res) {
    //   setGetListJoinner(res.API_Server_listJoiner)
    // }
    setLoadingSpin(true);
    await new Promise((resolve) => setTimeout(resolve, 900));
    try {
      setDataFetched(true);
      setItemPrint(item);
      setShouldPrint(true);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  // end print component
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
  });
  const formattedDate = (date) => {
    const [day, month, year] = date.split("/");
    return ` ${"ថ្ងៃទី" + toKhmerDay(day)} ${"ខែ" + toKhmerMonth(month)} ${
      "ឆ្នាំ" + toKhmerYear(year)
    }`;
  };

  class MyComponentToPrint extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint
              reason={itemPrint.reason}
              transfer_in_name1={itemPrint.tus_in_frontname}
              transfer_in_name2={" " + itemPrint.tus_in_fullname}
              transfer_in_name3={" " + itemPrint.tus_in_position}
              transfer_out_name1={itemPrint.tus_out_frontname}
              transfer_out_name2={" " + itemPrint.tus_out_fullname}
              transfer_out_name3={" " + itemPrint.tus_out_position}
              date_start={formattedDate(
                formateDateClient(itemPrint.date_start)
              )}
              date_end={formattedDate(formateDateClient(itemPrint.date_end))}
              location={itemPrint.location}
              org_cover_by={datLinkOrgnization.org_cover_by}
              do_in_provinceName={datLinkOrgnization.do_in_province}
              org_type_string={datLinkOrgnization.stringType}
              org_type_code={datLinkOrgnization.org_type_code}
              location_contact={datLinkOrgnization.location_contact}
              Org_ImagePath={Org_ImagePath}
              date_document={itemPrint.date_do_in_document}
              postion_signature={itemPrint.tus_in_position}
            />
          )}
        </div>
      );
    }
  }
  const handlePageChange = async (pageNumber) => {
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      setCurrentPage(pageNumber);
      const objTem = {
        ...objFilter,
        page: pageNumber,
      };
      setObjFilter(objTem);
      getList(objTem);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const onChangeSearch = (e) => {
    const KeyWork_Search = e.target.value;
    setCurrentPage(1);
    const objTemSearch = {
      ...objFilter,
      txtSearch: KeyWork_Search,
      page: 1,
    };
    console.log(objTemSearch);
    setObjFilter(objTemSearch);
    getList(objTemSearch);
  };

  const onDeleteModel = (item) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: "Are you sure you want to delete this Item?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          deleteList(item.id);
          getList(objFilter);
        },
        // onCancel: () => {reject}
      });
    });
  };
  const deleteList = async (item) => {
    setLoadingSpin(true);
    await new Promise((resolve) => setTimeout(resolve, 900));
    try {
      console.log("onDeleteListItem", item);
      const res = await request("rightmanager/" + item, "delete", {});
      if (res) {
        openNotification("success", "Success", "Successfully deleted list");
      } else {
        openNotification("error", "Error", "Failed to delete list");
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    } finally {
      setLoadingSpin(false);
    }
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ លិខិតផ្ទេរសិទ្ធ ")}
            <Input
              style={{ width: 200 }}
              onChange={onChangeSearch}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              disabled={!printValue}
              className="buttonstylekhmer"
              onClick={onClickDownloadReport}
              type="primary"
              icon={<BsDownload />}
            >
              {" "}
              ទាយយករបាយការណ៍
            </Button>
            <Button
              onClick={onOpenSaveNew}
              disabled={!addvalue}
              className="custom-form-item"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>{" "}
        </div>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            //  striped
            // bordered
            //  hover
            className="rounded-table"
          >
            <thead>
              <tr class="table-success">
                <th>NO</th>
                <th>ប្រភេទលិខិត </th>
                <th>ប្រធានផ្ទេរសិទ្ធ</th>
                <th>ផ្ទេរសិទ្ធជូន</th>
                <th>ថ្ងៃខែឆ្នាំបេសកកម្ម</th>
                <th>ខ្លឹមសារលិខិត</th>
                <th>ទីតាំងបេសកកម្ម</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={index} class="table-Secondary">
                  <td>{item.id} </td>

                  <td>
                    <div>
                      <div style={{ padding: 5 }}>
                        <Tag
                          bordered={false}
                          color="processing"
                          style={{ fontFamily: "Content" }}
                        >
                          ថ្ងៃខែលិខិត៖{" "}
                          {formateDateClient(item.date_do_in_document)}
                        </Tag>
                        <Tag
                          style={{ fontFamily: "Content" }}
                          bordered={false}
                          color="processing"
                        >
                          {item.transfer_type}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag style={{ fontFamily: "Content" }} color="#f50">
                          {item.tus_in_frontname} {item.tus_in_fullname}{" "}
                        </Tag>
                      </div>

                      <div>
                        <Tag style={{ fontFamily: "Content" }} color="#f50">
                          {" "}
                          {item.tus_in_position}{" "}
                        </Tag>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div>
                      <div>
                        <Tag style={{ fontFamily: "Content" }} color="#2db7f5">
                          {item.tus_out_frontname} {item.tus_out_fullname}{" "}
                        </Tag>
                      </div>

                      <div>
                        <Tag style={{ fontFamily: "Content" }} color="#2db7f5">
                          {" "}
                          {item.tus_out_position}{" "}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Content" }}
                          bordered={false}
                          color="processing"
                        >
                          ចេញដំណើរ ៖ {formateDateClient(item.date_start)}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Content" }}
                          bordered={false}
                          color="processing"
                        >
                          ត្រឡប់មកវិញ ៖ {formateDateClient(item.date_end)}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td class="word-wrap">
                    <div style={{ whiteSpace: "pre-wrap", color: "#108ee9" }}>
                      <BiCheckDouble /> {item.reason}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div style={{ color: "rgb(11, 145, 112)" }}>
                        <GrLocation /> {item.location}
                      </div>
                    </div>
                  </td>

                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        disabled={!Editvalue}
                        onClick={() => onEditData(item)}
                        type="primary"
                        icon={<MdOutlineEdit />}
                      />

                      <div className="print-container">
                        <MyComponentToPrint
                          ref={componentRef}
                          key={index}
                          item={itemPrint}
                        />
                        <Button
                          danger
                          type="primary"
                          disabled={!printValue}
                          onClick={() => handlePrint(item)}
                          icon={<DownloadOutlined />}
                        />
                      </div>
                      <Button
                        disabled={!deletevalue}
                        type="primary"
                        icon={<MdOutlineDelete />}
                        onClick={() => onDeleteModel(item)}
                        danger
                      />
                      {/* <Button disabled={!Editvalue} type="primary" onClick={()=>onOpenEdit(item)}  >Edit</Button>{' '}
                                    <Button  disabled={!deletevalue}  type="primary" onClick={()=>onDeleteModel(item)} danger>Delete</Button> */}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Pagination
          onChange={handlePageChange}
          size="large"
          current={currentPage}
          pageSize={itemsPerPage}
          total={total_item}
        />

        <Modal
          title={UserID == "" ? "បង្កើតថ្មី " : "កែប្រែ"}
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          width={700}
          footer={[
            <div>
              <Space>
                {" "}
                <Button onClick={onCancelModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {UserID == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div style={{ width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ថ្ងៃខែរៀបចំ​លិខិត ៖
              </p>
              <MyDatePicker
                value={UserRightManager_Date_Do_In_Documents}
                onChange={onChange_UserRightManager_Date_Do_In_Documents}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                កំណត់សម្គាល់ លិខិត ៖
              </p>

              <Select
                className="styleTageStatusForm"
                value={RightManager_type}
                onChange={onChange_RightManager_Type}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="លិខិតអញ្ជើញ">
                  លិខិតអញ្ជើញ
                </Option>
                <Option
                  className="styleTageStatusForm"
                  value="លិខិតឧទ្ទេសនាមបេសកកម្ម"
                >
                  លិខិតឧទ្ទេសនាមបេសកកម្ម
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតបង្គាប់ការ">
                  លិខិតបង្គាប់ការ
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតផ្សេងៗ">
                  លិខិតផ្សេងៗ
                </Option>
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ប្រធាន៖
              </p>
              <Select
                className="styleTageStatusForm"
                value={codIn}
                onChange={onChange_RightManager_Transfer_In_id}
                style={{ width: "100%" }}
                allowClear
              >
                {listGetRigthManagerIn.map((option, index) => (
                  <Option
                    index={index}
                    className="styleTageStatusForm"
                    key={option.username}
                    value={option.username}
                  >
                    {option.fullname} | {option.position}
                  </Option>
                ))}
              </Select>

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ផ្ទេរសិទ្ធជូន អនុប្រធាន ៖
              </p>

              <Select
                className="styleTageStatusForm"
                value={codOut}
                onChange={onChange_RightManager_Transfer_Out_id}
                style={{ width: "100%" }}
                allowClear
              >
                {listGetRigthManagerOut.map((option) => (
                  <Option
                    className="styleTageStatusForm"
                    key={option.username}
                    value={option.username}
                  >
                    {option.fullname} | {option.position}
                    {/* Replace with the field from your MySQL table */}
                  </Option>
                ))}
              </Select>
            </div>

            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ចាប់ផ្តើមថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserRightManager_DateStart}
                onChange={onChange_UserRightManager_DateStart}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ដល់ថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserRightManager_DateEnd}
                onChange={onChange_UserRightManager_DateEnd}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content ,sans-serif",
                }}
              >
                ទីតាំងបេសកកម្ម ៖
              </p>
              <Input.TextArea
                value={UserRightManager_Location}
                onChange={onChange_UserRightManager_Location}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល ទីតាំងបេសកកម្ម....."
              />
            </div>
          </div>
          <div>
            <p
              style={{
                margin: 5,
                color: "darkgray",
                paddingTop: 10,
                fontFamily: "Content ,sans-serif",
              }}
            >
              ខ្លឹមសារលិខិតចូលរួម ៖
            </p>
            <Input.TextArea
              value={UserRightManager_ReasonJoin}
              onChange={onChange_UserRightManager_ReasonJoin}
              className="styleTageStatusForm"
              rows={5}
              placeholder="បញ្ចូល ខ្លឹមសាររបស់ឯកសារ.....:
                                   ចូលរួម....ស្តីពី ....... ដែលធ្វើឡើងនៅថ្ងៃទី... ដល់ថ្ងៃទី... ខែ.... ឆ្នាំ..... 
                                   "
            />
          </div>
        </Modal>

        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button className="custom-form-item" type="primary">
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_Start}
                  />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_End}
                  />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RightManagerPage;
