import { linkPath } from "./helper";
import { request } from "./request";

export const getCurrentUser = () => {
  var profileGetData = localStorage.getItem("profile");
  if (profileGetData != "" && profileGetData != null) {
    profileGetData = JSON.parse(profileGetData);
    return profileGetData;
  }
  return null;
};
// export const getPermission = () => {
//   var permissionGetData = localStorage.getItem("permission");
//   if (permissionGetData != "" && permissionGetData != null) {
//     permissionGetData = JSON.parse(permissionGetData);
//     return permissionGetData;
//   }
//   return null;
// };
export const getPermission = () => {
  let permissionGetData = localStorage.getItem("per_option");

  if (permissionGetData !== "" && permissionGetData !== null) {
    try {
      // Decode the Base64 encoded string and then parse it as JSON
      permissionGetData = JSON.parse(atob(permissionGetData));
      return permissionGetData;
    } catch (error) {
      console.error("Error decoding permission data:", error);
    }
  }

  return null;
};
export const getProfileImageLinkPath = () => {
  const Org_ImagePath = localStorage.getItem("profileOrgData");
  console.log("Fetched profileOrgData:", Org_ImagePath); // Debugging log
  if (Org_ImagePath && Org_ImagePath !== "") {
    try {
      return JSON.parse(Org_ImagePath); // Parse the stored JSON
    } catch (error) {
      console.error("Error parsing profileOrgData:", error);
    }
  }
  return []; // Return an empty array if no data is found or parse error occurs
};

export const isGetLogin = () => {
  const isUserLogin = localStorage.getItem("isLogin");
  if (
    isUserLogin == null ||
    isUserLogin == "null" ||
    isUserLogin == "" ||
    isUserLogin == 0
  ) {
    return false;
  }
  return true;
};
export const getDataOrgnization = () => {
  const profileOrgData = JSON.parse(localStorage.getItem("profileOrgData"));
  if (!profileOrgData) {
    console.warn("No profileOrgData found in localStorage.");
    return [];
  }

  const logoOrg = profileOrgData.find((item) => item.param === "logo");
  if (!logoOrg || !logoOrg.imagelink) {
    console.warn("Logo not found in the profile data.");
    return [];
  }
  let stringType = null; // Default state

  switch (logoOrg.org_type) {
    case 1:
      stringType = null; // For organization PHD
      break;
    case 2:
    case 3:
      stringType = `លោកប្រធានមន្ទីរសុខាភិបាលរដ្ឋបាលខេត្ត ${logoOrg.province_name}`;
      break;
    case 4:
    case 5:
    case 6:
      stringType = `លោកប្រធានការិយាល័យសុខាភិបាលស្រុកប្រតិបត្តិ ${logoOrg.province_name}`;
      break;
    default:
      stringType = ""; // Handle unexpected org_type_code
      break;
  }
  const fullImagePath = linkPath + logoOrg.imagelink;
  return {
    fullImagePath: fullImagePath,
    org_cover_by: logoOrg.org_cover_by,
    do_in_province: logoOrg.province_name,
    org_type_code: logoOrg.org_type,
    location_contact: logoOrg.location_contact,
    stringType: stringType,
  };
};

export const getListOrginzation = async () => {
  try {
    // Ensure getCurrentUser is called if it's a function
    const currentUser =
      typeof getCurrentUser === "function" ? getCurrentUser() : getCurrentUser;

    // Ensure org_code exists
    if (!currentUser || !currentUser.org_code) {
      throw new Error("Current user or org_code is not defined.");
    }

    const param = new URLSearchParams({ org_code: currentUser.org_code });
    const res = await request(`usermastergroup?${param.toString()}`, "get", {});

    // Check if res and res.list_api_master_group are valid
    if (res) {
      return res.list_api_master_group;
    } else {
      console.warn("No data found in the API response.");
      return null; // Default return value if no data is found
    }
  } catch (error) {
    console.error("Error in getListOrginzation:", error.message);
    return null; // Default return value on error
  }
};
