import { useEffect, useState, useRef } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  InputNumber,
  Form,
  Table,
  Button,
  Input,
  Space,
  Tag,
  Checkbox,
  Modal,
  TimePicker,
  Row,
  Col,
  message,
  Select,
  Drawer,
} from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import dayjs from "dayjs";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { getStatusContent } from "../../share/getStatusContent";
import { GetRandomColor } from "../../share/GetRamdomColor";
import { openNotification } from "../../share/message";
import { getCurrentUser } from "../../share/getProfile";
export default function ShiftPage() {
  const getProfile = getCurrentUser();
  const [id, setID] = useState("");
  const [shift_name, setShiftName] = useState("");
  const [chk_status, setChkStatus] = useState(false);
  const [chk_mon, setChkMon] = useState(false);
  const [chk_tue, setChkTue] = useState(false);
  const [chk_wed, setChkWed] = useState(false);
  const [chk_thu, setChkThu] = useState(false);
  const [chk_fri, setChkFri] = useState(false);
  const [chk_sat, setChkSat] = useState(false);
  const [chk_sun, setChkSun] = useState(false);

  const [on_duty_time, seton_duty_time] = useState("");
  const [beginning_in_start, setbeginning_in_start] = useState("");
  const [beginning_in_end, setbeginning_in_end] = useState("");
  const [off_duty_time, setoff_duty_time] = useState("");
  const [ending_in_start, setending_in_start] = useState("");
  const [ending_in_end, setending_in_end] = useState("");
  const [late_minutes, setlate_minutes] = useState("");
  const [leave_early_minutes, setleave_early_minutes] = useState("");
  const [beginning_out, setbeginning_out] = useState("");
  const [ending_out, setending_out] = useState("");
  const [workday_count, setworkday_count] = useState("");

  const [openModelNew, setOpenModelNew] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  useEffect(() => {
    getList();
  }, []);
  const formattedTime = (time) => {
    return dayjs(time, "HH:mm:ss").format("HH:mm");
  };
  const formattedDate = (date) => {
    return dayjs(date, "YYYY-MM-DDHH:mm:ss").format("DD/MM/YYYY");
  };
  const columns = [
    {
      key: "id",
      title: "លេខកូដ",
      dataIndex: "id",
    },
    {
      key: "shift_name",
      title: "ឈ្មោះពេលធ្វើការ",
      dataIndex: "shift_name",
      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {record.shift_name}
          </div>
        );
      },
    },

    {
      key: "id",
      title: "ចំនួនកំណត់ថ្ងៃ",
      dataIndex: "id",
      render: (text, record, index) => {
        const daysOfWeek = [
          { label: "Mon", value: record.mon },
          { label: "Tue", value: record.tue },
          { label: "Wed", value: record.wed },
          { label: "Thu", value: record.thu },
          { label: "Fri", value: record.fri },
          { label: "Sat", value: record.sat },
          { label: "Sun", value: record.sun },
        ];
        return (
          <div>
            {daysOfWeek.map((day) => (
              <Tag
                style={{ fontWeight: "bold" }}
                key={day.label}
                color={
                  day.label === "Sat" || day.label === "Sun" ? "red" : "green"
                }
              >
                {day.label} <Checkbox checked={!!day.value} />
              </Tag>
            ))}
          </div>
        );
      },
    },

    {
      key: "on_duty_time",
      title: "ម៉ោងធ្វើការ",
      dataIndex: "on_duty_time",
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
            }}
          >
            <Tag color="magenta" style={{ margin: 2 }}>
              In: {formattedTime(record.on_duty_time)}
            </Tag>
            <Tag color="magenta" style={{ margin: 2 }}>
              Out: {formattedTime(record.off_duty_time)}
            </Tag>
          </div>
        );
      },
    },
    {
      key: "on_duty_time",
      title: "ការកំណត់ស្គែនម៉ោង",
      dataIndex: "on_duty_time",
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
            }}
          >
            <Tag style={{ margin: 2 }}>
              {formattedTime(record.beginning_in_start)} -
              {formattedTime(record.beginning_in_end)}
            </Tag>
            <Tag style={{ margin: 2 }}>
              {formattedTime(record.ending_in_start)} -
              {formattedTime(record.ending_in_end)}
            </Tag>
          </div>
        );
      },
    },
    {
      key: "create_by",
      title: "អ្នកបង្កើត",
      dataIndex: "create_by",
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Tag style={{ margin: 2 }} color="green">
              {formattedDate(record.create_date)}
            </Tag>
            <Tag style={{ margin: 2 }} color="green">
              {record.create_by}
            </Tag>
          </div>
        );
      },
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
    },

    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button
                type="primary"
                onClick={() => onHandle_Edit(item)}
                icon={<EditOutlined />}
              />

              <Button danger type="primary" icon={<DeleteOutlined />} />
            </Space>
          </div>
        );
      },
    },
  ];

  const onHandle_Edit = (item) => {
    setShiftName(item.shift_name);
    setChkStatus(item.status == 1 ? true : false);
    setChkMon(item.mon == 1 ? true : false);
    setChkTue(item.tue == 1 ? true : false);
    setChkWed(item.wed == 1 ? true : false);
    setChkThu(item.thu == 1 ? true : false); // corrected line
    setChkFri(item.fri == 1 ? true : false);
    setChkSat(item.sat == 1 ? true : false);
    setChkSun(item.sun == 1 ? true : false);
    setOpenModelNew(true);
  };

  const getList = async () => {
    const param = new URLSearchParams({
      org_code: getProfile.org_code,
    });
    try {
      const res = await request("shifttype?" + param.toString(), "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setList(res.api_list_shift);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const onHandleSaveAndUpdate = async () => {
    setLoadingSpin(true);
    try {
      // Prepare the data object
      const data = {
        shift_name: shift_name,
        status: chk_status === true ? 1 : 0,
        mon: chk_mon === true ? 1 : 0,
        tue: chk_tue === true ? 1 : 0,
        wed: chk_wed === true ? 1 : 0,
        thu: chk_thu === true ? 1 : 0,
        fri: chk_fri === true ? 1 : 0,
        sat: chk_sat === true ? 1 : 0,
        sun: chk_sun === true ? 1 : 0,

        on_duty_time: on_duty_time ? on_duty_time.format("HH:mm") : null,
        off_duty_time: off_duty_time ? off_duty_time.format("HH:mm") : null,
        late_minutes: late_minutes,
        leave_early_minutes: leave_early_minutes,
        beginning_in_start: beginning_in_start
          ? beginning_in_start.format("HH:mm")
          : null,
        beginning_in_end: beginning_in_end
          ? beginning_in_end.format("HH:mm")
          : null,
        beginning_out: beginning_out ? beginning_out.format("HH:mm") : null,
        ending_in_start: ending_in_start
          ? ending_in_start.format("HH:mm")
          : null,
        ending_in_end: ending_in_end ? ending_in_end.format("HH:mm") : null,
        ending_out: ending_out ? ending_out.format("HH:mm") : null,
        workday_count: workday_count,
        org_code: getProfile.org_code,
        create_by: getProfile.fullname,
      };

      // Check if any required fields are null or undefined
      for (let key in data) {
        if (
          data[key] === null ||
          data[key] === undefined ||
          (typeof data[key] === "string" && data[key].trim() === "")
        ) {
          openNotification("warning", `Field ${key} cannot be empty.`);
          setLoadingSpin(false);
          return;
        }
      }

      console.table(data);

      // Decide the HTTP method based on the presence of 'id'
      const method = id == null ? "post" : "put";

      // Send the request
      const res = await request("shifttype", method, data);
      if (res) {
        openNotification("success", "Save is successfuly");
      }
    } catch (error) {
      openNotification("warning", error.message || "An error occurred");
    } finally {
      setOpenModelNew(false);
      setLoadingSpin(false);
      getList();
    }
  };

  const handleNew = () => {
    // Clear all fields to prevent data carry-over
    setID(null);
    setShiftName("");
    setChkStatus(false);
    setChkMon(false);
    setChkTue(false);
    setChkWed(false);
    setChkThu(false);
    setChkFri(false);
    setChkSat(false);
    setChkSun(false);
    seton_duty_time("");
    setoff_duty_time("");
    // Add similar resets for other fields
    setOpenModelNew(true);
  };

  const onHandleShiftNameChanged = (e) => {
    setShiftName(e.target.value);
  };
  const onHandle_chk_status = (e) => {
    console.log("onHandle_chk_status", e.target.checked);
    setChkStatus(e.target.checked);
  };
  const onHandle_chk_mon = (e) => {
    setChkMon(e.target.checked);
  };
  const onHandle_chk_tue = (e) => {
    setChkTue(e.target.checked);
  };
  const onHandle_chk_wed = (e) => {
    setChkWed(e.target.checked);
  };
  const onHandle_chk_thu = (e) => {
    setChkThu(e.target.checked);
  };
  const onHandle_chk_fri = (e) => {
    setChkFri(e.target.checked);
  };
  const onHandle_chk_sat = (e) => {
    setChkSat(e.target.checked);
  };
  const onHandle_chk_sun = (e) => {
    setChkSun(e.target.checked);
  };
  const onHandle_duty_time = (value) => {
    seton_duty_time(value);
  };
  const onHandle_beginning_in_start = (value) => {
    console.log("onHandle_beginning_ start " + value);
    setbeginning_in_start(value);
  };

  const onHandle_beginning_in_end = (value) => {
    setbeginning_in_end(value);
  };
  const onHandle_off_duty_time = (value) => {
    setoff_duty_time(value);
  };
  const onHandle_ending_in_start = (value) => {
    setending_in_start(value);
  };
  const onHandle_ending_in_end = (value) => {
    setending_in_end(value);
  };
  const onHandel_late_minutes = (value) => {
    setlate_minutes(value);
  };
  const onHandle_leave_early_minutes = (value) => {
    setleave_early_minutes(value);
  };
  const onHandle_beginning_out = (value) => {
    setbeginning_out(value);
  };
  const onHandle_ending_out = (value) => {
    setending_out(value);
  };
  const onHandle_workday_count = (value) => {
    setworkday_count(value);
  };
  // Example list of shift options
  const shiftOptions = [
    { label: "Morning", value: "morning" },
    { label: "Afternoon", value: "afternoon" },
    { label: "Evening", value: "evening" },
    { label: "Night", value: "night" },
  ];

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ម៉ោងធ្វើការ")}
            <div>
              <Input></Input>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              onClick={handleNew}
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}
      </div>

      <Drawer
        width={700}
        open={openModelNew}
        onClose={() => setOpenModelNew(false)}
        footer={[
          <Space>
            <Button key="cancel" onClick={() => setOpenModelNew(false)}>
              Cancel
            </Button>

            <Button type="primary" onClick={onHandleSaveAndUpdate}>
              {id === null ? "Save" : "Update"}
            </Button>
          </Space>,
        ]}
      >
        <div style={{ fontWeight: "bold" }}>
          <Row gutter={16}>
            <Col span={24} style={{ margin: 10 }}>
              <Row style={{ margin: 5 }}>
                <Col span={4}>
                  <label>Shift Name</label>
                </Col>
                <Col span={10}>
                  <Input
                    placeholder="input shift name"
                    value={shift_name}
                    onChange={onHandleShiftNameChanged}
                    style={{ width: 250, height: 40 }}
                  />
                  {/* <Select
                    value={shift_name}
                    onChange={onHandleShiftNameChanged}
                    style={{ width: 250, height: 40 }}
                    placeholder="Select a shift"
                  >
                    {shiftOptions.map((shift) => (
                      <Select.Option key={shift.value} value={shift.value}>
                        {shift.label}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Col>
                <Col span={8} style={{ marginLeft: 20 }}>
                  <Tag style={{ padding: 10 }} color="green">
                    <Checkbox
                      value={chk_status}
                      checked={chk_status}
                      onChange={onHandle_chk_status}
                    >
                      Status
                    </Checkbox>
                  </Tag>
                </Col>
              </Row>
            </Col>

            {/* Days of the Week */}
            <Col span={24} style={{ margin: 5 }}>
              <label>Days of the Week</label>
              <Row gutter={16} style={{ margin: 5 }}>
                <div
                  style={{
                    backgroundColor: "#f2f3f4",
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <div>
                    <Tag color="green" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_mon}
                        checked={chk_mon}
                        onChange={onHandle_chk_mon}
                      >
                        Monday
                      </Checkbox>
                    </Tag>
                    <Tag color="green" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_tue}
                        checked={chk_tue}
                        onChange={onHandle_chk_tue}
                      >
                        Tuesday
                      </Checkbox>
                    </Tag>
                    <Tag color="green" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_wed}
                        checked={chk_wed}
                        onChange={onHandle_chk_wed}
                      >
                        Wednesday
                      </Checkbox>
                    </Tag>
                  </div>

                  <div>
                    <Tag color="green" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_thu}
                        checked={chk_thu}
                        onChange={onHandle_chk_thu}
                      >
                        Thursday
                      </Checkbox>
                    </Tag>
                    <Tag color="green" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_fri}
                        checked={chk_fri}
                        onChange={onHandle_chk_fri}
                      >
                        Friday
                      </Checkbox>
                    </Tag>
                    <Tag color="red" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_sat}
                        checked={chk_sat}
                        onChange={onHandle_chk_sat}
                      >
                        Saturday
                      </Checkbox>
                    </Tag>
                    <Tag color="red" style={{ margin: 3, padding: 5 }}>
                      <Checkbox
                        value={chk_sun}
                        checked={chk_sun}
                        onChange={onHandle_chk_sun}
                      >
                        Sunday
                      </Checkbox>
                    </Tag>
                  </div>
                </div>
              </Row>
            </Col>

            {/* Shift Timing (On Duty, Beginning In) */}
            <Col span={24} style={{ margin: 5 }}>
              <label>Shift Timing (On Duty, Beginning In)</label>
              <Row gutter={16} style={{ margin: 5 }}>
                <Col span={8}>
                  <label>On Duty Time :</label>
                  <TimePicker onChange={onHandle_duty_time} format="HH:mm" />
                </Col>
                <Col span={8}>
                  <label>Beginning In Start :</label>
                  <TimePicker
                    onChange={(e) => setbeginning_in_start(e)}
                    format="HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <label>Beginning In End :</label>
                  <TimePicker
                    //value={beginning_in_end}
                    onChange={onHandle_beginning_in_end}
                    format="HH:mm"
                  />
                </Col>
              </Row>
            </Col>

            {/* Shift Timing (Off Duty, Ending In) */}
            <Col span={24} style={{ margin: 5 }}>
              <label>Shift Timing (Off Duty, Ending In)</label>
              <Row gutter={16} style={{ margin: 5 }}>
                <Col span={8}>
                  <label>Off Duty Time :</label>
                  <TimePicker
                    name="off_duty_time"
                    // value={off_duty_time}
                    onChange={onHandle_off_duty_time}
                    format="HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <label>Ending In Start :</label>
                  <TimePicker
                    // value={ending_in_start}
                    onChange={onHandle_ending_in_start}
                    format="HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <label>Ending In End :</label>
                  <TimePicker
                    //   value={ending_in_end}
                    onChange={onHandle_ending_in_end}
                    format="HH:mm"
                  />
                </Col>
              </Row>
            </Col>

            {/* Late and Early Minutes */}
            <Col span={24} style={{ margin: 5 }}>
              <label>Late and Early Leave</label>
              <Row gutter={16} style={{ margin: 5 }}>
                <Col span={8}>
                  <label>Late Minutes :</label>
                  <InputNumber
                    value={late_minutes}
                    onChange={onHandel_late_minutes}
                    min={0}
                  />
                </Col>
                <Col span={16}>
                  <label>Leave Early Minutes :</label>
                  <InputNumber
                    value={leave_early_minutes}
                    onChange={onHandle_leave_early_minutes}
                    min={0}
                  />
                </Col>
              </Row>
            </Col>

            {/* Beginning Out and Ending Out */}
            <Col span={24} style={{ margin: 5 }}>
              <label>Beginning Out and Ending Out</label>
              <Row style={{ margin: 10 }}>
                <Col span={16}>
                  <label style={{ width: 110 }}>Beginning Out :</label>
                  <TimePicker
                    //  value={beginning_out}
                    onChange={onHandle_beginning_out}
                    format="HH:mm"
                  />
                </Col>
                <Col span={16} style={{ marginTop: 5 }}>
                  <label style={{ width: 110 }}>Ending Out :</label>
                  <TimePicker
                    //   value={ending_out}
                    onChange={onHandle_ending_out}
                    format="HH:mm"
                  />
                </Col>
              </Row>
            </Col>
            {/* Beginning Out and Ending Out */}
            <Col span={24} style={{ margin: 5 }}>
              <Row gutter={16} style={{ margin: 5 }}>
                <Col span={16}>
                  <label>Workday Count : </label>
                  <InputNumber
                    value={workday_count}
                    onChange={onHandle_workday_count}
                    step={0.1}
                    min={0.1}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
}
