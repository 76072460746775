// import axios from "axios";

// import { message } from "antd";
// const base_url = "https://reportapi.devcomputing.pro/api/";
// //const base_url = "http://localhost:8083/api/";
// export const request = (url = "", method = "get", data = {}) => {
//   // create function success

//   //   បើសិន គេ បោះជា from data រឺ ជា Jons

//   const headers =
//     data instanceof FormData
//       ? { "Content-Type": "multipart/form-data" }
//       : { "Content-Type": "application/json" };
//   // alert(localStorage.getItem("token"));
//   const access_token = localStorage.getItem("token");

//   return axios({
//     url: base_url + url,
//     method: method,
//     data: data,
//     headers: {
//       ...headers,
//       Authorization: `Bearer ${access_token}`,
//     },
//   })
//     .then((res) => {
//       console.log("Response:", res.data); // Log response data for debugging
//       return res.data;
//     })
//     .catch((error) => {
//       var status = error.response?.status;
//       if (status == 401) {
//         message.error(
//           "Your session has been expired, please login again",
//           () => {
//             window.location.href = "/login";
//             // clear token
//             localStorage.clear();
//           }
//         );
//       } else if (status == 403) {
//         message.error("You don't have permission to access this page");
//       } else if (status == 404) {
//         message.error("Page not found");
//       } else if (status == 500) {
//         message.error("Server error");
//       } else {
//         message.error("Error");
//         window.location.href = "/login";
//         localStorage.clear();
//       }
//     })
//     .finally(() => {});
// };
import { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { getCurrentUser } from "./getProfile";

const base_url = "https://reportapi.devcomputing.pro/api/";
//const base_url = "http://localhost:8083/api/";

export const request = (url = "", method = "get", data = {}) => {
  const headers =
    data instanceof FormData
      ? { "Content-Type": "multipart/form-data; charset=UTF-8" }
      : { "Content-Type": "application/json; charset=UTF-8" };

  const access_token = localStorage.getItem("token");

  return axios({
    url: base_url + url,
    method: method,
    data: data,
    headers: {
      ...headers,
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((res) => {
      console.log("Response:", res.data); // Log response data for debugging
      return res.data;
    })
    .catch((error) => {
      console.error("Request failed:", error); // Log error details for debugging
      const status = error.response?.status;

      if (status === 401) {
        message.warning("Your session has expired, please log in again", () => {
          localStorage.clear();
          window.location.href = "/login";
        });
      } else if (status === 403) {
        message.warning("You don't have permission to access this page");
      } else if (status === 404) {
        console.log("Page not found");
      } else if (status === 500) {
        console.log("Server error  status code: 500");
      } else {
        console.log(error);
      }
    });
};

export const getMasterGroups = async () => {
  const param = new URLSearchParams({ org_code: getCurrentUser().org_code });

  const res = await request("usermastergroup?" + param.toString(), "get", {});
  if (res) {
    return res.list_api_master_group[0]; // Changed to return the result directly
  }
};
