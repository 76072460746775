import React, { useState, useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import { Button, Input, message, Modal, Popconfirm, Space, Table } from "antd";
import { request } from "../../share/request";
import { BiEdit } from "react-icons/bi";

import { MdDeleteForever } from "react-icons/md";
import { base_url } from "../../share/helper";
import { Circles } from "react-loader-spinner";
import { HiOutlineQrCode } from "react-icons/hi2";
import { QRCodeCanvas } from "qrcode.react"; // Import from qrcode.react
const GPSMapSettingPage = ({ org_code }) => {
  const [markerPosition, setMarkerPosition] = useState([13.527234, 105.967292]); // Default marker position
  const [showButton, setShowButton] = useState(false); // To show/hide the button
  const [buttonCoords, setButtonCoords] = useState({ x: 0, y: 0 }); // Button screen position
  const [clickedLatLng, setClickedLatLng] = useState(null); // Store the lat/lng of the right-click
  const [id, setID] = useState(null); // Store the
  const mapRef = useRef(null);
  // api locaiton
  const [loading, setLoading] = useState(false);
  const [listLocation, setListLocation] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // State to control re-render
  const [fgColor, setFgColor] = useState("#000000"); // Default black

  // Function to generate random colors
  const getRandomColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return randomColor;
  };

  const getlist = async () => {
    try {
      setLoading(true);
      const param = new URLSearchParams({ org_code: org_code }); // Replace 1 with a dynamic value if needed
      const res = await request(
        "settingorglocationgps?" + param.toString(),
        "get",
        {}
      );

      if (res && res.success == true) {
        setListLocation(res.data_api_location);
      } else {
        throw new Error(res?.message || "Failed to fetch locations.");
      }
    } catch (err) {
      console.log(err.message);
      console.error("Error fetching locations:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (org_code) {
      getlist();
    }
  }, [org_code]);
  // Custom Icon for the Marker
  const icon = new L.Icon({
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // Component to handle map events
  const MapEvents = () => {
    const map = useMapEvent("contextmenu", (event) => {
      const containerPoint = map.latLngToContainerPoint(event.latlng); // Convert lat/lng to screen coordinates
      setButtonCoords({ x: containerPoint.x, y: containerPoint.y }); // Set button position
      setClickedLatLng(event.latlng); // Save the lat/lng of the clicked position
      setShowButton(true); // Show the button
    });
    return null;
  };

  const handleSetNewPosition = () => {
    if (clickedLatLng) {
      setMarkerPosition([clickedLatLng.lat, clickedLatLng.lng]); // Update marker position
      setlat_value(markerPosition[0]);
      setlng_value(markerPosition[1]);
    }
    setShowButton(false); // Hide the button after setting the new position
  };

  const handleEdit = (record) => {
    setID(record.id);
    // Add your edit logic here
    setTxtLocationName(record.name_location);
    setlat_value(record.lat);
    setlng_value(record.lng);
    setOpenModel(true);
  };

  const handleDelete = (id) => {
    // Handle delete functionality
    console.log("Delete location with id:", id);
    // Add your delete logic here
  };
  const [qr, setQR] = useState(0);
  const [qrCodeValue, setQRCodeValue] = useState(null);
  const handelGenerateGRCode = async (record) => {
    setID(record.id);
    setTxtLocationName(record.name_location);
    setFgColor(getRandomColor());
    try {
      setQR(1);

      setQRCodeValue(record.full_link_qrcode);
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
    } finally {
      setLoadingSpin(false);
    }
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name_location",
      key: "name_location",
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
    },
    {
      title: "Longitude",
      dataIndex: "lng",
      key: "lng",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Space>
            <Button
              type="primary"
              icon={<HiOutlineQrCode />}
              onClick={() => handelGenerateGRCode(record)}
            />

            <Button
              type="primary"
              icon={<BiEdit />}
              onClick={() => handleEdit(record)}
            />

            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger icon={<MdDeleteForever />} />
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const onAddNewLocation = () => {
    setTxtLocationName("");
    setID(null);
    setOpenModel(true);
  };
  const onCanncelOpenModel = () => {
    setOpenModel(false);
  };
  const handleSave = async () => {
    const lat = lat_value;
    const lng = lng_value;
    const link = base_url + `locationgps?lat=${lat}&lng=${lng}`;
    // Validate form fields before making the request
    if (!markerPosition || !txt_locationName) {
      alert("Please fill in all the required fields.");
      return;
    }

    // Prepare the data to be sent to the backend
    const locationData = {
      id: id,
      name_location: txt_locationName, // Assuming nameLocation is the state for location name
      lat: lat_value, // Lat from the marker position
      lng: lng_value, // Lng from the marker position
      org_code: org_code, // Org code (this should come from the form or selected organization)
      link_qrcode: link,
    };
    const method = id === null ? "post" : "put";
    try {
      // Make the POST request to save the location
      const response = await request(
        "settingorglocationgps",
        method,
        locationData
      );

      if (response && response.success == true) {
        setOpenModel(false);
        // Handle success
        message.success("Location saved successfully!");

        // Optionally, refresh the location list or update the state
        getlist(); // Assuming getlist() is the function to refresh the list of locations
      } else {
        // Handle failure
        message.warning("Failed to save the location.");
      }
    } catch (error) {
      console.error("Error saving location:", error);
      message.warning("An error occurred while saving the location.");
    }
  };
  const [txt_locationName, setTxtLocationName] = useState("");
  const onChangeLocationName = (e) => {
    setTxtLocationName(e.target.value);
  };
  const qrCodeRef = useRef(null);

  // Function to download the high-resolution QR code
  // Function to download the QR code as a high-resolution image
  const downloadCanvasQRCode = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current; // Directly reference the canvas element
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpg", 1.0); // Get the image as a high-res PNG
      link.download = id + "-QRCodeScannAttendan -" + txt_locationName + ".png"; // Set the file name for download
      link.click();
    }
  };
  const [lat_value, setlat_value] = useState();
  const [lng_value, setlng_value] = useState();
  const onChangeLat = (e) => {
    setlat_value(e.target.value);
  };
  const onChangeLng = (e) => {
    setlng_value(e.target.value);
  };
  return (
    <>
      {loadingSpin && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          type="primary"
          style={{ width: "150px", marginBottom: 10 }}
          onClick={onAddNewLocation}
        >
          New Location GPS
        </Button>
        <div style={{ width: "100vh", marginRight: 10 }}>
          <Table
            columns={columns}
            dataSource={listLocation}
            loading={loading}
            rowKey={(record) => record.id} // Ensure each row has a unique key
          />
          {qr == 1 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <QRCodeCanvas
                  ref={qrCodeRef} // Attach the ref directly to the QRCodeCanvas
                  value={qrCodeValue}
                  style={{ height: "300px", width: "300px" }}
                  size={500} // Set a large size for high resolution
                  includeMargin={true} // Add margin to the QR code
                  level="H" // Error correction level: High
                  fgColor={fgColor}
                />
                <Button
                  type="primary"
                  onClick={downloadCanvasQRCode}
                  style={{ marginTop: 10 }}
                >
                  Download QR Code
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        width={"100vh"}
        open={openModel}
        title={id === null ? "New Location GPS" : "Update Location GPS"}
        footer={[
          <Button key="cancel" onClick={onCanncelOpenModel}>
            Cancel
          </Button>,
          <Button
            key={id === null ? "Save GPS" : "Update GPS"}
            type="primary"
            onClick={handleSave} // Connect Save button to the save function
            //  disabled={!markerPosition || !showButton} // Disable Save if no new position set
          >
            Save
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input.TextArea
            value={txt_locationName}
            onChange={onChangeLocationName}
            placeholder=" Name of orgnization location"
            rows={2}
            style={{ marginBottom: 5, width: "50%" }}
          />
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: 10 }}
          >
            <div>
              <span>Lat:</span>
              <Input value={lat_value} onChange={onChangeLat} type="number" />
            </div>
            <div style={{ marginLeft: 10 }}>
              <span>Lng: </span>
              <Input value={lng_value} onChange={onChangeLng} type="number" />
            </div>
          </div>
        </div>

        <div
          key={refreshKey} // Refresh key
          style={{
            width: "95vh",
            height: "60vh",
            position: "relative",
          }}
        >
          <MapContainer
            center={markerPosition}
            zoom={13}
            style={{
              width: "100%",
              height: "60vh",
            }}
            whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Marker at the current position */}
            <Marker position={markerPosition} icon={icon}>
              <Popup>
                Current Marker Position <br />
                Latitude: {markerPosition[0]} <br />
                Longitude: {markerPosition[1]}
              </Popup>
            </Marker>

            {/* Add map events for right-click */}
            <MapEvents />
          </MapContainer>
        </div>

        {/* Show the button on right-click */}
        {showButton && (
          <button
            onClick={handleSetNewPosition}
            style={{
              position: "absolute",
              left: `${buttonCoords.x}px`,
              top: `${buttonCoords.y}px`,
              transform: "translate(-50%, -50%)",
              padding: "10px 15px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              zIndex: 1000,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Add shadow here
            }}
          >
            Set Location
          </button>
        )}
      </Modal>
    </>
  );
};

export default GPSMapSettingPage;
