import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { request } from "../../share/request";
import { Stack } from "react-bootstrap";

import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Pagination,
} from "antd";

import "../../styles/Home-module.css";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import MainOrgPage from "./MainOrgPage";
import { formateDateClient } from "../../share/helper";
const { Option } = Select;
const OrganizationsPage = () => {
  const [province_id, setprovince_id] = useState(null);
  const [listProvince, setListProvince] = useState([]);
  const [org_type, setOrgType] = useState([]);
  const [province_name, setProvinceName] = useState(null);
  const [Org_Cover_by, setOrgCoverBy] = useState();
  const [TotalPage, setTotalPage] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [list, setList] = useState([]);
  const getProfile = getCurrentUser();
  const [visableModel, setVisableNew] = useState(false);
  const [DrawerVisable, setDrawerVisable] = useState(false);
  const [visableDeleteModel, setVisableDeleteModel] = useState(false);
  const [id, setID] = useState();
  const [domain_value, setDomain] = useState("");
  const [org_kh, setOrgKhmer] = useState("");
  const [org_eng, setOrgEnglish] = useState("");
  const [location_contact, setLocationContactValue] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [txt_search_text, setSearchText] = useState("");
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const deapartmentValue = userPermission.find(
    (permission) => permission.pagename === "department"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = deapartmentValue;
  // -----------------  end permisstion page --------------------
  //  get param values
  const [objFilter, setObjFilter] = useState({
    page: 1,
    org_name: "",
  });
  // ----------------- end param  --------------------
  useEffect(() => {
    // block form load
    getList(objFilter);
  }, []);
  useEffect(() => {
    console.log("Selected Item:", org_type);
  }, [org_type]);
  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      org_name: objFilter.org_name,
    });
    // create function success
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("settingorg?" + param.toString(), "get", {});
      if (res) {
        setList(res.list);
        setTotalPage(res.totalRecords);
        setListProvince(res.list_api_province);
        console.log("Setting org", res.list);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const buttonStyle = {
    fontFamily: "Content", // Replace 'YourFontFamily' with your desired font family
  };
  const custom_model = {
    fontFamily: "Content", // Replace 'YourFontFamily' with your desired font family
  };
  const onOpenNewTelegramBot = () => {
    setVisableNew(true);
    setOrgKhmer("");
    setOrgEnglish("");
    setDomain("");
    setID("");
  };
  const onCloseModel = () => {
    setVisableNew(false);
  };

  const onChangeOrgKhmer = (event) => {
    setOrgKhmer(event.target.value);
  };
  const onChangeOrgEnglish = (event) => {
    setOrgEnglish(event.target.value);
  };
  const onChangeDomain = (event) => {
    setDomain(event.target.value);
  };
  // const onChangeProvinceName = (event) => {
  //   setProvinceName(event.target.value);
  // };
  const StyleText_Label = {
    margin: 5,
    color: "darkgray",
    paddingTop: 10,
    fontFamily: "Content",
  };
  const onSave = async () => {
    const requestData = {
      org_code: id,
      org_name_kh: org_kh,
      org_name_eng: org_eng,
      domain: domain_value,
      create_by: getProfile.fullname,
      status: enable ? 1 : 0,
      Org_Cover_by: Org_Cover_by,
      province_name: province_name,
      org_type: org_type,
      location_contact: location_contact,
      pro_id: province_id,
    };
    console.table(requestData);
    // setLoadingSpin(true); // Show spinner during save/update operation

    try {
      let response;

      if (id === "" || id === undefined) {
        // New record - POST request to add organization
        response = await request("settingorg", "post", requestData);
      } else {
        // Update existing record - PUT request
        response = await request("settingorg", "put", requestData);
      }

      if (response?.success) {
        setVisableNew(false);
        openNotification(
          "success",
          "Operation Successful",
          id === "" ? "Added successfully!" : "Updated successfully!"
        );

        // Refresh list after successful save/update
        await getList(objFilter);
      } else if (response?.status === 500) {
        openNotification(
          "error",
          "Error",
          response.message || "An internal server error occurred."
        );
      }
    } catch (error) {
      // Handling different types of errors
      let errorMessage = "An error occurred";

      if (error.response) {
        // Server responded with a status other than 200
        errorMessage = error.response.data?.message || errorMessage;
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response received from the server.";
      } else {
        // An error occurred in setting up the request
        errorMessage = `Request error: ${error.message}`;
      }
      setVisableNew(false); // Close modal after successful operation
      openNotification("error", "Error", errorMessage);
    } finally {
      setLoadingSpin(false); // Hide spinner after the operation is completed
    }
  };

  // create save and update
  //   const onSave = async () => {
  //     const requestData = {
  //       org_code: id,
  //       org_name_kh: org_kh,
  //       org_name_eng: org_eng,
  //       domain: domain_value,
  //       create_by: getProfile.fullname,
  //       status: enable ? 1 : 0,
  //     };
  //     setLoadingSpin(true); // Show spinner during save/update operation
  //     let response;

  //     if (id === "" || id === undefined) {
  //       // New record - POST request to add organization
  //       response = await request("settingorg", "post", requestData);
  //     } else {
  //       // Update existing record - PUT request
  //       response = await request("settingorg", "put", requestData);
  //     }
  //     try {
  //       if (response && response.success) {
  //         openNotification(
  //           "success",
  //           "success",
  //           id === "" ? "Added successfully!" : "Updated successfully!"
  //         );

  //         // Refresh list after successful save/update
  //         await getList();
  //       } else if (response.status === 500) {
  //         openNotification("error", "error", response.message);
  //       }
  //     } catch (error) {
  //       if (error.response) {
  //         // Server responded with a status other than 200
  //         const errorMessage =
  //           error.response.data?.message || "An error occurred";
  //         openNotification("error", errorMessage);
  //       } else if (error.request) {
  //         // Request was made but no response was received
  //         openNotification("error", "No response received from the server");
  //       } else {
  //         // An error occurred in setting up the request
  //         openNotification("error", `Request error: ${error.message}`);
  //       }
  //     } finally {
  //       setVisableNew(false); // Close modal
  //       setLoadingSpin(false); // Hide spinner
  //     }
  //   };
  // const onSave = async () => {
  //   const requestData = {
  //     org_code: id,
  //     org_name_kh: org_kh,
  //     org_name_eng: org_eng,
  //     domain: domain_value,
  //     create_by: getProfile.fullname,
  //     status: enable ? 1 : 0,
  //     Org_Cover_by: Org_Cover_by,
  //     province_name: province_name,
  //     org_type: org_type,
  //     location_contact: location_contact,
  //     pro_id: province_id,
  //   };
  //   setLoadingSpin(true); // Show spinner during save/update operation

  //   try {
  //     setVisableNew(false); // Close modal
  //     let response;

  //     if (id === "" || id === undefined) {
  //       // New record - POST request to add organization
  //       response = await request("settingorg", "post", requestData);
  //     } else {
  //       // Update existing record - PUT request
  //       response = await request("settingorg", "put", requestData);
  //     }

  //     if (response && response.success) {
  //       openNotification(
  //         "success",
  //         "Operation Successful",
  //         id === "" ? "Added successfully!" : "Updated successfully!"
  //       );

  //       // Refresh list after successful save/update
  //       await getList(objFilter);
  //     } else if (response.status === 500) {
  //       openNotification(
  //         "error",
  //         "Error",
  //         response.message || "An internal server error occurred."
  //       );
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       // Server responded with a status other than 200
  //       const errorMessage =
  //         error.response.data?.message || "An error occurred";
  //       openNotification("error", "Error", errorMessage);
  //     } else if (error.request) {
  //       // Request was made but no response was received
  //       openNotification(
  //         "error",
  //         "Error",
  //         "No response received from the server."
  //       );
  //     } else {
  //       // An error occurred in setting up the request
  //       openNotification("error", "Error", `Request error: ${error.message}`);
  //     }
  //   } finally {
  //     setLoadingSpin(false); // Hide spinner
  //   }
  // };
  const [OrgTypeName, setOrgTypeName] = useState(null);
  const onOpenEditModel = (item) => {
    setOrgType(null);
    setOrgTypeName(null);
    // Set organization type
    const orgTypeValue = String(item.org_type); // Ensure it's a string
    setOrgType(orgTypeValue);
    setOrgTypeName(orgTypeMap[orgTypeValue] || "");
    setVisableNew(true);
    setID(item.org_code);
    setDomain(item.domain);
    setOrgKhmer(item.org_name_kh);
    setOrgEnglish(item.org_name_eng);
    setOrgCoverBy(item.org_cover_by);
    setProvinceName(item.province_name);
    setLocationContactValue(item.location_contact);
    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };
  const orgTypeMap = {
    1: "PHD | មន្ទីរសុខាភិបាលខេត្ត",
    2: "OD | ការិយាល័យស្រុកប្រតិបត្តិ",
    3: "PH | មន្ទីរពេទ្យខេត្ត",
    4: "PRH | មន្ទីរពេទ្យបង្អែកខេត្ត",
    5: "HC | មណ្ឌលសុខភាព",
    6: "HP | បុស៍សុខភាព",
  };
  const orgTypeMap_Port = {
    1: "PHD-",
    2: "OD-",
    3: "PH-",
    4: "PRH-",
    5: "HC-",
    6: "HP-",
  };

  const onChange_Org_Type = (value) => {
    setOrgType(value);
    setOrgTypeName(orgTypeMap[value] || null);
  };
  const onclickDeleteNo = () => {
    setVisableDeleteModel(false);
  };

  const onOpenModelDelete = (item) => {
    setVisableDeleteModel(true);
  };

  const getStatusContent = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            Enable
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            Disable
          </Button>
        );

      default:
        return null;
    }
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------
  const onCancel = () => {
    setVisableNew(false);
  };
  const onCancelDelete = () => {
    setVisableDeleteModel(false);
  };
  const onClose = () => {
    setDrawerVisable(false);
  };
  const [name_org, setNameOrg] = useState("");

  const onOpenDrawer = (item) => {
    setID(item.org_code);
    setprovince_id(item.pro_id);
    setNameOrg(item.org_name_kh);
    setDrawerVisable(true);
  };

  const onShowSizeChange = async (current) => {
    try {
      setLoadingSpin(true);
      console.log(current);
      setPageNumber(current);

      var objTmp = {
        ...objFilter,
        page: current,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onChangeOrganizationsSearch = (e) => {
    setSearchText(e.target.value);
    const objTmp = {
      ...objFilter,
      org_name: e.target.value,
      page: e.target.value === "" ? 1 : objFilter.page,
    };
    setObjFilter(objTmp);
    getList(objTmp);
  };

  const onChangeOrgCoverBy = (e) => {
    setOrgCoverBy(e.target.value);
  };

  const onChangeLocationContactValue = (e) => {
    setLocationContactValue(e.target.value);
  };
  const onChangeProvinceName = (value) => {
    console.log("on change", value);
    setprovince_id(value);
    const selectedProvince = listProvince.find(
      (province) => province.province_code === value
    );
    setProvinceName(selectedProvince?.province_name_khmer || ""); // Update province name
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      <div style={boxHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div> {boxHeaderTagText(" ព៏តមានរបស់អង្គភាព​ / Organizations")}</div>
          <Input
            allowClear
            placeholder="Search organizations..."
            onChange={onChangeOrganizationsSearch}
            style={{
              width: 250,
              height: 40,
            }}
          />
        </div>
        <div>
          <Button
            className="buttonstylekhmer"
            disabled={!addvalue}
            onClick={onOpenNewTelegramBot}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            បន្ថែមថ្មី
          </Button>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table className="rounded-table">
            <thead>
              <tr class="table-success">
                <th>No</th>
                <th>ខេត្ត </th>
                <th>ឈ្មោះអង្គភាព​ Khmer </th>
                <th>ឈ្មោះអង្គភាព​ English</th>
                <th>Telegram /Facebook...</th>
                <th>ថ្ងៃបង្កើត</th>
                <th>បង្កើតដោយ</th>
                <th>ស្ថានភាព</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={index}>
                  <td>{item.org_code}</td>
                  <td>
                    <Tag style={{ paddingTop: "2px" }} color="blue">
                      {item.province_name}
                    </Tag>
                  </td>
                  <td>
                    <Tag
                      style={{ paddingTop: "2px" }}
                      color={
                        item.org_type === 1
                          ? "blue"
                          : item.org_type === 2
                          ? "green"
                          : item.org_type === 3
                          ? "orange-inverse"
                          : "red"
                      }
                    >
                      {orgTypeMap_Port[item.org_type]} {item.org_name_kh}
                    </Tag>
                  </td>
                  <td>{item.org_name_eng}</td>
                  <td>
                    {item.domain.length > 10
                      ? `${item.domain.slice(0, 20)}...`
                      : item.domain}
                  </td>
                  <td>
                    <Tag>{formateDateClient(item.created_at)}</Tag>
                  </td>
                  <td>
                    <Tag color="blue" className="khmer-font">
                      {item.create_by}
                    </Tag>
                  </td>
                  <td>{getStatusContent(item.status)}</td>

                  <td style={{ width: 100 }}>
                    <Stack gap={2} direction="horizontal">
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        disabled={!Editvalue}
                        onClick={() => onOpenEditModel(item)}
                      ></Button>
                      <Button
                        icon={<SettingOutlined />}
                        type="primary"
                        disabled={!Editvalue}
                        onClick={() => onOpenDrawer(item)}
                      ></Button>
                      <Button
                        icon={<DeleteOutlined />}
                        disabled={!deletevalue}
                        onClick={() => onOpenModelDelete(item)}
                        type="primary"
                        danger
                      />
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Pagination
          //   showSizeChanger
          onChange={onShowSizeChange}
          defaultCurrent={pageNumber}
          total={TotalPage}
        />
      </div>

      <Drawer
        title={"ការកំណត់ផ្សេងៗរបស់អង្គភាព​​ " + name_org}
        width={1400}
        onClose={onClose}
        open={DrawerVisable}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <MainOrgPage idorg={id} province_id={province_id} />
      </Drawer>

      <Modal
        title={id == "" ? "បង្កើតថ្មី" : "កែប្រែ"}
        style={custom_model}
        width={580}
        open={visableModel}
        onCancel={onCancel}
        footer={[
          <div>
            <Space>
              <Button onClick={onCloseModel}>Cancel</Button>
              <Button type="primary" onClick={onSave}>
                {id == "" ? "Save" : "Update"}
              </Button>
            </Space>
          </div>,
        ]}
      >
        <Form style={buttonStyle}>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div>
              <p style={StyleText_Label}>ឈ្មោះ ខេត្ត ៖ </p>
              <Select
                disabled={id !== "" ? true : false}
                className="styleTageStatusForm"
                style={{ width: "100%" }}
                value={province_id}
                onChange={onChangeProvinceName}
                allowClear
              >
                {listProvince.map((record) => (
                  <Option
                    key={record.province_code}
                    value={record.province_code}
                    className="styleTageStatusForm"
                  >
                    {record.province_name_khmer}
                  </Option>
                ))}
              </Select>
              <p style={StyleText_Label}>ឈ្មោះអង្គភាព​ Khmer :</p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder=" ឈ្មោះអង្គភាព​ Khmer..."
                value={org_kh}
                rows={2}
                onChange={onChangeOrgKhmer}
                style={{ width: 250 }}
              />

              <p style={StyleText_Label}>ឈ្មោះអង្គភាព​ English :</p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder="ឈ្មោះអង្គភាព​ English..."
                value={org_eng}
                rows={2}
                onChange={onChangeOrgEnglish}
                style={{ width: 250 }}
              />
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ក្រោមរដ្ឋបាល :
                </p>
                <Input.TextArea
                  className="styleTageStatusForm"
                  placeholder=" បញ្ជូលឈ្មោ ក្រោមរដ្ឋបាល..."
                  value={Org_Cover_by}
                  rows={2}
                  onChange={onChangeOrgCoverBy}
                  style={{ width: 250 }}
                />
              </div>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  អង្គភាពស្ថិតក្នុងខេត្ត :
                </p>
                <Input.TextArea
                  className="styleTageStatusForm"
                  placeholder=" បញ្ជូលឈ្មោះ ខេត្ត..."
                  value={province_name}
                  rows={2}
                  //     onChange={onChangeProvinceName}
                  style={{ width: 250 }}
                />
              </div>
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <p style={StyleText_Label}>
                Link: Telegram /Domain /Facebook /Website :
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={5}
                placeholder="link: Telegram/Domain/Facebook / Website ..."
                value={domain_value}
                onChange={onChangeDomain}
                style={{ width: 250 }}
              />
              <p style={StyleText_Label}>
                ទីតាំងអង្គភាព និងលេខរដ្ឋបាលទំនាក់ទង :
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={5}
                placeholder="បញ្ចូល ទីតាំងអង្គភាព និងលេខរដ្ឋបាលទំនាក់ទង"
                value={location_contact}
                onChange={onChangeLocationContactValue}
                style={{ width: 250 }}
              />
              <p style={StyleText_Label}>ប្រភេទអង្គភាព :</p>
              <Select
                className="styleTageStatusForm"
                value={org_type}
                onChange={onChange_Org_Type}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="1">
                  PHD | មន្ទីរសុខាភិបាលខេត្ត
                </Option>
                <Option className="styleTageStatusForm" value="2">
                  OD | ការិយាល័យស្រុកប្រតិបត្តិ
                </Option>
                <Option className="styleTageStatusForm" value="3">
                  PH | មន្ទីរពេទ្យខេត្ត
                </Option>
                <Option className="styleTageStatusForm" value="4">
                  PRH | មន្ទីរពេទ្យបង្អែកខេត្ត
                </Option>
                <Option className="styleTageStatusForm" value="5">
                  HC | មណ្ឌលសុខភាព
                </Option>
                <Option className="styleTageStatusForm" value="6">
                  HP | បុស៍សុខភាព
                </Option>
              </Select>
              <p style={StyleText_Label}>ស្ថានភាព / Status :</p>
              <Tag style={{ marginTop: 10 }}>
                <div style={{ padding: 10, width: 235 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="លុបផ្នែក "
        style={buttonStyle}
        open={visableDeleteModel}
        onCancel={onCancelDelete}
        footer={[
          <div>
            <Button onClick={onclickDeleteNo}>No</Button>
            {/* <Button onClick={()=>onClickDeleteYes() } type="primary"   >Yes</Button> */}
          </div>,
        ]}
      >
        <p style={buttonStyle}>តើអ្នកចង់លុប នេះចេញពីប្រព័ន្ធ ?</p>
      </Modal>
    </div>
  );
};

export default OrganizationsPage;
