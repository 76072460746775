import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
// import { Html5QrcodeScanner } from "html5-qrcode";
import {
  Button,
  DatePicker,
  Table,
  Space,
  Input,
  Tag,
  Modal,
  Card,
  Avatar,
  TimePicker,
  Select,
} from "antd";
import { request } from "../../share/request";
import dayjs from "dayjs";
import moment from "moment";
import {
  formateDateClient,
  formateDateSystem,
  linkPathIDCard,
  timeGeneratorScan,
} from "../../share/helper";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { Circles } from "react-loader-spinner";
import {
  PlusCircleOutlined,
  CloseOutlined,
  FilterOutlined,
  UserOutlined,
} from "@ant-design/icons";

import style from "../../styles/style.module.css";
import "../../styles/StyleAttendance.css";
import { Pagination } from "antd";
import { BsDownload } from "react-icons/bs";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { openNotification } from "../../share/message";
import isoWeek from "dayjs/plugin/isoWeek";
// Function to calculate distance between two coordinates using Haversine formula
function getDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

const AttendanceQRCode = ({
  GetUserName,
  GetIdnumber,
  txtSearchVisable,
  btn_add,
}) => {
  const format = "HH:mm";
  const [Org_ImagePath, SetOrgImagePath] = useState(
    "https://www.pngkit.com/png/full/981-9818240_your-logo-retulp-bus-stop.png"
  );
  const [list_staff, setList_staff] = useState([]);
  const getProfile = getCurrentUser();
  const [totalPage, setTotalPage] = useState(null);
  const [model, setModel] = useState(false);
  const [model_invalid_link, setModel_invalid_link] = useState(false);
  const metor = 0.05;
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = getCurrentUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [userBrowserLat, setUserBrowserLat] = useState(0);
  const [userBrowserLng, setUserBrowserLng] = useState(0);
  const [distanceValue, setDistanceValue] = useState(0);
  const [showReader, setShowReader] = useState(true); // State to control reader visibility
  const [showReaderInvalidQRCode, setShowReaderInvalidQRCode] = useState(true); // State to show invalid QR code modal
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [UserDateStart, setUserDateStart] = useState(null);
  const [UserDateEnd, setUserDateEnd] = useState(null);
  const [listAttendanceByWeekend, setListAttendanceByWeekend] = useState([]);
  const [username, setUserName] = useState("");
  const [staffname, setStaffName] = useState("");
  const [visableModelAttendance, setVisableModelAttendance] = useState(false);
  const [tiem_Value, setTimeValue] = useState(null);
  const { Option } = Select;
  const [userdate_attandance, setUserdate_attandance] = useState(null);
  const itemsPerPage = 10;
  const [listFinal, setListFinal] = useState([]);
  const [DownloadReport, setDownloadReport] = useState(false);
  const [ListExcelData, setListExcelData] = useState([]);
  const [selectedDateStart, setSelectedDate_start] = useState(dayjs);
  const [selectedDateEnd, setSelectedDate_end] = useState(dayjs);
  // Extend dayjs with isoWeek plugin
  dayjs.extend(isoWeek);

  const getStartAndEndOfWeek = () => {
    // Start of the week (Monday)
    const startOfWeek = dayjs().startOf("isoWeek"); // ISO weeks start on Monday
    // End of the week (Sunday)
    const endOfWeek = dayjs().endOf("isoWeek");

    return {
      start: startOfWeek.format("YYYY-MM-DD"),
      end: endOfWeek.format("YYYY-MM-DD"),
    };
  };

  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: getProfile.org_code });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const logoProfile = res.list_api.find((item) => item.param === "logo");
        if (logoProfile) {
          SetOrgImagePath(linkPathIDCard + logoProfile.imagelink);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  // // date of week
  // const getStartAndEndOfWeek = () => {
  //   // Start of the week (Monday)
  //   const startOfWeek = dayjs().startOf("week").add(1, "day"); // dayjs starts week on Sunday by default
  //   // End of the week (Sunday)
  //   const endOfWeek = dayjs().endOf("week").add(1, "day");

  //   return {
  //     start: startOfWeek.format("YYYY-MM-DD"),
  //     end: endOfWeek.format("YYYY-MM-DD"),
  //   };
  // };
  const { start, end } = getStartAndEndOfWeek();
  // search data in db
  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtSearch: "",
    d_start: UserDateStart || start,
    d_end: UserDateEnd || end,
    // d_start: "2024-11-06",
    // d_end: "2024-11-10",
    org_code: userProfile.org_code,
    username: GetUserName === undefined ? "" : GetUserName,
  });
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const AttendancePageValue = userPermission.find(
    (permission) => permission.pagename === "attendance"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = AttendancePageValue;
  // -----------------  end permisstion page --------------------
  const pagesizeIndex = 10;
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const getListEmployee = async () => {
    if (!getProfile?.org_code) {
      console.error("Organization code is missing");
      return;
    }

    const param = new URLSearchParams({
      org_code: getProfile.org_code,
    });

    try {
      const res = await request(`leavestaff?${param.toString()}`, "get", {});
      if (res && res.list_staff) {
        setList_staff(res.list_staff);
      } else {
        console.warn("No staff list returned from the response.");
      }
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };
  const onChangeStaff = (value) => {
    setUserName(value);
    setStaffName(value);
    console.log("onChangeStaff', value:", username);
  };
  const getList = async (ParamObjFilter) => {
    console.log("parsing list 1: ", objFilter);
    // create function success
    try {
      const params = new URLSearchParams({
        page: ParamObjFilter.page,
        txtSearch: ParamObjFilter.txtSearch,
        d_start: ParamObjFilter.d_start,
        d_end: ParamObjFilter.d_end,
        org_code: ParamObjFilter.org_code,
        username: ParamObjFilter.username,
      });
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request(
        "attendanceqrcode?" + params.toString(),
        "get",
        {}
      );
      if (res && res.list) {
        if (ParamObjFilter.txtSearch) {
          console.log("Filtered Data:", res.list);
          setFilteredDataSource(res.list[0]);
          setListAttendanceByWeekend(res.api_DataWeekend[1]);
          setListFinal(res.api_datefinal[0]);
        } else {
          console.log("Non-Filtered Data:", res.list);
          setFilteredDataSource(res.list[0]);
          setListAttendanceByWeekend(res.api_DataWeekend[1]);
          setListFinal(res.api_datefinal[0]);
        }
        if (
          res &&
          res.list &&
          Array.isArray(res.list[1]) &&
          res.list[1].length > 0
        ) {
          const firstItem = res.list[1][0]; // Accessing the first item from the nested array
          if (firstItem && firstItem.total !== undefined) {
            const totalItems = firstItem.total;
            console.log("Total Items:", totalItems);
            setTotalPage(totalItems);
          } else {
            console.log("Error: 'total' property not found or undefined.");
            // Handle the case where 'total' property is missing or undefined
          }
        } else {
          console.log("Error: Invalid response structure or empty array.");
          // Handle other error scenarios related to response structure
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImageLinkOrganization();
    console.log(" loading image path:", Org_ImagePath);
    getListEmployee();
    getList(objFilter);
    console.log("param search :", objFilter);
  }, []);

  // for reader qrcode wep // do not delete tthis code
  //   useEffect(() => {
  //     getList();
  //     if (showReader && showReaderInvalidQRCode) {
  //       let scanner = null;
  //       const onScanSuccess = (googleMapsLink) => {
  //         setResult(googleMapsLink);
  //         setIsLoading(true);
  //         scanner.clear();
  //         extractLatLng(googleMapsLink);
  //       };
  //       const onScanError = (error) => {
  //         console.error("QR error:", error);
  //       };
  //       scanner = new Html5QrcodeScanner("reader", {
  //         fps: 10,
  //         qrbox: {
  //           width: 250,
  //           height: 250,
  //         },
  //       });
  //       scanner.render(onScanSuccess, onScanError);
  //       return () => {
  //         if (scanner) {
  //           scanner.clear();
  //         }
  //       };
  //     } else {
  //       setShowReader(true);
  //       setShowReaderInvalidQRCode(true);
  //     }
  //   }, [latitude, longitude, distanceValue, showReader, showReaderInvalidQRCode]);

  const extractLatLng = (googleMapsLink) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = googleMapsLink.match(regex);
    if (match && match.length >= 3) {
      const lat = parseFloat(match[1]);
      const lng = parseFloat(match[2]);
      setLatitude(lat);
      setLongitude(lng);
      handleSaveAttendance(lat, lng);
    } else {
      setShowReaderInvalidQRCode(true);
      setModel_invalid_link(true);
      setIsLoading(false);
    }
  };

  const handleSaveAttendance = async (latQR, lngQR) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          setUserBrowserLat(position.coords.latitude);
          setUserBrowserLng(position.coords.longitude);
          await new Promise((resolve) => setTimeout(resolve, 400));
          const distance = getDistance(
            latQR,
            lngQR,
            position.coords.latitude,
            position.coords.longitude
          );
          setDistanceValue(distance);
          if (distance <= metor) {
            const data = {
              staff_code: userProfile.username,
              staff_name: userProfile.fullname,
              scan_date: formateDateSystem(),
              scan_time: timeGeneratorScan(),
            };
            try {
              const res = await request("attendanceqrcode", "post", data);
              console.log("Save Attendance:", res);
            } catch (error) {
              console.error("Error saving attendance:", error);
            } finally {
              setIsLoading(false);
              setModel(true);
              setShowReader(true);
              setShowReaderInvalidQRCode(true);
            }
          } else {
            console.log("QR code location is too far from current location.");
            setIsLoading(false);
            setModel(true);
          }
        },
        function (error) {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported in this browser.");
    }
  };
  const handleCLoseInvalidLinkModal = () => {
    setModel_invalid_link(false);
    setShowReaderInvalidQRCode(true);
  };

  // const onChangePage = async (page) => {
  //   try {
  //     setLoading(true);
  //     var objTmp = {
  //       ...objFilter,
  //       page: page,
  //     };
  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     await setObjFilter(objTmp);
  //     getList(objTmp);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSearchInputChange = async (event) => {
    const inputValue = event.target.value;
    setCurrentPage(1);
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      onSearch(inputValue);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    var objTmp = {
      ...objFilter,
      txtSearch: value == "" || null ? "" : value,
      page: value === "" ? 1 : objFilter.page,
    };
    setObjFilter(objTmp);
    getList(objTmp);
  };
  const timeScan = (timeString) => {
    // Assuming timeString format is "###" (e.g., "123")
    if (!timeString) return ""; // Handle empty timeString case

    // Extract hours and minutes from the timeString
    const hours = Math.floor(timeString / 100); // Extract hours (e.g., 123 -> 1)
    const minutes = timeString % 100; // Extract minutes (e.g., 123 -> 23)

    // Determine AM/PM and format hours and minutes
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

    // Construct formatted time string (e.g., "1:23 PM")
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Define common styles outside the JSX
  const commonStyle = {
    justifyContent: "center",
    alignItems: "start",
    height: 20,
    fontSize: 4,
    borderRadius: 5,
    padding: "0 5px",
    margin: 2,
  };

  const textStyle = {
    margin: 2,
    fontWeight: "bold",
    color: "white",
  };
  const createDayColumn = (
    key,
    title,
    dataIndex,
    listFinal,
    getRandomColor
  ) => ({
    key,
    title,
    dataIndex,

    render: (value, record) => {
      const times = value ? value.split(", ") : [];

      // Calculate the start and end of the week
      const today = dayjs();
      const startOfWeek = today.startOf("week").add(1, "day"); // Monday
      const dayIndex = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].indexOf(key);
      const targetDate = startOfWeek.add(dayIndex, "days"); // Adjust to the specific day
      console.log("this is " + targetDate);
      // Filter listFinal for the current day
      const matchingItem = listFinal.filter((item) => {
        const scanDate = dayjs(item.scan_date);
        return (
          scanDate.isSame(targetDate, "date") &&
          item.user_id === record.idnumber
        );
      })[0]; // Get the first match if available
      const percentlimit = matchingItem
        ? Math.min(100, Math.round(matchingItem.total_percent))
        : ".."; // Ensure no value exceeds 100 or fallback to "N/A"
      const percent = matchingItem ? `${percentlimit}%` : "..";
      const work_minute = matchingItem
        ? matchingItem.fullday_total_minute_work
        : "..";
      const work_status = matchingItem ? matchingItem.arrival_status : "..";

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "auto",
            paddingRight: "2px",
            borderRight: "1px solid gray",
            //  backgroundColor: "blanchedalmond",
          }}
        >
          <div>
            {times &&
              Array.isArray(times) &&
              times.map((time, index) => (
                <Tag
                  key={index}
                  color={getRandomColor()}
                  style={{
                    marginBottom: "5px",
                    borderRadius: 20,
                    fontWeight: "bold",
                  }}
                >
                  {time}
                </Tag>
              ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {[
              { value: `${percent}`, bgColor: getRandomColor() },
              { value: `${work_minute}m`, bgColor: getRandomColor() },
              { value: `${work_status}`, bgColor: getRandomColor() },
            ].map((item, index) => (
              <Tag
                key={index}
                color={item.bgColor}
                //  color="green"
                style={{
                  fontSize: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "1px 0",
                }}
              >
                {item.value}
              </Tag>
            ))}
          </div>
        </div>
      );
    },
  });
  const daysOfWeek = [
    { key: "Monday", title: "ថ្ងៃច័ន្ទ | Mon" },
    { key: "Tuesday", title: "ថ្ងៃអង្គារ៍ | Tue" },
    { key: "Wednesday", title: "ថ្ងៃពុធ | Wed" },
    { key: "Thursday", title: "ថ្ងៃព្រហស្បតិ៍ | Thu" },
    { key: "Friday", title: "ថ្ងៃសុក្រ | Fri" },
    { key: "Saturday", title: "ថ្ងៃសៅរ៍ | Sat" },
    { key: "Sunday", title: "ថ្ងៃអាទិត្យ | Sun" },
  ];

  const weekColumns = daysOfWeek.map((day) =>
    createDayColumn(day.key, day.title, day.key, listFinal, getRandomColor)
  );
  const columns = [
    {
      key: "id",
      title: "ល.រ",
      dataIndex: "id",

      render: (value, item, index) =>
        index + 1 + itemsPerPage * (objFilter.page - 1),
    },
    {
      key: "idnumber",
      title: "ពត៍មានបុគ្គលិក",
      dataIndex: "idnumber",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: 250,
              margin: "5px",
              borderRadius: "8px",
              backgroundColor: "",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ padding: 10 }}>
                <Avatar
                  size={60}
                  icon={<UserOutlined />}
                  style={{ marginBottom: 16 }}
                />
              </div>
              <div style={{ padding: 10, fontWeight: "bold", color: "gray" }}>
                {record.fullname}
                <div>
                  {record.department} | {record.position}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    ...weekColumns,
  ];
  // for old
  // const columns = [
  //   {
  //     key: "id",
  //     title: "ល.រ",
  //     dataIndex: "id",

  //     render: (value, item, index) =>
  //       index + 1 + itemsPerPage * (objFilter.page - 1),
  //   },

  //   {
  //     key: "idnumber",
  //     title: "ពត៍មានបុគ្គលិក",
  //     dataIndex: "idnumber",
  //     render: (text, record, index) => {
  //       return (
  //         <div
  //           style={{
  //             width: 250,

  //             margin: "5px",
  //             borderRadius: "8px",
  //             backgroundColor: "",
  //             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //           }}
  //         >
  //           <div style={{ display: "flex", flexDirection: "row" }}>
  //             <div style={{ padding: 10 }}>
  //               <Avatar
  //                 size={60}
  //                 icon={<UserOutlined />}
  //                 style={{ marginBottom: 16 }}
  //               />
  //             </div>
  //             <div style={{ padding: 10, fontWeight: "bold", color: "gray" }}>
  //               {record.fullname}
  //               <div>
  //                 {record.department} | {record.position}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Monday",
  //     title: "ថ្ងៃច័ន្ទ | Mon",
  //     dataIndex: "Monday",
  //     render: (value, record) => {
  //       const times = value ? value.split(", ") : [];

  //       // Calculate the start of the current week (Monday)
  //       const today = dayjs();
  //       const startOfWeek = today.startOf("week").add(1, "day"); // Monday
  //       const endOfWeek = startOfWeek.add(6, "days"); // Sunday

  //       // Filter listFinal for the current week and Monday
  //       const matchingItem = listFinal.filter((item) => {
  //         const scanDate = dayjs(item.scan_date); // Parse scan_date
  //         return (
  //           scanDate.isSame(startOfWeek, "date") && // Ensure it's Monday
  //           item.user_id === record.idnumber // Match idnumber
  //         );
  //       })[0]; // Get the first match if available

  //       const percent = matchingItem
  //         ? `${Math.round(matchingItem.total_percent)}%`
  //         : "N/A";
  //       const work_minute = matchingItem
  //         ? matchingItem.fullday_total_minute_work
  //         : "N/A";
  //       const work_status = matchingItem ? matchingItem.arrival_status : "N/A";

  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div style={{ display: "flex", flexDirection: "column" }}>
  //             {[
  //               { value: `${percent}`, bgColor: getRandomColor() },
  //               { value: `${work_minute}m`, bgColor: getRandomColor() },
  //               { value: `${work_status}`, bgColor: getRandomColor() },
  //             ].map((item, index) => (
  //               <div
  //                 key={index}
  //                 style={{
  //                   ...commonStyle,
  //                   backgroundColor: item.bgColor,
  //                 }}
  //               >
  //                 <p style={textStyle}>{item.value}</p>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     key: "Tuesday",
  //     title: "ថ្ងៃអង្គារ៍ | Tue",
  //     dataIndex: "Tuesday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Wednesday",
  //     title: "ថ្ងៃពុធ | Wed",
  //     dataIndex: "Wednesday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Thursday",
  //     title: "ថ្ងៃព្រហ | Thu",
  //     dataIndex: "Thursday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Friday",
  //     title: "ថ្ងៃសុក្រ | Fri",
  //     dataIndex: "Friday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Saturday",
  //     title: "ថ្ងៃសៅរ៍ | Sat",
  //     dataIndex: "Saturday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: "Sunday",
  //     title: "ថ្ងៃអាទិត្យ | Sun",
  //     dataIndex: "Sunday",
  //     render: (value) => {
  //       const times = value ? value.split(", ") : [];
  //       // Ensure the value is an array of times and map over it to render tags
  //       return (
  //         <div style={{ display: "flex", flexDirection: "row", width: 130 }}>
  //           <div>
  //             {times &&
  //               Array.isArray(times) &&
  //               times.map((time, index) => (
  //                 <Tag
  //                   key={index}
  //                   color={getRandomColor()}
  //                   style={{
  //                     marginBottom: "5px",
  //                     borderRadius: 20,
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   {time}
  //                 </Tag>
  //               ))}
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center", // Aligns children (the <p> tag) horizontally
  //               alignItems: "center", // Aligns children vertically
  //               backgroundColor: getRandomColor(),
  //               height: 25,
  //               borderRadius: 20,
  //               padding: "0 5px", // Optional: Add some padding if needed
  //             }}
  //           >
  //             <p style={{ margin: 2, fontWeight: "bold", color: "white" }}>
  //               100%
  //             </p>
  //             {/* Remove margin to ensure perfect centering */}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },

  //   // {
  //   //   key: "action",
  //   //   title: "សកម្មភាព",
  //   //   dataIndex: "action",
  //   //   render: (value, item, index) => {
  //   //     return (
  //   //       <div>
  //   //         <Space key={index}>
  //   //           <Button
  //   //             type="primary"
  //   //             //  disabled={!Editvalue}
  //   //             //  onClick={() => onOpenModelEdit(item)}
  //   //           >
  //   //             Edit
  //   //           </Button>
  //   //           <Button
  //   //             danger
  //   //             type="primary"
  //   //             //  disabled={!Deletevalue}
  //   //             //  onClick={() => onOpenModelDelete(item)}
  //   //             icon={<CloseOutlined />}
  //   //           />
  //   //         </Space>
  //   //       </div>
  //   //     );
  //   //   },
  //   // },
  // ];

  const onChangePage = async (page) => {
    setLoading(true);
    setCurrentPage(page);
    var objTmp = {
      ...objFilter,
      page: page,
    };
    await setObjFilter(objTmp);
    getList(objTmp);
  };

  const handleFilterClick = () => {
    setOpenModel(true);
  };
  const handleCancel = () => {
    setOpenModel(false);
  };
  const handleApply = async () => {
    console.log(
      "apply handle ",
      UserDateStart?.format("DD/MM/YYYY") +
        " - " +
        UserDateEnd?.format("DD/MM/YYYY")
    );

    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      var objTmp = {
        ...objFilter,
        d_start: undefined ? null : UserDateStart?.format("YYYY-MM-DD"),
        d_end: undefined ? null : UserDateEnd?.format("YYYY-MM-DD"),
      };
      console.log("new param : ", objTmp);

      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoading(false);
      setOpenModel(false);
    }
  };

  const OnChangeDate_Start = (date) => {
    setUserDateStart(date); // No need for dayjs parsing, as Antd DatePicker returns dayjs objects
    console.log("OnChangeDate1 ", date?.format("DD/MM/YYYY"));
  };

  const OnChangeDate_End = (date) => {
    setUserDateEnd(date);
    console.log("OnChangeDate2 ", date?.format("DD/MM/YYYY"));
  };

  const onCLickTimeChange = (time, timeString) => {
    console.log("Selected time:", dayjs(time).format("HH:mm")); // This is the Dayjs object representing the selected time
    console.log("Formatted time:", timeString); // This is the formatted time as a string, e.g., "12:00"
    setTimeValue(dayjs(time).format("HH:mm"));
    // You can use `time` or `timeString` as needed
  };
  const handleSaveandUpdate = async () => {
    try {
      setLoading(true);

      const data = {
        staff_code: username,
        staff_name: null, // Assumes no staff name is required, otherwise update this.
        scan_date: dayjs(userdate_attandance).format("YYYY-MM-DD"), // Replace with dynamic date if necessary.
        scan_time: tiem_Value, // Ensure `time_Value` has the correct format, e.g., "HH:mm".
      };
      console.table(data);

      // Make POST request
      const res = await request("attendanceqrcode", "post", data);

      // Handle response
      if (res.success == true) {
        openNotification("success", "Success", "Save attendance successfully!");
      } else {
        openNotification(
          "error",
          "Error",
          res.message || "Failed to save attendance."
        );
      }

      // Refresh the list (assuming `getList` fetches updated data)
    } catch (error) {
      console.error("Error saving attendance:", error);
      openNotification("error", "Error", "An unexpected error occurred.");
    } finally {
      setVisableModelAttendance(false);
      setLoading(false);
      getList(objFilter);
    }
  };

  const onChangeDateAttendance = (date) => {
    setUserdate_attandance(date);
    console.log(" data attancedan: " + userdate_attandance);
  };

  const onExporttoExcel_AttendanceTimeReport = async () => {
    const param = new URLSearchParams({
      org_code: getProfile.org_code,
      date_start: dayjs(selectedDateStart).format("YYYY-MM-DD"),
      date_end: dayjs(selectedDateEnd).format("YYYY-MM-DD"),
      idnumber: GetIdnumber === undefined ? "" : GetIdnumber,
    });

    try {
      const res = await request(
        `attendancetimereport?` + param.toString(),
        "get",
        {}
      );
      console.log("Full API Response:", res);

      if (res && res.success === true) {
        // Wrap the response in an array if it is not already an array
        const attendanceData = res.api_attendance_time_report[0];
        setListExcelData(res.api_attendance_time_report[0]);
        return attendanceData;
      } else {
        console.error("Invalid response structure or empty data:", res);
        return [];
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
      return [];
    }
  };
  const convertImageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, {
        mode: "cors", // Ensure CORS is allowed by the server
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch image. Status: ${response.status}`);
      }

      const blob = await response.blob();
      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result.split(",")[1];
          resolve("data:image/png;base64," + result); // Ensure this is correct for your image type
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      return base64String;
    } catch (error) {
      console.error("Error fetching or converting image:", error);
      throw new Error("Image conversion failed");
    }
  };

  const downloadExcel_monthylyreport = async () => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Fetch data
      const data = (await onExporttoExcel_AttendanceTimeReport()) || [];
      console.log("Processed data for Excel:", data);

      if (data.length === 0) {
        console.warn("No data available for Excel export.");
        return;
      }

      // Load the template Excel file
      const templatePath =
        process.env.PUBLIC_URL + "/template/attendance_time_report.xlsx";
      const response = await fetch(templatePath);

      if (!response.ok) {
        throw new Error(`Failed to load template. Status: ${response.status}`);
      }

      const templateArrayBuffer = await response.arrayBuffer();
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);

      const dataSheet = workbook.getWorksheet("report");

      // Convert data to worksheet format
      const newData = data.map((item) => Object.values(item));

      // Example: Add organization name and data
      dataSheet.getCell("A3").value =
        getProfile.org_name_kh || "Organization Name";
      dataSheet.getCell("O3").value = getProfile.org_type;
      dataSheet.getCell(
        "A5"
      ).value = `តារាងស្កែនវត្តមានបុគ្គលិក ពីថ្ងៃទី ${dayjs(
        selectedDateStart
      ).format("DD/MM/YYYY")} ដល់ថ្ងៃទី ${dayjs(selectedDateEnd).format(
        "DD/MM/YYYY"
      )}`;
      // Convert and insert the image
      const imageUrl =
        "https://static.vecteezy.com/system/resources/previews/012/986/755/non_2x/abstract-circle-logo-icon-free-png.png";
      const base64Image = await convertImageToBase64(imageUrl);
      console.log("base64Image: ", base64Image);
      // const base64Image = await convertImageToBase64(Org_ImagePath);
      // const extension = Org_ImagePath.split(".").pop().toLowerCase();
      // const mimeType =
      //   extension === "png"
      //     ? "image/png"
      //     : extension === "jpg"
      //     ? "image/jpeg"
      //     : "image/png";

      // console.log("Base64 Image:", base64Image); // Debugging log
      // console.log("Image MIME Type:", mimeType); // Debugging log

      // const imageId = workbook.addImage({
      //   base64: base64Image,
      //   extension: extension,
      //   mimeType: mimeType,
      // });

      // // Position image at cell S3 (column 18, row 3)
      // dataSheet.addImage(imageId, {
      //   tl: { col: 18, row: 3 },
      //   ext: { width: 100, height: 100 },
      // });

      // Add data table
      dataSheet.addTable({
        name: "dataTable1",
        ref: "B9",
        columns: Object.keys(data[0]).map((key) => ({ name: key })),
        rows: newData,
      });

      // Hide unused rows
      const hideRows = (sheet, length) => {
        const startRowToDelete = length + 11;
        const endRowToHide = 1000;
        for (let row = startRowToDelete; row <= endRowToHide; row++) {
          sheet.getRow(row).height = 0.1;
        }
      };
      hideRows(dataSheet, newData.length);

      // Save workbook and trigger download
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `Attendance-time-Report-${selectedDateStart.format(
        "MM-DD-YYYY"
      )} to ${selectedDateEnd.format("MM-DD-YYYY")} ${moment().format(
        "HH:mm:ss"
      )}`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
    } catch (error) {
      console.error("Error during Excel generation:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    setDownloadReport(true);
  };

  const handleDateChange_Start = (date) => {
    setSelectedDate_start(date);
  };
  const handleDateChange_End = (date) => {
    setSelectedDate_end(date);
  };
  return (
    <div>
      {/* <div style={{ width: "300px", height: "300px", padding: "10px" }}>
        {showReader && <div id="reader"></div>}
        {showReaderInvalidQRCode && <div id="reader"></div>}
        {isLoading && (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
            />
          </div>
        )}
        {latitude !== null && longitude !== null && (
          <Modal
            open={model}
            title={
              distanceValue <= metor
                ? "Attendance Save Successfully"
                : "QR code location is too far from current location."
            }
            footer={
              <Button
                color="primary"
                onClick={() => {
                  setModel(false);
                  setShowReader(true);
                }}
              >
                Close
              </Button>
            }
            onCancel={() => {
              setModel(false);
              setShowReader(true);
            }}
          >
            <p>
              {latitude === 0 && longitude === 0
                ? "Invalid Google Maps link. Please provide a valid link."
                : null}
            </p>
            <p>
              <Result
                status={distanceValue <= metor ? "success" : "warning"}
                title={
                  distanceValue <= metor
                    ? "Attendance Save Successfully"
                    : "QRcode location is too far from current location."
                }
              />
            </p>
          </Modal>
        )}

        <Modal
          open={model_invalid_link}
          title="Invalid QR Code"
          onCancel={handleCLoseInvalidLinkModal}
          footer={null}
        >
          <Result title="Invalid QRCode . Please try to scan with correct QRCode " />
        </Modal>
      </div> */}

      <div style={boxHeader}>
        <div className="Header-Setting-Grid">
          <div className="Header-Setting-Grid-R">
            {boxHeaderTagText("ការគ្រប់គ្រងស្កែនវត្តមានបុគ្គលិក ")}

            <Space>
              <Input
                style={{
                  display: txtSearchVisable ? "none" : "block",
                  width: "200px",
                }}
                className="styleTageStatusForm"
                placeholder="Search..."
                onChange={handleSearchInputChange}
                allowClear
              />
              <div
                style={{
                  backgroundColor: getRandomColor(),
                  padding: "8px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  border: "1px solid gray",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleFilterClick} // Add a click handler for filter action
              >
                <FilterOutlined style={{ color: "white", fontSize: "16px" }} />
              </div>
            </Space>
          </div>

          <div>
            <Space>
              <Button
                className="buttonstylekhmer"
                onClick={handleExportExcel}
                type="primary"
                icon={<BsDownload />}
              >
                ទាយយករបាយការណ៍
              </Button>
              <Button
                style={{
                  display: btn_add ? "none" : "block",
                }}
                className="custom-form-item"
                disabled={!addvalue}
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setVisableModelAttendance(true)}
              >
                បន្ថែមថ្មី
              </Button>
            </Space>
          </div>
        </div>
      </div>

      <div style={{ padding: "10px", width: "100%" }}>
        <div>
          {loading ? (
            <div className="sprinCustome">
              <Circles
                height="80"
                width="80"
                color="#2e86c1"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={listAttendanceByWeekend}
              pagination={{
                showSizeChanger: true,
                pageSize: pagesizeIndex,
                total: totalPage, // total record data from database server
                current: currentPage, // current page number
                onChange: onChangePage,
              }}
            />
          )}
        </div>
      </div>

      <Modal
        width={400}
        title="Filter By Date"
        open={openModel}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleApply}>
            Apply
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: 10 }}>
            <p>Start Date : </p>
            <DatePicker
              format="DD/MM/YYYY" // Set the display format
              value={UserDateStart} // Directly use state as DatePicker handles dayjs objects
              onChange={OnChangeDate_Start}
              className="styleDatePicker"
              style={{ width: "150px" }}
              allowClear
            />
          </div>
          <div style={{ margin: 10 }}>
            <p>End Date : </p>
            <DatePicker
              format="DD/MM/YYYY" // Set the display format
              value={UserDateEnd}
              onChange={OnChangeDate_End}
              className="styleDatePicker"
              style={{ width: "150px" }}
              allowClear
            />
          </div>
        </div>
      </Modal>

      <Modal
        width={400}
        open={visableModelAttendance}
        title="New"
        onCancel={() => setVisableModelAttendance(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisableModelAttendance(false)}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleSaveandUpdate}>
            Save Attendance
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: 10 }}>
            <p>Staff Name: </p>
            <Select
              style={{ width: "100%", height: 40 }}
              showSearch
              value={staffname}
              className="styleTageStatusForm"
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChangeStaff}
            >
              {list_staff.map((item) => (
                <Option key={item.id} value={item.username}>
                  {item.fullname}
                </Option>
              ))}
            </Select>
            <p style={{ marginTop: 10 }}>Date && Time : </p>
            <Space>
              <DatePicker
                format="DD/MM/YYYY" // Set the display format
                value={userdate_attandance} // Directly use state as DatePicker handles dayjs objects
                onChange={onChangeDateAttendance}
                className="styleDatePicker"
                style={{ width: "150px" }}
                allowClear
              />
              <TimePicker
                className="styleTageStatusForm"
                onOk={onCLickTimeChange}
                defaultValue={dayjs("12:00", format)}
                format={format}
              />
            </Space>
          </div>
        </div>
      </Modal>
      <Modal
        title="ទាញរបាយការណ៍"
        className="custom-modal" // Use the custom-modal class for responsive styles
        open={DownloadReport}
        //   onCancel={onCancelDownload}
        footer={[
          <div>
            <Space>
              <Button
                className="custom-form-item"
                onClick={() => setDownloadReport(false)}
              >
                បោះបង់
              </Button>
              <Button
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel_monthylyreport}
              >
                ទាញយក
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div style={{ width: "520px" }}>
          <Space>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Space style={{ width: "100%" }}>
                ចាប់ពីថ្ងៃទីខែ :
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={handleDateChange_Start}
                />
              </Space>
            </div>
            <div>
              <Space>
                ដល់ថ្ងៃទីខែ :
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={handleDateChange_End}
                />
              </Space>
            </div>
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default AttendanceQRCode;
