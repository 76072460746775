import React, { useState } from "react";
import LeavePage from "../leavereport/LeavePage";
import { getCurrentUser } from "../../share/getProfile";
import { Tabs } from "antd";
import { PieChartOutlined, SolutionOutlined } from "@ant-design/icons"; // Import icons
import AttendanceQRCode from "../attendance/AttendanceQRcodePage";

const { TabPane } = Tabs; // Correct destructuring of TabPane from Tabs

export default function UserLeave() {
  const getUser = getCurrentUser();

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab="ការគ្រប់គ្រងច្បាប់​ របស់បុគ្គលិក"
          key="2"
          icon={<PieChartOutlined />}
        >
          <LeavePage
            getUser={getUser.fullname}
            txtSearchVisable={true}
            getIDnumber={getUser.idnumber}
            monthlyReport={1}
          />
        </TabPane>
        <TabPane tab="វត្តមានរបស់បុគ្គលិក" key="3" icon={<SolutionOutlined />}>
          <AttendanceQRCode
            GetUserName={getUser.username}
            GetIdnumber={getUser.idnumber}
            txtSearchVisable={true}
            btn_add={true}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
