import {
  Table,
  Pagination,
  Space,
  Button,
  Modal,
  Input,
  DatePicker,
  TreeSelect,
  Checkbox,
  Tag,
} from "antd";

import { useState, useEffect, useMemo } from "react";
import { request } from "../../share/request";
import "../../styles/Home-module.css";
import { BsDownload } from "react-icons/bs";
import style from "../../styles/style.module.css";
import { Circles } from "react-loader-spinner";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { formateDateClient, formateDateSystem } from "../../share/helper";
import { getJobStatus } from "../../share/getStatusContent";
//  for export data to excel
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import moment from "moment";
import { openNotification } from "../../share/message";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { getCurrentUser } from "../../share/getProfile";
import "./Model-Setting-Grid.css";

// -----------------  end import --------------------

const EmployeePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const getProfile = getCurrentUser();
  const [ListExcelData, setListExcelData] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [DownloadReport, setDownloadReport] = useState(false);
  const [dataSours, setDataSoure] = useState([]);
  const [totalpage, setTotalPage] = useState(1);
  const [selectOrgCodeSave, setSelectOrgCodeSave] = useState(
    getProfile.org_code
  );

  const [size, setSize] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [objFilter, setObjFilter] = useState({
    page: 1,
    org_code: getProfile.org_code,
  });
  useEffect(() => {
    getMasterGroups();
    // block form load
    getList(objFilter);
  }, []);

  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      org_code: getProfile.org_code,
    });

    // create function success

    try {
      const res = await request(
        "employeereport?" + param.toString(),
        "get",
        {}
      );
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setDataSoure(res.ListEmployeeData[0]);
        setTotalPage(res.totalRecord);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record, index) => {
        // Assuming `page` and `pageSize` are available in your component scope
        return <div>{(pageNumber - 1) * pagesize + index + 1}</div>; // Proper formula for page-based indexing
      },
    },

    {
      title: "លេខមន្ត្រីរាជការ",
      dataIndex: "idnumber",
    },
    {
      title: "ឈ្មោះមន្ត្រីរាជការ",
      dataIndex: "fullname",
      render: (text, record) => (
        <div>
          <div style={{ fontSize: "14px" }}>{record.fullname}</div>
          <div style={{ fontSize: "12px", marginTop: 0, color: "#BFBFBF" }}>
            {record.staffname_eng}
          </div>
        </div>
      ),
    },

    {
      title: "ភេទ",
      dataIndex: "gender",
    },

    {
      title: "ផ្នែក និងតួរនាទី",
      dataIndex: "department",
      render: (text, record) => (
        <div>
          <div style={{ fontSize: "14px" }}>{record.department}</div>
          <div style={{ fontSize: "12px", marginTop: 0, color: "#BFBFBF" }}>
            {record.position}
          </div>
        </div>
      ),
    },
    {
      title: "ជំនាញ",
      dataIndex: "skill",
    },
    {
      title: "ថ្ងៃចូលបម្រើការងារ",
      dataIndex: "joinwork",
      render: (text, record) => <div>{formateDateSystem(record.joinwork)}</div>,
    },
    {
      title: "កាំប្រាក់",
      dataIndex: "level",
    },
    {
      title: "ថ្ងៃឡើងកាំប្រាក់",
      dataIndex: "date_last_upgrad",
      render: (text, record) => (
        <div>{formateDateSystem(record.date_last_upgrad)}</div>
      ),
    },
    {
      title: "ប្រភេទបុគ្គលិក",
      dataIndex: "stafftype",
      render: (text, record) => <div>{getJobStatus(record.stafftype)}</div>,
    },
    {
      title: "ស្ថានភាព",
      dataIndex: "jobstatus",
      render: (text, record) => <div>{getJobStatus(record.jobstatus)}</div>,
    },

    {
      title: "សកម្មភាព",
      render: (_, record, index) => (
        <Space>
          <Button type="primary" icon={<MdOutlineEdit />} />
          <Button type="primary" icon={<MdOutlineDelete />} danger />
        </Space>
      ),
    },
  ];
  // // for one file excel download
  // const onExporttoExcel = async () => {
  //   const idorg = getProfile.org_code;
  //   console.log("ID org : ", idorg);
  //   const param = new URLSearchParams({
  //     idorg: idorg,
  //   });
  //   const res = await request("employeeexcel?" + param.toString(), "get", {});
  //   if (res) {
  //     // Ensure res.dataRequestLeave is an array
  //     const dataArray = Array.isArray(res.ListEmployeeDownloadExcell_gov[0])
  //       ? res.ListEmployeeDownloadExcell_gov[0]
  //       : [];
  //     setListExcelData(dataArray);
  //     console.log("res", dataArray);

  //     // Return the array for further use if needed
  //     return dataArray;
  //   }

  //   // Return an empty array or handle the case when 'res' is falsy
  //   return [];
  // };
  const onExporttoExcel = async () => {
    const orgCodes = param_org;
    // Ensure orgCodes is a comma-separated string, even for a single org
    const idorg = Array.isArray(orgCodes) ? orgCodes.join(",") : orgCodes;

    console.log("ID org : ", idorg);

    // Prepare URL parameters
    const param = new URLSearchParams({
      idorg: idorg, // Pass single or multiple IDs
    });

    try {
      // Make the GET request to the API endpoint
      const res = await request(`employeeexcel?${param.toString()}`, "get", {});
      if (res) {
        // Extract the correct data array
        const dataArray = Array.isArray(res.ListEmployeeDownloadExcell_gov)
          ? res.ListEmployeeDownloadExcell_gov[0] || []
          : [];

        // Update the state
        setListExcelData(dataArray);
        console.log("res", dataArray);

        // Return the array for further use
        return dataArray;
      }
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    }

    // Return an empty array if something goes wrong
    return [];
  };

  const onExporttoExcel_other = async () => {
    const orgCodes = param_org;
    // Ensure orgCodes is a comma-separated string, even for a single org
    const idorg = Array.isArray(orgCodes) ? orgCodes.join(",") : orgCodes;

    console.log("ID org : ", idorg);

    // Prepare URL parameters
    const param = new URLSearchParams({
      idorg: idorg, // Pass single or multiple IDs
    });
    const res = await request(`employeeexcel?${param.toString()}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.ListEmployeeDownloadExcell_other[0])
        ? res.ListEmployeeDownloadExcell_other[0]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  const onExporttoExcel_TotalAll = async () => {
    const orgCodes = param_org;
    // Ensure orgCodes is a comma-separated string, even for a single org
    const idorg = Array.isArray(orgCodes) ? orgCodes.join(",") : orgCodes;

    console.log("ID org : ", idorg);

    // Prepare URL parameters
    const param = new URLSearchParams({
      idorg: idorg, // Pass single or multiple IDs
    });
    const res = await request(`employeeexcel?${param.toString()}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(
        res.ListEmployeeDownloadExcell_TotalAll[0]
      )
        ? res.ListEmployeeDownloadExcell_TotalAll[0]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };

  const downloadExcel = async () => {
    const OrgName = getProfile.org_name_kh;

    try {
      setLoadingSpin(true);
      setDownloadReport(false);

      // Fetch data and ensure it's an array
      const data = (await onExporttoExcel()) || [];
      const data_other = (await onExporttoExcel_other()) || [];
      const data_TotalAll = (await onExporttoExcel_TotalAll()) || [];

      // Load the template Excel file from the public folder
      const templatePath =
        process.env.PUBLIC_URL + "/template/templatestaff.xlsx";
      const response = await fetch(templatePath);
      const templateArrayBuffer = await response.arrayBuffer();

      // Create a workbook from the template file
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);

      // Get the sheets from the workbook
      const dataSheet = workbook.getWorksheet("staffgov");
      const otherSheet = workbook.getWorksheet("staffothers");
      const TotalAllSheet = workbook.getWorksheet("total-all");

      if (!dataSheet || !otherSheet || !TotalAllSheet) {
        console.error("One or more sheets not found in the template.");
        return;
      }

      dataSheet.getCell("A2").value = OrgName;

      // Convert the new data to worksheet format
      const newData = data.map((item) => Object.values(item));
      const newData_other = data_other.map((item) => Object.values(item));
      const newData_TotalAll = data_TotalAll.map((item) => Object.values(item));

      // Check if the data arrays are valid
      if (newData.length === 0) {
        console.warn("No data available for the main data sheet.");
      }
      if (newData_other.length === 0) {
        console.warn("No data available for the other data sheet.");
      }
      if (newData_TotalAll.length === 0) {
        console.warn("No data available for the total all data sheet.");
      }

      // Append the new data to the existing sheets
      if (newData.length > 0) {
        dataSheet.addTable({
          name: "dataTable",
          ref: "B6",
          headerRow: true,
          columns: Object.keys(data[0]).map((key) => ({ name: key })),
          rows: newData,
        });
      }

      if (newData_other.length > 0) {
        otherSheet.addTable({
          name: "dataTable1",
          ref: "B6",
          headerRow: true,
          columns: Object.keys(data_other[0]).map((key) => ({ name: key })),
          rows: newData_other,
        });
      }

      if (newData_TotalAll.length > 0) {
        TotalAllSheet.addTable({
          name: "dataTable2",
          ref: "A6",
          headerRow: true,
          columns: Object.keys(data_TotalAll[0]).map((key) => ({ name: key })),
          rows: newData_TotalAll,
        });
      }

      // Hide unnecessary rows in each sheet
      const hideRows = (sheet, length) => {
        const startRowToDelete = length + 7;
        const endRowToHide = 1000;

        for (let row = startRowToDelete; row <= endRowToHide; row++) {
          sheet.getRow(row).height = 0.1; // Hide the row
        }
      };

      hideRows(dataSheet, newData.length);
      hideRows(otherSheet, newData_other.length);
      hideRows(TotalAllSheet, newData_TotalAll.length);

      // Apply Background Color Logic

      const applyBackgroundColor = (sheet, data) => {
        for (let row = 7; row <= data.length + 6; row++) {
          const colAValue = sheet.getCell(row, 1).value; // Column A
          const colBValue = sheet.getCell(row, 2).value; // Column B
          const colCValue = sheet.getCell(row, 3).value; // Column C
          const colDValue = sheet.getCell(row, 4).value; // Column D
          // Ensure Column B is empty (null or empty string after trimming) and Column C is not null
          if (
            colAValue == null &&
            colBValue == null &&
            colCValue != null &&
            colDValue == null
          ) {
            for (let col = 1; col <= 19; col++) {
              // Loop through all columns in the row
              sheet.getCell(row, col).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "AED6F1" }, // Light blue background
              };
            }
          }
        }
      };

      applyBackgroundColor(dataSheet, newData);
      applyBackgroundColor(otherSheet, newData_other);

      // Handle subtotal formulas
      const applySubtotal = (sheet, data) => {
        for (let row = 7; row <= data.length + 6; row++) {
          const cellValue = sheet.getCell(row, 2).value;
          if (cellValue === 1) {
            const subtotalFormula = {
              formula: `SUBTOTAL(109, B$7:B${row})`,
            };
            sheet.getCell(row, 1).value = subtotalFormula;
          }
        }
      };

      applySubtotal(dataSheet, newData);
      applySubtotal(otherSheet, newData_other);

      // Background color for TotalAll sheet
      for (let row = 7; row <= newData_TotalAll.length + 6; row++) {
        const cellValue = TotalAllSheet.getCell(row, 2).value;
        if (cellValue != null) {
          for (let col = 1; col <= 5; col++) {
            const cell = TotalAllSheet.getCell(row, col);
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFCCCC" },
            };
            cell.font = {
              bold: true,
              name: "Content",
            };
          }
        }
      }

      // Save the modified workbook as a Blob with XLSX format
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `StaffSummaryReport-${moment().format(
        "MM-DD-YYYY-HH:mm:ss"
      )}`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
    } catch (error) {
      console.error("An error occurred while generating the report:", error); // Log detailed error
      openNotification("error", "Error", "Error while downloading report");
    } finally {
      setLoadingSpin(false);
    }
  };

  const onCancelDownload = () => {
    setDownloadReport(false);
  };
  const opOpenModelDownloadReport = () => {
    setDownloadReport(true);
  };

  const onShowSizeChange = async (current) => {
    console.log(current);
    setPageNumber(current);
    setCurrentPage(current);
    try {
      setLoadingSpin(false);
      var objTmp = {
        ...objFilter,
        page: current,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const [ListMasterGroup, setListMasterGroup] = useState([]);
  const getMasterGroups = async () => {
    const param = new URLSearchParams({ org_code: getProfile.org_code });

    const res = await request("usermastergroup?" + param.toString(), "get", {});
    if (res) {
      setListMasterGroup(res.list_api_master_group[0]);
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(null); // Store the selected index
  const [param_org, setParamOrg] = useState(getProfile.org_code);
  // const treeData = ListMasterGroup.reduce((acc, item) => {
  //   const groupId = item.group_id || "Unknown"; // Use "Unknown" as a fallback
  //   const nameUnknow = item.org_name_kh;
  //   const groupName = item.group_name || nameUnknow; // Use "Unknown" as a fallback
  //   const group = acc.find((g) => g.value === groupId);
  //   if (group) {
  //     group.children.push({
  //       title: item.org_name_kh,
  //       value: item.org_code,
  //       key: item.organization_id,
  //     });
  //   } else {
  //     acc.push({
  //       title: `${groupName}`, // Use fallback or default group name
  //       value: groupId,
  //       key: `${groupId}`,
  //       children: [
  //         {
  //           title: item.org_name_kh,
  //           value: item.org_code,
  //           key: item.organization_id,
  //         },
  //       ],
  //     });
  //   }
  //   return acc;
  // }, []);
  // const [selectedIndex, setSelectedIndex] = useState(null); // Store the selected index
  // const findOrgByValue = (value, data) => {
  //   let foundOrg = null;
  //   data.some((node) => {
  //     if (node.value === value) {
  //       foundOrg = node;
  //       return true;
  //     }
  //     // Recursively search in the children
  //     if (node.children && node.children.length > 0) {
  //       foundOrg = findOrgByValue(value, node.children);
  //       return foundOrg ? true : false;
  //     }
  //     return false;
  //   });
  //   return foundOrg;
  // };
  // const findOrgIndex = (value, data, parentIndex = null) => {
  //   let index = -1;
  //   data.some((node, idx) => {
  //     // Check if the current node matches the selected value
  //     if (node.value === value) {
  //       index = parentIndex !== null ? `${parentIndex}-${idx}` : idx;
  //       return true;
  //     }

  //     // If there are children, recursively search in the children
  //     if (node.children && node.children.length > 0) {
  //       index = findOrgIndex(value, node.children, idx); // Pass current index as parentIndex
  //       return index !== -1;
  //     }
  //     return false;
  //   });
  //   return index;
  // };
  // const onChangeOrganization_Multiple = async (value) => {
  //   const selectedOrg = findOrgByValue(value, treeData);
  //   const orgIndex = findOrgIndex(value, treeData);
  //   if (selectedOrg) {
  //     const groupId = selectedOrg.groupId; // Get the groupId (parent)
  //     const orgCode = selectedOrg.value; // The orgCode of the selected organization

  //     setSelectOrgCodeSave(orgCode);

  //     setSelectedIndex(orgIndex); // Store the index of the selected org

  //     // Log the selected values
  //     console.table({ groupId, orgCode, index: orgIndex });
  //     console.log("this is index number: ", selectedIndex);
  //     console.log(" selected type : ", selectedOrg);
  //     const newfilter = {
  //       ...objFilter,
  //       org_code: orgCode,
  //     };
  //     console.table("new fileter : ", newfilter);
  //   }
  // };
  const treeData = useMemo(() => {
    return ListMasterGroup.reduce((acc, item) => {
      const groupId = item.group_id || "Unknown";
      const groupName = item.group_name || item.org_name_kh || "Unknown";
      const group = acc.find((g) => g.value === groupId);
      if (group) {
        group.children.push({
          title: item.org_name_kh,
          value: item.org_code,
          key: item.organization_id,
        });
      } else {
        acc.push({
          title: groupName,
          value: groupId,
          key: `${groupId}`,
          children: [
            {
              title: item.org_name_kh,
              value: item.org_code,
              key: item.organization_id,
            },
          ],
        });
      }
      return acc;
    }, []);
  }, [ListMasterGroup]);
  const findOrg = (value, data, parentIndex = null) => {
    for (let idx = 0; idx < data.length; idx++) {
      const node = data[idx];

      if (node.value === value) {
        return {
          org: node,
          index: parentIndex !== null ? `${parentIndex}-${idx}` : idx,
        };
      }

      if (node.children && node.children.length > 0) {
        const result = findOrg(value, node.children, idx);
        if (result) return result;
      }
    }
    return null;
  };
  const handleTreeSelectChange = (value) => {
    if (!value || value.length === 0) {
      // Clear selection
      setSelectOrgCodeSave([]);
    } else {
      // Update selected values
      setSelectOrgCodeSave(value);
    }

    console.log("Updated Selected Values: ", value);
    setParamOrg(value);
  };

  return (
    <div>
      <div>
        <div className="sprinSave-container">
          {/* Your other components */}
          {loadingSpin && (
            <div className="sprinSave">
              <Circles
                height="80"
                width="80"
                color="#2e86c1"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>

        {/* <div className={style.sarchPenel} style={boxHeader}>
          <div className={style.sarchPenel}>
            <Space>
              {boxHeaderTagText("មន្រ្តីរាជការ បុគ្គលិកជួល...")}

              <div style={{ display: "flex" }}>
                <Space>
                  <Input placeholder="Search..." allowClear />
                </Space>
              </div>
            </Space>
          </div>
          <div>
            <Space>
              <Button
                className="buttonstylekhmer"
                onClick={opOpenModelDownloadReport}
                type="primary"
                icon={<BsDownload />}
              >
                ទាយយករបាយការណ៍
              </Button>
            </Space>
          </div>
        </div> */}

        <div style={boxHeader}>
          <div className="Header-Setting-Grid">
            <div className="Header-Setting-Grid-R">
              {boxHeaderTagText("មន្រ្តីរាជការ បុគ្គលិកជួល...")}

              <Space>
                <Input
                  className="styleTageStatusForm"
                  placeholder="Search..."
                  allowClear
                />
              </Space>
            </div>

            <div>
              <Space>
                <Button
                  className="buttonstylekhmer"
                  onClick={opOpenModelDownloadReport}
                  type="primary"
                  icon={<BsDownload />}
                >
                  ទាយយករបាយការណ៍
                </Button>
              </Space>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            size="small"
            tableLayout="auto"
            columns={columns}
            dataSource={dataSours}
            pagination={false}
          />
        )}
        <div style={{ marginTop: 10 }}>
          <Pagination
            showSizeChanger
            onChange={onShowSizeChange}
            defaultCurrent={currentPage}
            total={totalpage}
          />
        </div>
      </div>
      <Modal
        title="ទាញរបាយការណ៍"
        className="custom-modal" // Use the custom-modal class for responsive styles
        open={DownloadReport}
        footer={[
          <div>
            <Space>
              {" "}
              <Button onClick={onCancelDownload}>Cancel</Button>
              <Button
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel}
              >
                ទាញយកសង្ខេបរបាយការណ៍បុគ្គលិក (Excel)
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div style={{ width: "520px" }}>
          <Space>
            {/* <Tag style={{ padding: 7 }}>
              <Checkbox>អង្គភាពទាំងអស់</Checkbox>
            </Tag> */}
            <TreeSelect
              allowClear
              showSearch
              treeCheckable={true} // Enables checkboxes for multiple selection
              multiple={true} // Enables multiple selection
              style={{ width: "500px" }}
              value={selectOrgCodeSave} // Controlled value
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={treeData} // Dynamic treeData
              placeholder="Select Organization(s)"
              treeDefaultExpandAll
              filterTreeNode={(input, treeNode) =>
                treeNode.title.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => handleTreeSelectChange(value)} // Change handler
            />
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeePage;
