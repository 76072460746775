import React, { useRef } from "react";
import "../../styles/Home-module.css";
import { formattedDate, linkPath, linkPathReport } from "../../share/helper";
import { formateDateClient } from "../../share/helper";
import { BiCheckbox, BiCheckboxChecked, BiLogoTelegram } from "react-icons/bi";
import { getCurrentUser } from "../../share/getProfile";
import { GrLocation } from "react-icons/gr";
import { QRCode } from "antd";
import { FooterPagePrint } from "../letter/FooterPagePrint";
export default class ComponentToPrint extends React.Component {
  render() {
    const {
      reason,
      transfer_in_name1,
      transfer_in_name2,
      transfer_in_name3,
      transfer_out_name1,
      transfer_out_name2,
      transfer_out_name3,
      date_start,
      date_end,
      location,
      postion_signature,
      org_cover_by,
      do_in_provinceName,
      date_document,
      org_type_code,
      location_contact,
      org_type_string,
      Org_ImagePath,
    } = this.props;
    const OrgProfile = getCurrentUser();
    const moment = require("moment");
    require("@thyrith/momentkh")(moment);
    let api_date_document = moment(date_document, "YYYY-MM-DD");

    let khmerDateDocument = api_date_document.toLunarDate();

    const spaces = " ".repeat(10); // Creates a string with 20 spaces
    const spaces1 = " ".repeat(1);
    const tilte1 = spaces + "ខ្ញុំ " + transfer_in_name1;
    const title2 = spaces1 + "ត្រូវទៅ" + reason + spaces1 + " នៅ" + location;
    const tilte3 = spaces + "ខ្ញុំសូមផ្ទេរសិទ្ធជូន " + transfer_out_name1;
    const title_out =
      transfer_out_name3 +
      " ឲ្យទទួលដឹកនាំចរន្តការងារប្រចាំថ្ងៃជំនួសខ្ញុំ ចាប់ពី" +
      date_start +
      spaces1 +
      "រហូតដល់ខ្ញុំមានវត្តមានការងារវិញ ។";

    const Header = () => {
      return (
        <div className="text-on-image-name_rightmanager_mission">
          <div
            className="text-overlay"
            style={{
              top: 30,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-on-image-name_rightmanager_mission">
              <span>ព្រះរាជាណាចក្រកម្ពុជា</span> <br />
              <span>ជាតិ សាសនា ព្រះមហាក្សត្រ</span>
              <br />
              <img
                src={linkPath + "tacteing_font.jpg"}
                alt="tacteing font"
                style={{ width: "20%" }}
              />
            </div>
          </div>
          <div
            className="text-overlay"
            style={{
              marginLeft: 50,
              top: 80,
              display: "flex",
              width: "100%",
              alignItems: "start",
            }}
          >
            <div>
              <img
                src={Org_ImagePath}
                alt="Organization Logo"
                style={{ width: "70px", height: "70px", marginBottom: "5px" }}
              />
              <span>
                {OrgProfile.org_type <= 3 && (
                  <span className="text-on-image-name_rightmanager_mission">
                    <br /> រដ្ឋបាលខេត្ត{OrgProfile.province_name} <br />
                  </span>
                )}
                {OrgProfile.org_type >= 4 && (
                  <span className="text-on-image-name_rightmanager_mission">
                    <br /> មន្ទីរសុខាភិបាលនៃរដ្ឋបាលខេត្ត <br />
                  </span>
                )}
              </span>

              {OrgProfile.org_type != 1 && (
                <span className="text-on-image-name_rightmanager_mission">
                  {OrgProfile.org_cover_by} <br />
                </span>
              )}
              <span className="text-on-image-name_rightmanager_mission">
                {OrgProfile.org_name_kh}
              </span>
              <br />
              <span className="text-on-image-name_letters_mission">
                លេខ៖...........
              </span>
            </div>
          </div>

          <div
            className="text-overlay"
            style={{
              // backgroundColor: "#a3e4d7",
              right: 50,
              top: 200,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{ textAlign: "end" }}
              className="text-on-image-name_letters_mission"
            >
              <span>{khmerDateDocument}</span>
              <br />
              <span>
                {" "}
                ធ្វើនៅ{do_in_provinceName}{" "}
                {formattedDate(formateDateClient(date_document))}
              </span>
            </div>
          </div>

          <div
            className="text-overlay"
            style={{
              top: 270,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-on-image-name_rightmanager_mission">
              {/* <span>{OrgProfile.org_name_kh}</span> */}
              <br />
              <span>លិខិតផ្ទេរសិទ្ធ</span>
              <br />
              <img
                src={linkPath + "tacteing_font.jpg"}
                alt="tacteing font"
                style={{ width: "15%" }}
              />
              {/* <span>លោកប្រធាន{org_cover_by}</span> */}
              {/* <span>{org_type_string}</span> */}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div>
        <Header />
        <FooterPagePrint location_contact={location_contact} />
        <div
          className="text-overlay"
          style={{ top: 350, left: 120, marginRight: 100 }}
        >
          <table>
            <tr>
              <td style={{ lineHeight: "2" }}>
                <p className="text-on-image-name_letters_mission">
                  &nbsp;&nbsp;&nbsp; {tilte1}
                  <span className="text-on-image-name_rightmanager_mission">
                    {transfer_in_name2}
                  </span>
                  {transfer_in_name3}
                  {title2}
                </p>
                <p className="text-on-image-name_letters_mission">
                  &nbsp;&nbsp;&nbsp;{tilte3}
                  <span className="text-on-image-name_rightmanager_mission">
                    {transfer_out_name2}
                  </span>
                  {title_out}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    marginTop: 50,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 100,
                  }}
                >
                  <span className="text-on-image-name_rightmanager_mission">
                    {postion_signature}
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="text-overlay"></div>
      </div>
    );
  }
}
