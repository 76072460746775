// import React, { useRef } from "react";
// import "../../styles/Home-module.css";
// import { linkPathIDCard } from "../../share/helper";
// import { QRCode, Card } from "antd";
// import { linkPath } from "../../share/helper";

// export default class CardComponentBack extends React.Component {
//   render() {
//     const {
//       staffname,
//       deparment,
//       position,
//       image,
//       backImage,
//       profileqrcode,
//       logoName,
//     } = this.props;
//     return (
//       <div style={{ paddingTop: 80, paddingLeft: 5, width: "50%" }}>
//         <div>
//           <Card>
//             <div style={{ position: "relative", width: 285, height: 407 }}>
//               {/* Background image */}
//               <img
//                 src={linkPathIDCard + backImage}
//                 className="back-paper"
//                 style={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "cover", // Use cover to fit the container
//                 }}
//               />

//               {/* QR Code overlay */}
//               <div
//                 className="qrcode-overlaycard-BackPapar"
//                 style={{
//                   position: "absolute",
//                   top: "10px", // Adjust position as needed
//                   left: "10px", // Adjust position as needed
//                   zIndex: 1,
//                 }}
//               >
//                 <QRCode
//                   errorLevel="H"
//                   size={120}
//                   color="#2471a3"
//                   value={profileqrcode}
//                   icon={linkPathIDCard + logoName}
//                   iconSize={20}
//                 />
//                 <div className="text-overlaycard-back">
//                   <span>{staffname}</span>
//                 </div>
//               </div>
//             </div>
//           </Card>
//         </div>
//       </div>
//     );
//   }
// }

import React from "react";
import "../../styles/Home-module.css";
import { linkPathIDCard } from "../../share/helper";
import { QRCode, Card } from "antd";
import { getCurrentUser } from "../../share/getProfile";
export default class CardComponentBack extends React.Component {
  render() {
    const {
      staffname,
      deparment,
      position,
      backImage,
      profileqrcode,
      logoName,
    } = this.props;
    const getProfileOrg = getCurrentUser();
    return (
      <div style={{ paddingTop: 80, paddingLeft: 5, width: "50%" }}>
        <div>
          <Card>
            <div style={{ position: "relative", width: 285, height: 407 }}>
              {/* Flipped Background image */}
              <img
                src={linkPathIDCard + backImage}
                className="back-paper"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Use cover to fit the container
                  transform: "scaleX(-1)", // Flip horizontally
                }}
              />

              {/* QR Code overlay */}
              <div
                className="qrcode-overlaycard-BackPapar"
                style={{
                  position: "absolute",
                  top: "10px", // Adjust position as needed
                  left: "10px", // Adjust position as needed
                  zIndex: 1,
                  transform: "scaleX(-1)", // Flip horizontally
                }}
              >
                <QRCode
                  errorLevel="H"
                  size={120}
                  color="#2471a3"
                  value={profileqrcode}
                  icon={linkPathIDCard + logoName}
                  iconSize={20}
                />
                {/* Text overlay */}
                <div
                  style={{
                    position: "absolute",

                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <span>ប្រធាន{getProfileOrg.org_name_kh}</span>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
