import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { request } from "../../share/request";
import { Stack } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
} from "antd";

import "../../styles/Home-module.css";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission, getCurrentUser } from "../../share/getProfile";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
const Option = { Select };
const BotTele = ({ idorg }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);

  const [visableNewTelegramBot, setVisableNewTelegramBot] = useState(false);
  const [visableDeleteModel, setVisableDeleteModel] = useState(false);

  const [id, setID] = useState("");
  const [type, setType] = useState("");
  const [tele_group_name, setTeleGroupName] = useState("");
  const [tele_token_id, setTelegramToken] = useState("");
  const [tele_grouid, setTelegramGroupID] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const deapartmentValue = userPermission.find(
    (permission) => permission.pagename === "department"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = deapartmentValue;
  // -----------------  end permisstion page --------------------

  useEffect(() => {
    // block form load
    if (idorg) {
      getList();
    }
  }, [idorg]);

  const getList = async () => {
    const param = new URLSearchParams({
      idorg: idorg,
    });
    // create function success
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("telegrambot?" + param.toString(), "get", {});
      if (res) {
        setList(res.list_API_Server);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const buttonStyle = {
    fontFamily: "Content ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const custom_model = {
    fontFamily: "Content ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const onOpenNewTelegramBot = () => {
    setID("");
    setType("");
    setTeleGroupName("");
    setTelegramGroupID("");
    setTelegramToken("");
    setVisableNewTelegramBot(true);
  };
  const onCloseTelegramBot = () => {
    setVisableNewTelegramBot(false);
  };

  const onChangeType = (event) => {
    // setType(event.target.value);
    setType(event);
  };
  const onChangeTele_token_id = (event) => {
    setTelegramToken(event.target.value);
  };
  const onChangeTele_grouid = (event) => {
    setTelegramGroupID(event.target.value);
  };
  const onChageTeleGroupName = (event) => {
    setTeleGroupName(event.target.value);
  };

  const onSaveTelegramBot = async () => {
    // Prepare data to send
    var data = {
      id: id,
      type: type,
      group_name: tele_group_name,
      group_id: tele_grouid,
      bot_name: tele_token_id,
      status: enable ? 1 : disable ? 0 : 1,
      org_code: idorg,
    };
    setVisableNewTelegramBot(false); // Close modal after request
    setLoadingSpin(true); // Start loading spinner
    try {
      const method = id === "" ? "post" : "put";
      console.log("Request method:", method);
      console.table(data);

      const res = await request("telegrambot", method, data);

      if (res) {
        getList(); // Reload list
        openNotification(
          "success",
          "Success",
          id == "" ? "Save is successfully" : "Update is successfully"
        );
      } else {
        openNotification("error", "Error", res);
      }
    } catch (error) {
      openNotification("error", "Error", error);
    } finally {
      setLoadingSpin(false); // Show loading spinner
    }
  };

  const onOpenEditModel = (item) => {
    setVisableNewTelegramBot(true);
    setID(item.id);
    setType(item.type);
    setTeleGroupName(item.group_name);
    setTelegramToken(item.bot_name);
    setTelegramGroupID(item.group_id);

    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };
  const onclickDeleteNo = () => {
    setVisableDeleteModel(false);
  };

  const onOpenModelDelete = (item) => {
    setVisableDeleteModel(true);
  };

  const getStatusContent = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            Enable
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            Disable
          </Button>
        );

      default:
        return null;
    }
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------
  const onCancel = () => {
    setVisableNewTelegramBot(false);
  };
  const onCancelDelete = () => {
    setVisableDeleteModel(false);
  };

  const onChangeTab = (key) => {
    console.log("onChangeTab ", key);
  };

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      <div style={boxHeader}>
        {boxHeaderTagText(" ព៏តមាន Telegram Bot")}
        <div>
          <Button
            disabled={!addvalue}
            onClick={onOpenNewTelegramBot}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            បន្ថែមថ្មី
          </Button>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table className="rounded-table">
            <thead>
              <tr class="table-success">
                <th>No</th>
                <th>Type of Sending</th>
                <th>Name of Telegram</th>
                <th>Group Telegram ID</th>
                <th>Token Bot ID</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.type}</td>
                  <td>{item.group_name}</td>
                  <td>{item.group_id}</td>
                  <td>{item.bot_name}</td>
                  <td>{getStatusContent(item.status)}</td>

                  <td style={{ width: 100 }}>
                    <Stack gap={2} direction="horizontal">
                      <Button
                        type="primary"
                        disabled={!Editvalue}
                        onClick={() => onOpenEditModel(item)}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        disabled={!deletevalue}
                        onClick={() => onOpenModelDelete(item)}
                        type="primary"
                        danger
                      >
                        Delete
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination>
          {Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map(
            (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </div>
      <Modal
        title={id == "" ? "បង្កើតថ្មី" : "កែប្រែ"}
        style={custom_model}
        width={580}
        open={visableNewTelegramBot}
        onCancel={onCancel}
        footer={[
          <div>
            <Space>
              <Button type="primary" onClick={onCloseTelegramBot}>
                Cancel
              </Button>
              <Button type="primary" onClick={onSaveTelegramBot}>
                {id == "" ? "Save" : "Update"}
              </Button>
            </Space>
          </div>,
        ]}
      >
        <Form style={buttonStyle}>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ប្រភេទ / Type of Telegram :
              </p>
              {/* <Input
                className="styleTageStatusForm"
                placeholder="input Type"
                value={type}
                onChange={onChangeType}
                style={{ width: 250 }}
              /> */}
              <Select
                className="styleTageStatusForm"
                placeholder="input Type"
                value={type}
                onChange={onChangeType}
                style={{ width: 250 }}
              >
                <Option value="car">Car</Option>
                <Option value="autoatendance">AutoAtendance</Option>
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                Telegram Bot Token:
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder="input Telegram Bot Token"
                value={tele_token_id}
                rows={2}
                onChange={onChangeTele_token_id}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                Group ID Number:
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder="input Group ID Number"
                value={tele_grouid}
                rows={2}
                onChange={onChangeTele_grouid}
                style={{ width: 250 }}
              />
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                Name of Telegram Group :
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={3}
                placeholder="input Name of Group"
                value={tele_group_name}
                onChange={onChageTeleGroupName}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ស្ថានភាព / Status :
              </p>
              <Tag style={{ marginTop: 10 }}>
                <div style={{ padding: 10, width: 235 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        title="លុបផ្នែក "
        style={buttonStyle}
        open={visableDeleteModel}
        onCancel={onCancelDelete}
        footer={[
          <div>
            <Button onClick={onclickDeleteNo}>No</Button>
            {/* <Button onClick={()=>onClickDeleteYes() } type="primary"   >Yes</Button> */}
          </div>,
        ]}
      >
        <p style={buttonStyle}>តើអ្នកចង់លុប នេះចេញពីប្រព័ន្ធ ?</p>
      </Modal>
    </div>
  );
};

export default BotTele;
