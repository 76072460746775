import { Button, Input, Select, message, Modal, Space } from "antd";
import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { request } from "../../share/request";
import { Circles } from "react-loader-spinner";

import { CameraOutlined, SaveOutlined } from "@ant-design/icons";
import { linkPathIDCard } from "../../share/helper";
import { getCurrentUser } from "../../share/getProfile";
import { openNotification } from "../../share/message";
const { Option } = Select;

const UserSystem = ({ list_role, idorg }) => {
  const getProfile = getCurrentUser();
  const [roleid, setRoleID] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [loadingSpin, setloadingSpin] = useState(false);
  const inputRefFront = useRef(null);
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState("");
  const cropperRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setID] = useState();
  const [LogoOrgPreview, setLogoOrgPreview] = useState(null);
  useEffect(() => {
    setID(null);
    setImage(null);
    setLogoOrgPreview(null);
    if (idorg) {
      getImageLinkOrganization();
    }
  }, [idorg]);
  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: idorg });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const logo_org = res.list_api.find((item) => item.param === "logo");
        setID(logo_org.id);
        if (logo_org) {
          setLogoOrgPreview(linkPathIDCard + logo_org.imagelink);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  const saveData = async () => {
    try {
      // Validation checks for required fields
      if (!phoneNumber || !fullName || !roleid) {
        message.error(
          "Please fill in all required fields: Phone Number, Full Name, and Role."
        );
        return; // Exit the function if any field is missing
      }

      const data = {
        phoneNumber,
        fullName,
        roleid,
        idorg,
      };

      setloadingSpin(true);

      const response = await request("settingusersystem", "post", data);

      if (response.success === true) {
        message.success("Data saved successfully!");
        setPhoneNumber("");
        setFullName("");
        setRoleID(null);
      } else {
        message.error(`Failed to save data. Status code: ${response.message}`);
      }
    } catch (error) {
      console.error("Save error:", error);
      message.error("An error occurred while saving.");
    } finally {
      setloadingSpin(false);
    }
  };

  const onClickSubmit = () => {
    console.log("Submitting:", { phoneNumber, fullName, roleid });
    saveData();
  };

  const onChangeProvider = (value) => {
    setRoleID(value);
    console.log("Provider selected:", value);
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    console.log("Phone number:", e.target.value);
  };

  const onChangeFullName = (e) => {
    setFullName(e.target.value);
    console.log("Full name:", e.target.value);
  };
  const handleUploadLogoOrganization = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setIsModalOpen(true);
        console.log("Image loaded");
      };
      reader.readAsDataURL(file);
    }
  };
  const getCroppedImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedDataUrl = cropper.getCroppedCanvas()?.toDataURL();

      if (!croppedDataUrl) {
        console.error(
          "Cropped canvas returned null. Check image source and CORS."
        );
      } else {
        setLogoOrgPreview(croppedDataUrl);
        console.log("Cropped image data URL:", croppedDataUrl);
      }

      setIsModalOpen(false);
    }
  };
  const stylesCard = () => {
    return {
      marginTop: 10,
      width: 250,
      height: 250,
      border: "2px solid #ccc", // Adds a border
      borderRadius: "8px", // Rounds the corners
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Adds shadow
      padding: "10px", // Adds padding inside the container
      backgroundColor: "#fff",
    };
  };

  const onHandleNew_Update = async () => {
    if (!image.startsWith("data:image/")) {
      console.error("Invalid base64 image format.");
      return;
    }

    // Convert base64 to a File object
    // const imageFile = base64ToFile(image, "logo.png", "image/png");
    const blob = await fetch(LogoOrgPreview).then((res) => res.blob());
    const imageFile = new File([blob], "logo.png", { type: "image/png" });
    const formData = new FormData();
    formData.append("id", id);
    formData.append("org_code", idorg);
    formData.append("param", "logo");
    formData.append("create_by", getProfile?.fullname || "Unknown");
    formData.append("file_image_logo", imageFile);

    // Log for debugging
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    setloadingSpin(true);
    try {
      const method = id == null || id === "" ? "post" : "put";
      const res = await request("settingorglogo", method, formData);

      if (res) {
        openNotification("success", "Success", "Saved Successfully");
        setImage(null);
        getImageLinkOrganization();
      }
    } catch (error) {
      console.error("Save failed:", error);
      openNotification("error", "Error", "Save failed");
    } finally {
      setloadingSpin(false);
    }
  };

  return (
    <div>
      {loadingSpin && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      <div className="khmer-font" style={{ display: "flex" }}>
        <div style={{ width: "300px" }}>
          <div style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "bold", color: "GrayText" }}>
              លេខទូស័ព្ទ / PhoneNumber:
            </p>
            <Input
              placeholder="Input Phone Number"
              style={{ height: 40 }}
              value={phoneNumber}
              onChange={onChangePhoneNumber}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "bold", color: "GrayText" }}>
              ឈ្មោះ​បុគ្គលិក / Fullname:
            </p>
            <Input
              placeholder="Input Your Fullname"
              style={{ height: 40 }}
              value={fullName}
              onChange={onChangeFullName}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "bold", color: "GrayText" }}>
              សិទ្ធអ្នកកាន់ / User Provider:
            </p>

            <Select
              className="styleTageStatusForm"
              onChange={onChangeProvider}
              value={roleid}
              style={{ width: "100%" }}
              allowClear
            >
              {list_role.map((item) => (
                <Option
                  className="styleTageStatusForm"
                  key={item.role_id}
                  value={item.role_id}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Button
              type="primary"
              onClick={onClickSubmit}
              style={{ width: "100%", marginTop: 10, height: 40 }}
            >
              Submit
            </Button>
          </div>
        </div>
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 100,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontWeight: "bold", color: "GrayText" }}>
            ផ្លាស់ប្តូ Logo របស់អង្គភាព
          </p>

          <div>
            <input
              type="file"
              ref={inputRefFront}
              style={{ display: "none" }}
              onChange={handleUploadLogoOrganization}
              accept="image/*"
            />
            <div>
              <Button
                style={{ width: "100%", height: 40, fontWeight: "bold" }}
                icon={<CameraOutlined />}
                type={image ? "primary" : "default"}
                variant="outlined"
                onClick={
                  image
                    ? () => onHandleNew_Update()
                    : () => inputRefFront.current.click()
                }
              >
                {image ? "Save Logo" : " Upload Photo"}
              </Button>
            </div>
            {/* Modal for cropping */}
            <Modal
              visible={isModalOpen}
              // onRequestClose={() => setIsModalOpen(false)}
              title="Crop Image"
              onOk={getCroppedImage}
              onCancel={() => setIsModalOpen(false)}
              okText="Crop"
              cancelText="Cancel"
            >
              {image && (
                <Cropper
                  crossOrigin="anonymous"
                  src={image}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={false}
                  ref={cropperRef}
                  key={image} // Add this line
                  viewMode={1}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                />
              )}
            </Modal>
          </div>

          {LogoOrgPreview ? (
            <div>
              <img src={LogoOrgPreview} alt="Cropped" style={stylesCard()} />
            </div>
          ) : (
            <div
              style={{
                ...stylesCard(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "bold", color: "GrayText" }}> No Logo</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSystem;
