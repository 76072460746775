import { useState, useEffect } from "react";
import { getCurrentUser } from "./getProfile";
import { request } from "./request";
import { linkPathIDCard } from "./helper";

const useOrganizationImage = () => {
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const getProfile = getCurrentUser();
      if (!getProfile || !getProfile.org_code) {
        console.error("Organization code is missing from the profile");
        return;
      }
      const param = new URLSearchParams({ org_code: getProfile.org_code });

      try {
        const res = await request(
          "settingorgidcard?" + param.toString(),
          "get",
          {}
        );

        if (res && res.list_api) {
          const logo_org = res.list_api.find((item) => item.param === "logo");
          if (logo_org && logo_org.imagelink) {
            const fullImagePath = linkPathIDCard + logo_org.imagelink;
            console.log("Fetched image path:", fullImagePath); // Debugging log
            setImagePath(fullImagePath);
          } else {
            console.warn("Logo not found in the API response");
          }
        } else {
          console.error("No valid response from the API");
        }
      } catch (error) {
        console.error("Failed to fetch organization image:", error);
      }
    };

    fetchImage();
  }, []); // Run once on mount

  return imagePath;
};

export default useOrganizationImage;
