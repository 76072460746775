import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  message,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import "../../styles/Home-module.css";
import { IoSaveOutline } from "react-icons/io5";
import { Province } from "../../share/Address/Address";
import { request } from "../../share/request";
import GetLoading from "../../share/getLoading";
const UserFamilyPage = ({ userid }) => {
  const [loading, setLoading] = useState(false);
  const [list_familyaddress, setListFamilyaddress] = useState([]);
  const [addresses, setAddresses] = useState({
    permanent: { province: null, city: null, commune: null, village: null },
    father: { province: null, city: null, commune: null, village: null },
    mother: { province: null, city: null, commune: null, village: null },
    witness_man: { province: null, city: null, commune: null, village: null },
    witness_woman: { province: null, city: null, commune: null, village: null },
  });

  const updateAddress = (section, field, value) => {
    setAddresses((prevState) => {
      return {
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value,
        },
      };
    });
  };

  const dataTableChildren = [
    {
      key: "No",
      title: "ល.រ",
      dataIndex: "No",
      width: "5%",
    },
    {
      key: "children_name",
      title: "ឈ្មោះកូន",
      dataIndex: "children_name",
      width: "15%",
    },
    {
      key: "gender",
      title: "ភេទ",
      dataIndex: "gender",
      width: "5%",
    },
    {
      key: "dob",
      title: "ថ្ងៃខែឆ្នាំកំណើត",
      dataIndex: "dob",
      width: "15%",
    },
    {
      key: "job",
      title: "មុខរបរការងារ",
      dataIndex: "job",
      width: "15%",
    },
    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      width: "15%",
    },
  ];

  useEffect(() => {
    console.log(" userid : ", userid);
    if (userid) {
      getListFmailyAddress();
    }
  }, [addresses, userid]);

  const onSaveFamilyAddress = async () => {
    const data = { user_id: userid, addresses: addresses };
    setLoading(true);

    // Make the POST request to save the family address
    const res = await request("userfamilyaddress", "POST", data);

    // Check the response and show success message
    if (res && res.isOk === true) {
      message.success("Family Address saved successfully!");
      setLoading(false);
    } else {
      // Handle unexpected response or error
      message.error("Failed to save Family Address. Please try again.");
      setLoading(false);
    }
  };
  // Fetch the list of provinces and family addresses
  const getListFmailyAddress = async () => {
    const param = new URLSearchParams({
      user_id: userid,
    });
    const res = await request(
      "userfamilyaddress?" + param.toString(),
      "get",
      {}
    );
    if (res) {
      console.table(res.api_user_familyaddress);
      setListFamilyaddress(res.api_user_familyaddress || []);
    }
  };

  return (
    <div className="khmer-font-content">
      {loading ? (
        <div>
          <div className="sprinSave">
            <GetLoading />
          </div>
        </div>
      ) : null}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Space>
            <span>១. ប្រពន្ធ ឬប្តីឈ្មោះ </span>
            <Input
              placeholder="....................."
              style={{ width: "100%" }}
            />
            <Tag style={{ padding: "3px" }}>
              <Checkbox /> រស់ <Checkbox /> ស្លាប់
            </Tag>
            <span>កើតថ្ងៃទី ៖</span>
            <DatePicker />
            <span> .មុខរបរបច្ចុប្បន្ន</span>
            <Input
              placeholder="....................."
              style={{ width: "100%" }}
            />
          </Space>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <span> .អាស័យដ្ឋានអចិន្រ្តៃយ៍បច្ចុប្បន្ន ប្តី​ ឬប្រពន្ធ៖ </span>
        </div>
        <div style={{ marginTop: "5px" }}>
          <Space>
            <span> ផ្ទះលេខ ៖ </span>
            <Input type="text" placeholder="......." style={{ width: 100 }} />
            <span>ផ្លូវលេខ​ ៖ </span>
            <Input type="text" placeholder="......." style={{ width: 100 }} />

            {list_familyaddress && list_familyaddress.length > 0 ? (
              list_familyaddress
                ?.filter((item) => item.member_type === "permanent")
                .map((address) => (
                  <Province
                    key={address.id}
                    province_name={address.province_name_khmer}
                    city_name={address.city_name_khmer}
                    commune_name={address.com_name_khmer}
                    village_name={address.village_name_khmer}
                    onSelect={(field, value) =>
                      updateAddress("permanent", field, value)
                    }
                  />
                ))
            ) : (
              <Province
                onSelect={(field, value) =>
                  updateAddress("permanent", field, value)
                }
              />
            )}
          </Space>
        </div>
        <div style={{ marginTop: "5px" }}>
          <span>លេខទូស័ទ្ទ ៖ </span>
          <Input
            placeholder="លេខប្រពន្ធ ឬប្តី...."
            style={{ width: "150px" }}
          />
          <span> ចំនួនកូន ៖ </span>
          <Input placeholder="...សរុបកូន" style={{ width: "150px" }} />
          <span> ចំនួនកូនស្រី ៖ </span>
          <Input placeholder=".........នាក់" style={{ width: "150px" }} />
          <span> ចំនួនកូនប្រុស ៖ </span>
          <Input placeholder=".........នាក់" style={{ width: "150px" }} />
        </div>
        <div style={{ marginTop: "5px" }}>
          <Table
            style={{ marginRight: "20%" }}
            columns={dataTableChildren}
            dataSource={[]}
            pagination={false}
            locale={{
              emptyText: <div style={{ height: "50px" }}></div>, // Placeholder
            }}
          />
        </div>
        <div style={{ marginTop: "5px" }}>
          <Space>
            <span>២.ឪពុកបង្កើតឈ្មោះ ៖ </span>
            <Input placeholder="...." style={{ width: "150px" }} />
            <Tag style={{ padding: "3px" }}>
              <Checkbox /> រស់ <Checkbox /> ស្លាប់
            </Tag>
            <span>កើតថ្ងៃទី ៖</span>
            <DatePicker />
            <span>២.មុខរបរបច្ចុប្បន្ន</span>
            <Input placeholder="....................." />
          </Space>
        </div>
        <div style={{ marginTop: "5px", marginLeft: 30 }}>
          <span>.ស្រុកកំណើត (ឪពុកបង្កើត)</span>

          {list_familyaddress && list_familyaddress.length > 0 ? (
            list_familyaddress
              ?.filter((item) => item.member_type === "father")
              .map((address) => (
                <Province
                  key={address.id}
                  province_name={address.province_name_khmer}
                  city_name={address.city_name_khmer}
                  commune_name={address.com_name_khmer}
                  village_name={address.village_name_khmer}
                  onSelect={(field, value) =>
                    updateAddress("father", field, value)
                  }
                />
              ))
          ) : (
            <Province
              onSelect={(field, value) => updateAddress("father", field, value)}
            />
          )}
        </div>
        <div style={{ marginTop: "5px" }}>
          <Space>
            <span>៣.ម្តាយបង្កើតឈ្មោះ ៖ </span>
            <Input placeholder="...." style={{ width: "150px" }} />
            <Tag style={{ padding: "3px" }}>
              <Checkbox /> រស់ <Checkbox /> ស្លាប់
            </Tag>
            <span>កើតថ្ងៃទី ៖</span>
            <DatePicker />
            <span> .មុខរបរបច្ចុប្បន្ន</span>
            <Input placeholder="....................." />
          </Space>
        </div>
        <div style={{ marginTop: "5px", marginLeft: 30 }}>
          <span> .ស្រុកកំណើត(ម្តាយបង្កើត) </span>

          {list_familyaddress && list_familyaddress.length > 0 ? (
            list_familyaddress
              .filter((item) => item.member_type === "mother")
              .map((address) => (
                <Province
                  key={address.id}
                  province_name={address.province_name_khmer}
                  city_name={address.city_name_khmer}
                  commune_name={address.com_name_khmer}
                  village_name={address.village_name_khmer}
                  onSelect={(field, value) =>
                    updateAddress("mother", field, value)
                  }
                />
              ))
          ) : (
            <Province
              onSelect={(field, value) => updateAddress("mother", field, value)}
            />
          )}
        </div>
        <div style={{ marginTop: "5px" }}>
          <Space>
            <span>
              ៤.អ្នកដឹងលឺ មួយ ឬពីរនាក់(ឈ្មោះ ភេទ មុខរបរការងារ និងអាស័យដ្ឋាន) ៖{" "}
            </span>
          </Space>
          <div
            style={{
              backgroundColor: "#f8f9f9",
              padding: "5px",
              borderRadius: "5px",
              marginLeft: 30,
            }}
          >
            <Space>
              <span>១.ឈ្មោះ ៖ </span>
              <Input placeholder="...." style={{ width: "150px" }} />
              <Tag style={{ padding: "3px" }}>
                <Checkbox /> ប្រុស <Checkbox /> ស្រី
              </Tag>
              <span>កើតថ្ងៃទី ៖</span>
              <DatePicker />
              <span>២.មុខរបរបច្ចុប្បន្ន</span>
              <Input placeholder="....................." />
            </Space>
            <div style={{ marginTop: "5px" }}>
              <Space>
                {" "}
                <span> ផ្ទះលេខ ៖ </span>
                <Input
                  type="text"
                  placeholder="......."
                  style={{ width: 100 }}
                />
                <span>ផ្លូវលេខ​ ៖ </span>
                <Input
                  type="text"
                  placeholder="......."
                  style={{ width: 100 }}
                />
                {list_familyaddress && list_familyaddress.length > 0 ? (
                  list_familyaddress
                    .filter(
                      (item) => item && item.member_type === "witness_man" // Ensure `item` is not null/undefined
                    )
                    .map((address) => (
                      <Province
                        key={address.id}
                        province_name={address.province_name_khmer || []}
                        city_name={address.city_name_khmer || []}
                        commune_name={address.com_name_khmer || []}
                        village_name={address.village_name_khmer || []}
                        onSelect={(field, value) =>
                          updateAddress("witness_man", field, value)
                        }
                      />
                    ))
                ) : (
                  <>
                    {console.log("Fallback is being rendered")}
                    <Province
                      onSelect={(field, value) =>
                        updateAddress("witness_man", field, value)
                      }
                    />
                  </>
                )}
              </Space>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#f8f9f9",
              padding: "5px",
              marginLeft: 30,
            }}
          >
            <Space>
              <span>២.ឈ្មោះ ៖ </span>
              <Input placeholder="...." style={{ width: "150px" }} />
              <Tag style={{ padding: "3px" }}>
                <Checkbox /> ប្រុស <Checkbox /> ស្រី
              </Tag>
              <span>កើតថ្ងៃទី ៖</span>
              <DatePicker />
              <span>២.មុខរបរបច្ចុប្បន្ន</span>
              <Input placeholder="....................." />
            </Space>
            <div style={{ marginTop: "5px" }}>
              <Space>
                <span> ផ្ទះលេខ ៖ </span>
                <Input
                  type="text"
                  placeholder="......."
                  style={{ width: 100 }}
                />
                <span>ផ្លូវលេខ​ ៖ </span>
                <Input
                  type="text"
                  placeholder="......."
                  style={{ width: 100 }}
                />

                {list_familyaddress && list_familyaddress.length > 0 ? (
                  list_familyaddress
                    .filter((item) => item.member_type === "witness_woman")
                    .map((address) => (
                      <Province
                        key={address.id}
                        province_name={address.province_name_khmer || []}
                        city_name={address.city_name_khmer || []}
                        commune_name={address.com_name_khmer || []}
                        village_name={address.village_name_khmer || []}
                        onSelect={(field, value) =>
                          updateAddress("witness_woman", field, value)
                        }
                      />
                    ))
                ) : (
                  <Province
                    onSelect={(field, value) =>
                      updateAddress("witness_woman", field, value)
                    }
                  />
                )}
              </Space>
            </div>
          </div>

          <div style={{ marginTop: "5px" }}>
            <Button
              type="primary"
              onClick={onSaveFamilyAddress}
              icon={<IoSaveOutline />}
            >
              រក្សាទុក
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserFamilyPage;
