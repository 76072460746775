import React, { useEffect, useState } from "react";
import { request } from "../../share/request";
import { Button, message, Select, Space } from "antd";
import { Circles } from "react-loader-spinner";

export default function SubGroup({ province_id, org_code }) {
  const { Option } = Select;
  const [group_id, setGroupID] = useState(null);
  const [group_default_id, setGroupDefaultID] = useState(null);
  const [listGroup, setListGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectDefualt, setselectDefualt] = useState([]);
  const [isUserSelected, setIsUserSelected] = useState(false); // Tracks if the user has selected manually

  const [objFilter, setObjFilter] = useState({
    province_id: province_id,
    group_id: null,
    org_code: org_code,
  });
  useEffect(() => {
    if (org_code) {
      console.log("org_code:", org_code);
      setLoading(true);

      const updatedFilter = {
        province_id: province_id,
        group_id: null,
        org_code: org_code,
      };

      // Fetch list and default selected value
      getList(updatedFilter)
        .then((defaultGroup) => {
          if (defaultGroup && !isUserSelected) {
            // Only set default if user hasn't manually selected a value
            setGroupID(defaultGroup.id);
            setGroupDefaultID(defaultGroup.id);
            setselectDefualt(
              `${defaultGroup.province_name_khmer} | ${defaultGroup.group_name}`
            );
          }
        })
        .finally(() => setLoading(false));
    }
  }, [province_id, org_code]); // Re-run whenever province_id or org_code changes

  const getList = async (filter) => {
    const param = new URLSearchParams({
      province_id: filter.province_id,
      group_id: filter.group_id,
      org_code: filter.org_code,
    });
    console.log("json param M: ", JSON.stringify(param));
    const res = await request(
      `settingorgmanagenot-maingroup?${param.toString()}`,
      "get",
      {}
    );

    if (res) {
      console.log("list of org : ", res.list_group);
      setListGroup(res.list_group || []); // Set the groups here or use empty array

      // Find the default selected group
      const defaultGroup = res.list_api_group?.[0] || []; // Assuming the API returns default in `list_api_group`
      return defaultGroup; // Return the default group
    } else {
      console.log("Server is not responding");
      setListGroup([]); // Clear the list if there’s no response
      return null;
    }
  };
  const onChangeSubGroup = (value) => {
    // Set selected group ID and mark as user-selected
    setGroupID(value);
    setIsUserSelected(true);

    // Find the selected record to display
    const selected = listGroup.find((record) => record.id === value);
    if (selected) {
      setselectDefualt(
        `${selected.province_name_khmer} | ${selected.group_name}`
      );
    }
    console.log("onChangeSubGroup:", value, "Selected Group:", selected);
  };

  const onSaveSubGroup = async () => {
    const data = {
      group_id: group_id,
      org_id: org_code,
      group_default_id: group_default_id,
    };
    console.table(data);
    setLoading(true);

    try {
      const res = await request("settingorggrouptogroup", "put", data);
      if (res && res.success == true) {
        message.success(res.message);
      } else {
        message.error("failed to update Group");
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      <div>
        <Space>
          {/* default: {group_default_id} */}
          <Select
            className="styleTageStatusForm"
            style={{ width: "400px" }}
            value={group_id || []} // Ensures placeholder is shown when `group_id` is null
            onChange={onChangeSubGroup}
            placeholder="Please select group" // Placeholder text
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {listGroup.map((record) => (
              <Option
                key={record.id}
                value={record.id}
                className="styleTageStatusForm"
              >
                {record.province_name_khmer} | {record.group_name}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={onSaveSubGroup}>
            {group_default_id == null ? "Save Group" : "Update Group"}
          </Button>
        </Space>
      </div>
    </div>
  );
}
