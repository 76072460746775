import React, { useEffect, useState } from "react";
import {
  Modal,
  Select,
  Table,
  Button,
  Space,
  DatePicker,
  Popconfirm,
  Tag,
} from "antd";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { request } from "../../share/request";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoDocumentTextOutline } from "react-icons/io5";
import moment from "moment";
import { openNotification } from "../../share/message";
import {
  formateDateClientTime,
  formateDateSystem,
  timeGeneratorScanlog,
  toKhmerDayWeekend,
} from "../../share/helper";
import queryString from "query-string";
//  for export data to excel
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { Circles } from "react-loader-spinner";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const StaffSchedule = () => {
  const [listJoiner, setListJoiner] = useState([]);
  const [StaffName, setStaffName] = useState("");
  const [dateschedule, setDateSchedule] = useState("");
  const [UserLetters_StaffCodeJoin, setUserLetters_StaffCodeJoin] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isAddingRow, setIsAddingRow] = useState(false); // State variable to track if adding row
  const showAddButton = !dataSource || dataSource.length === 0;

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [DownloadReport, setDownloadReport] = useState(false);

  const [selectedDateStart, setSelectedDate_start] =
    useState(/* initialize with a valid date object */);
  const [selectedDateEnd, setSelectedDate_end] =
    useState(/* initialize with a valid date object */);

  const pagesizeIndex = 7;
  useEffect(() => {
    setPageNumber(1);
    getlist();
    // Fetch data from MySQL when component mounts
  }, []);
  const isEditing = (record, dataIndex) =>
    record.id === editingKey && dataIndex !== editingKey;

  const handleAdd = () => {
    // Create a new row object with default values or prompt the user for input
    const newRow = {
      id: "", // Generate a unique ID for the new row
      date: "",
      head1: null,
      head2: null,
      head3: null,
      head4: null,
      head5: null,
      head6: null,
      head7: null,
      head8: null,
      head9: null,
      head10: null,
      head11: null,
      head12: null,
      head13: null,
      head14: null,
      head15: null,
      head16: null,
      head17: null,
      head18: null,
      head19: null,
      head20: null,
    };

    // Update the dataSource state by adding the new row
    setDataSource([...dataSource, newRow]);

    // Optionally, update the editingKey state if necessary
    setEditingKey(newRow.id);

    // Optionally, send the new data to your backend API to persist it
    // You can use your request function here to send a POST request with the new row data
  };
  const actionTitle = showAddButton ? (
    <span>
      Action
      <Button
        type="primary"
        onClick={handleAdd}
        className="buttonstylekhmer"
        icon={<PlusCircleOutlined />}
        style={{ marginLeft: "8px" }}
      />
    </span>
  ) : (
    "Action"
  );
  const getlist = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("schedule", "get", {});
      console.log("API response:", res);

      if (res && res.API_Server_ListSchedule) {
        setDataSource(res.API_Server_ListSchedule);
        setListJoiner(res.API_Server_ListJoiner);
      } else {
        console.error("Invalid API response:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsAddingRow(true);
    }
    setLoading(false);
  };
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      editable: false,
      fixed: "left",
      width: 100,
      render: (text, record, index) =>
        (PageNumber - 1) * pagesizeIndex + index + 1, // Calculate the correct index
    },
    {
      title: "ថ្ងៃខែឆ្នាំ យាម",
      dataIndex: "date",
      editable: true,
      fixed: "left",
      render: (text, record) => renderEditableCell_Date(text, record, "date"),
      width: 200,
    },
    {
      title: "ថ្នាក់ដឹកនាំ",
      dataIndex: "head1",
      editable: true,
      render: (text, record) => renderEditableCell(text, record, "head1"),
    },
    {
      title: "បច្ចេកទេស",
      dataIndex: "head2",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head2"),
    },
    {
      title: "បែងចែកជម្ងឺ",
      dataIndex: "head3",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head3"),
    },
    {
      title: "សង្គ្រោះបន្ទាន់",
      dataIndex: "head4",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head4"),
    },
    {
      title: "សម្ភព រោគស្រី",
      dataIndex: "head5",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head5"),
    },
    {
      title: "របួស វះកាត់",
      dataIndex: "head6",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head6"),
    },
    {
      title: "ជម្ងឺទូទៅ",
      dataIndex: "head7",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head7"),
    },
    {
      title: "ជម្ងឺកុមារ",
      dataIndex: "head8",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head8"),
    },
    {
      title: "ថែទាំទារក",
      dataIndex: "head9",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head9"),
    },
    {
      title: "វិទ្យុសាស្រ្ត",
      dataIndex: "head10",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head10"),
    },
    {
      title: "ចក្ខុរោគ",
      dataIndex: "head11",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head11"),
    },
    {
      title: "ពិសោធន៍",
      dataIndex: "head12",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head12"),
    },
    {
      title: "ឱសថ",
      dataIndex: "head13",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head13"),
    },
    {
      title: "ទន្តសាស្រ្ត",
      dataIndex: "head14",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head14"),
    },
    {
      title: "បញ្ចូល",
      dataIndex: "head15",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head15"),
    },
    {
      title: "ជម្ងឺរបេង",
      dataIndex: "head16",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head16"),
    },
    {
      title: "លែងបង់ថ្លៃ",
      dataIndex: "head17",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head17"),
    },
    {
      title: "បបស",
      dataIndex: "head18",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head18"),
    },
    {
      title: "មូលនិធិ",
      dataIndex: "head19",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head19"),
    },
    {
      title: "រដ្ឋបាល",
      dataIndex: "head20",
      editable: true,

      render: (text, record) => renderEditableCell(text, record, "head20"),
    },

    // Add similar render function for other editable columns
    {
      title: actionTitle,
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (_, record, index) => {
        const editable = isEditing(record);

        return (
          <div>
            {editable ? (
              <Space size="middle">
                <Button type="primary" onClick={() => handleSave(record)}>
                  Save
                </Button>
                <Button onClick={handleCancel} type="primary" danger>
                  Cancel
                </Button>
              </Space>
            ) : (
              <>
                <Space>
                  <Button
                    disabled={editingKey !== ""}
                    onClick={() => handleEdit(record)}
                    type="primary"
                    icon={<MdOutlineEdit />}
                  />

                  <Button
                    disabled={editingKey !== ""}
                    type="primary"
                    danger
                    icon={<MdOutlineDelete />}
                  />

                  {(PageNumber - 1) * pagesizeIndex + index + 1 ==
                    dataSource.length && (
                    <Button
                      type="primary"
                      onClick={handleAdd}
                      className="buttonstylekhmer"
                      icon={<PlusCircleOutlined />}
                    ></Button>
                  )}
                </Space>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleSave = async (record) => {
    const records = {
      id: record.id,
      date: record.date,
      head1: record.head1,
      head2: record.head2,
      head3: record.head3,
      head4: record.head4,
      head5: record.head5,
      head6: record.head6,
      head7: record.head7,
      head8: record.head8,
      head9: record.head9,
      head10: record.head10,
      head11: record.head11,
      head12: record.head12,
      head13: record.head13,
      head14: record.head14,
      head15: record.head15,
      head16: record.head16,
      head17: record.head17,
      head18: record.head18,
      head19: record.head19,
      head20: record.head20,
      create_date: "admin",
      status: 1,
    };

    let method = record.id ? "put" : "post";
    const res = await request("schedule", method, records);
    if (res) {
      openNotification(
        "success",
        "Success",
        record.id ? "Data updated successfully " : "Insert  successfully "
      );
      getlist();
    }
    setEditingKey("");
  };
  const handleEdit = (record) => {
    setEditingKey(record.id);
    // const arrayValueForSelect = record.head1.split(',').map(value => value.trim());
    // Ensure that the date field is not null or undefined
    const dateValue = record.date ? moment(record.date, "YYYY-MM-DD") : null;

    // Optionally, update the record object with the formatted date value
    const updatedRecord = { ...record, date: dateValue };
  };
  const handleCancel = () => {
    setEditingKey("");
    getlist();
  };

  const renderEditableCell_Date = (text, record, dataIndex) => {
    const editable = isEditing(record);
    const dateValue = record.date ? moment(record.date, "YYYY-MM-DD") : null;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {editable ? (
          <div>
            <Tag color="magenta" style={{ marginBottom: 5 }}>
              {record.date}
            </Tag>
            <DatePicker
              style={{ width: 150 }}
              label="Select Date"
              onChange={(date, dateString) => {
                const newData = [...dataSource];
                const index = newData.findIndex(
                  (item) => record.id === item.id
                );
                if (index > -1) {
                  newData[index][dataIndex] = dateString; // Use dateString directly instead of formatting it
                  setDataSource(newData);
                }
              }}
            />
          </div>
        ) : (
          <div style={{ width: 90, fontWeight: "bold" }}>
            <Tag color="green" style={{ fontFamily: "Khmer OS Battambang" }}>
              {toKhmerDayWeekend(
                moment(text, "YYYY-MM-DD").locale("km").format("dddd")
              )}
            </Tag>
            <Tag color="#f50" style={{ marginTop: 5 }}>
              {text}
            </Tag>
          </div>
        )}
      </div>
    );
  };

  const tagColors = ["#108ee9", "#87d068", "#f50"];
  const renderEditableCell = (text, record, dataIndex) => {
    const editable = isEditing(record);
    // Check if record[dataIndex] is a string before splitting it
    const arrayValueForSelect =
      typeof record[dataIndex] === "string"
        ? record[dataIndex].split(",").map((value) => value.trim())
        : [];

    return editable ? (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "150px" }}
        placeholder="Please select"
        value={arrayValueForSelect} // Use the value from the record object
        onChange={(selectedValues) => {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => record.id === item.id);

          if (index > -1) {
            // Join the selected values into a comma-separated string
            newData[index][dataIndex] = selectedValues.join(","); // Update with selected values
            setDataSource(newData); // Update the state with new data
          }
        }}
        options={DataJoiner}
        showSearch // Enable search functionality
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        } // Custom
      />
    ) : (
      <div style={{ width: 100 }}>
        {arrayValueForSelect.map((value, index) => (
          <Tag
            key={index}
            color={tagColors[index % tagColors.length]}
            style={{ marginTop: 2, fontFamily: "Khmer OS Battambang" }}
          >
            {value}
          </Tag>
        ))}
      </div>
    );
  };

  const DataJoiner = listJoiner.map((staff, index) => ({
    label: staff.staffname_kh1,
    value: staff.staffname_kh1, // Using idnumber as the value
  }));
  const [PageNumber, setPageNumber] = useState();
  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber); // Assuming you have a state variable pageNumber and a setter function setPageNumber
  };

  const onExporttoExcel = async () => {
    if (!selectedDateStart || !selectedDateEnd) {
      console.error("Selected dates are undefined");
      return;
    }
    const param = {
      date_start: selectedDateStart,
      date_end: selectedDateEnd,
    };

    const queryParams = queryString.stringify(param);
    console.log("date start:", formateDateSystem(selectedDateStart));
    console.log("date end:", formateDateSystem(selectedDateEnd));
    const res = await request(`schedulereport?${queryParams}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.dataRequestSchedule)
        ? res.dataRequestSchedule
        : [];

      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  const onExporttoExcelTotal = async () => {
    if (!selectedDateStart || !selectedDateEnd) {
      console.error("Selected dates are undefined");
      return;
    }
    const param = {
      date_start: selectedDateStart,
      date_end: selectedDateEnd,
    };

    const queryParams = queryString.stringify(param);
    console.log("date start:", formateDateSystem(selectedDateStart));
    console.log("date end:", formateDateSystem(selectedDateEnd));
    const res = await request(`schedulereport?${queryParams}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.listTotalStaff)
        ? res.listTotalStaff[0]
        : [];

      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };

  //  for download leave
  const handleDateChange_Start = (date) => {
    setSelectedDate_start(date);
  };
  const handleDateChange_End = (date) => {
    setSelectedDate_end(date);
  };
  const downloadExcel = async () => {
    await new Promise((resolve) => setTimeout(resolve, 400));
    // Usage
    const data = await onExporttoExcel();
    const data_total = await onExporttoExcelTotal();

    // Load the template Excel file from the public folder
    const templatePath =
      process.env.PUBLIC_URL + "/template/staffschedule.xlsx";
    const response = await fetch(templatePath);
    const templateArrayBuffer = await response.arrayBuffer();

    // Create a workbook from the template file
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(templateArrayBuffer);

    // Get the 'data' sheet from the workbook
    const dataSheet = workbook.getWorksheet("detail");
    const dataSheet_toal = workbook.getWorksheet("sumary");
    if (!dataSheet) {
      console.error('Sheet with name "data" not found in the template.');
      return;
    }

    // Convert the new data to worksheet format
    const newData = data.map((item) => Object.values(item));
    const newData_total = data_total.map((item) => Object.values(item));
    // Append the new data to the existing 'data' sheet starting from cell A3
    dataSheet.addTable({
      name: "dataTable",
      ref: "A6",
      headerRow: true,
      columns: Object.keys(data[0]).map((key) => ({ name: key })),
      rows: newData,
    });
    dataSheet_toal.addTable({
      name: "dataTable1",
      ref: "C10",
      headerRow: true,
      columns: Object.keys(data_total[0]).map((key) => ({ name: key })),
      rows: newData_total,
    });

    // Add report date range on cell A4

    dataSheet.getCell(
      "B4"
    ).value = ` របាយការណ៍ចាប់ពីថ្ងៃទី៖ ${selectedDateStart.format(
      "MM-DD-YYYY"
    )} ដល់ថ្ងៃទី៖  ${selectedDateEnd.format("MM-DD-YYYY")}`;
    dataSheet_toal.getCell(
      "A8"
    ).value = ` របាយការណ៍ចាប់ពីថ្ងៃទី៖ ${selectedDateStart.format(
      "MM-DD-YYYY"
    )} ដល់ថ្ងៃទី៖  ${selectedDateEnd.format("MM-DD-YYYY")}`;

    for (let row = 7; row < data.length + 7; row++) {
      console.log("Row:", row);
      // Get the cell object from the data sheet
      const cellValueA = dataSheet.getCell(row, 1).value;
      console.log("Cell value: " + cellValueA);
      if (cellValueA !== null) {
        console.log("Cell value is not null, formatting cells in row", row);
        // Iterate over each column in the row
        for (let col = 1; col <= 22; col++) {
          // Get the cell object from the data sheet
          const cell = dataSheet.getCell(row, col);

          const cellValue = cell.toString();
          // Split the cell value by commas
          const parts = cellValue.split(",");
          // Initialize result for the current cell
          let result = "";
          for (let i = 0; i < parts.length; i++) {
            // Append each part with its index followed by a line break
            // result += `${i + 1}.${parts[i].trim()}\n`; if you add index number
            result += `${""}.${parts[i].trim()}\n`;
          }
          // Update the value in the Excel worksheet
          cell.value = result.trim(); // Trim whitespace at the end to remove the extra line break

          console.log(`Data not found for row ${row - 7} or column ${col - 1}`);
          // Optionally, you can handle this case by setting a default value or leaving the cell blank
          cell.value = result.trim(); // Set the cell value to an empty string
        }
      }
    }

    const startRowToDelete = data.length + 7;
    const endRowToHide = 998;

    for (let row = startRowToDelete; row <= endRowToHide; row++) {
      // Set the height of the row to a very small value to hide it
      dataSheet.getRow(row).height = 0.1;
    }
    const startRowToDelete_total = data_total.length + 12;
    const endRowToHide_total = 500;
    for (
      let row1 = startRowToDelete_total;
      row1 <= endRowToHide_total;
      row1++
    ) {
      // Set the height of the row to a very small value to hide it
      dataSheet_toal.getRow(row1).height = 0.1;
    }
    // Save the modified workbook as a Blob with XLSX format
    const blob = await workbook.xlsx.writeBuffer();
    const name_report = `Report-${selectedDateStart.format(
      "MM-DD-YYYY"
    )} to ${selectedDateEnd.format("MM-DD-YYYYHHmmss")}`;
    FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
  };
  return (
    <div>
      <>
        <div style={boxHeader}>
          <div style={{ display: "flex" }}>
            <Space>
              {boxHeaderTagText(
                "   ក. តារាងវេនយាមប្រចាំសប្តាហ៍របស់មន្ត្រីរាជការ"
              )}
              <div style={{ marginBottom: 10 }}>
                <Space>
                  <DatePicker />
                  <DatePicker />
                </Space>
              </div>
            </Space>
          </div>
          <div style={{ display: "flex" }}>
            <Space>
              <Button
                className="buttonstylekhmer"
                type="primary"
                onClick={() => setDownloadReport(true)}
                icon={
                  <IoDocumentTextOutline style={{ width: 20, height: 20 }} />
                }
              >
                ទាញយករបាយការណ៍វេនយាម
              </Button>
            </Space>
          </div>
        </div>
        <div className="sprinSave-container">
          {/* Your other components */}
          {loadingSpin && (
            <div className="sprinSave">
              <Circles
                height="80"
                width="80"
                color="#2e86c1"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
        <div style={{ overflowX: "auto" }}>
          <div>
            {loading ? (
              <div className="sprinCustome">
                <Circles
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <Table
                rowKey="id"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  pageSize: pagesizeIndex,
                  onChange: handlePageChange,

                  // Set the number of rows per page
                  // You can also include other pagination options here, such as total, defaultCurrent, etc.
                }}
                rowClassName={(record) =>
                  isEditing(record) ? "editing-row" : ""
                } // Apply custom class to editing rows
              />
            )}
          </div>
        </div>
        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel}
              >
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker onChange={handleDateChange_Start} />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker onChange={handleDateChange_End} />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default StaffSchedule;
