import { useEffect, useState, useRef } from "react";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  CloseOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Pagination,
  List,
  Row,
  Col,
  DatePicker,
  Tabs,
  Popconfirm,
  message,
} from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import { getStatusContent, getTageStyle } from "../../share/getStatusContent";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import ShiftPage from "./ShiftPage";
import { FaPeopleArrows } from "react-icons/fa6";
import { BsShiftFill } from "react-icons/bs";
import GetLoading from "../../share/getLoading";
import { getCurrentUser } from "../../share/getProfile";
import dayjs from "dayjs";
import { formateDateSystem } from "../../share/helper";
import moment from "moment";
const { TabPane } = Tabs;

const HolidayPage = () => {
  const getProfile = getCurrentUser();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingHoliday, setEditingHoliday] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    getList();
  }, []);
  const handleDeleteHoliday = async (item) => {
    const param = new URLSearchParams({
      id: item.id,
    });
    console.log(" deleting: " + item.id);
    setLoadingSpin(true);
    try {
      const res = await request("holidays?" + param.toString(), "DELETE", {});
      if (res.isOk == true) {
        getList();
      } else {
        console.error("Failed to delete holiday");
      }
    } catch (error) {
      console.error("Error while deleting holiday:", error);
    } finally {
      setLoadingSpin(false);
      getList();
    }
  };
  const handleAddNewHoliday = async () => {
    form
      .validateFields()
      .then(async (values) => {
        // Prepare data for API
        const holidayData = {
          id: id,
          holiday_name_kh: values.holiday_name_kh,
          holiday_name_eng: values.holiday_name_eng,
          date_start: values.date_start.format("YYYY-MM-DD"), // format the date
          date_end: values.date_end.format("YYYY-MM-DD"), // format the date
          usercreate: getProfile.fullname, // Assume this comes from the profile
          status: 1, // or any status logic you need
          org_code: getProfile.org_code, // Assuming you get this from user profile
        };
        console.log("holidayData", holidayData);
        // Show loading spinner while submitting
        setLoadingSpin(true);
        let method = id === null ? "POST" : "PUT";
        try {
          const res = await request("holidays", method, holidayData);
          if (res && res.isOk == true) {
            // Successfully created, fetch the updated list
            getList();
            setIsModalVisible(false); // Close modal
            message.success(
              id === null
                ? "Holiday created successfully"
                : "Holiday Update successfully"
            );
            form.resetFields(); // Reset the form
          } else {
            // Handle error case
            console.error("Failed to create holiday");
          }
        } catch (error) {
          console.error("Error while creating holiday:", error);
        } finally {
          setLoadingSpin(false); // Hide spinner after request
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const getList = async () => {
    const param = new URLSearchParams({
      org_code: getProfile.org_code,
    });
    try {
      const res = await request("holidays?" + param.toString(), "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setList(res.api_holidays);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const [id, setId] = useState(null);
  const handleEditHoliday = (holiday) => {
    setId(holiday.id);
    setEditingHoliday(holiday);
    form.setFieldsValue({
      holiday_name_kh: holiday.holiday_name_kh,
      holiday_name_eng: holiday.holiday_name_eng,
      date_start: dayjs(holiday.date_start),
      date_end: dayjs(holiday.date_end),
      usercreate: getProfile.fullname,
      status: holiday.status,
    });

    setIsEditing(true);
    setIsModalVisible(true);
  };

  const columns = [
    {
      key: "typ_id",
      title: "ល.រ",
      dataIndex: "id",
    },
    {
      key: "holiday_name_kh",
      title: "ឈ្មោះថ្ងៃឈប់សម្រាក KH",
      dataIndex: "holiday_name_kh",
    },
    {
      key: "holiday_name_eng",
      title: "ឈ្មោះថ្ងៃឈប់សម្រាក EN",
      dataIndex: "holiday_name_eng",
    },

    {
      key: "date_start",
      title: "ថ្ងៃចាប់ផ្តើម",
      dataIndex: "date_start",
      render: (value, item, index) => {
        return (
          <div>
            <Tag color="green"> {formateDateSystem(value)} </Tag>
          </div>
        );
      },
    },
    {
      key: "date_end",
      title: "ដល់ថ្ងៃទី",
      dataIndex: "date_end",
      render: (value, item, index) => {
        return (
          <div>
            <Tag color="green"> {formateDateSystem(value)} </Tag>
          </div>
        );
      },
    },
    {
      key: "usercreate",
      title: "អ្នកបង្កើត",
      dataIndex: "usercreate",
      render: getTageStyle,
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
    },

    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button type="primary" onClick={() => handleEditHoliday(item)}>
                Edit
              </Button>

              <Popconfirm
                placement="leftTop"
                title={"Delete"}
                description={"Are you sure to delete this holiday?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDeleteHoliday(item)}
              >
                <Button danger type="primary" icon={<CloseOutlined />} />
              </Popconfirm>
            </Space>
          </div>
        );
      },
    },
  ];
  const handleNewHoliday = () => {
    form.resetFields();
    setIsModalVisible(true);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <GetLoading />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ថ្ងៃឈប់សម្រាក")}
            <div>
              <Input></Input>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleNewHoliday}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <GetLoading />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}
      </div>

      <Modal
        title={id == null ? "បង្កើតថ្ងៃឈប់សម្រាកថ្មី" : "កែប្រែថ្ងៃឈប់សម្រាក"}
        visible={isModalVisible}
        onOk={handleAddNewHoliday}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical" style={{ marginTop: 20 }}>
          <Form.Item
            name="holiday_name_kh"
            label="ឈ្មោះថ្ងៃឈប់សម្រាក KH"
            rules={[
              { required: true, message: "សូមបញ្ចូលឈ្មោះថ្ងៃឈប់សម្រាក KH" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="holiday_name_eng"
            label="ឈ្មោះថ្ងៃឈប់សម្រាក EN"
            rules={[
              { required: true, message: "សូមបញ្ចូលឈ្មោះថ្ងៃឈប់សម្រាក EN" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="date_start"
            label="ថ្ងៃចាប់ផ្តើម"
            rules={[{ required: true, message: "សូមបញ្ចូលថ្ងៃចាប់ផ្តើម" }]}
          >
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item
            name="date_end"
            label="ដល់ថ្ងៃទី"
            rules={[{ required: true, message: "សូមបញ្ចូលដល់ថ្ងៃទី" }]}
          >
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default HolidayPage;
