import { Tag } from "antd";
import { IoCheckboxOutline, IoCheckmarkDoneOutline } from "react-icons/io5";

const boxHeader = {
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  justifyContent: "space-between", // You missed the "justifyContent" key
  padding: 5,
  fontFamily: "Content",
  fontSize: 11,
  backgroundColor: "#f8f9f9",
  borderRadius: 5,
  marginBottom: 10,
  border: "1px solid #e0e0e0",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // This line is good for shadow
};

const boxHeaderTagText = (value) => {
  return (
    <div
      color="#108ee9"
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 8,
        fontSize: 14,
        color: "#108ee9",
        fontWeight: "bold",
        justifyContent: "center",
        justifyItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#e5e7e9",
          padding: 5,
          borderRadius: 50,
          paddingRight: 20,
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#239b56",
            color: "white",
            width: 18,
            height: 18,
            borderWidth: " 1px solid gray",
            borderRadius: 22,
            marginRight: 5,
          }}
        >
          <IoCheckmarkDoneOutline />
        </div>
        {value}
      </div>
    </div>
  );
};
export { boxHeader, boxHeaderTagText };
