import { notification } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { IoWarningSharp } from "react-icons/io5";

export const openNotification = (type, message, description) => {
  let icon;
  let iconColor;
  let backgroundColor;

  switch (type) {
    case "success":
      icon = <CheckCircleOutlined size={36} />;
      iconColor = "#52c41a"; // Green color for success
      backgroundColor = "#f6ffed"; // Light green background
      break;
    case "error":
      icon = <CloseCircleOutlined size={36} />;
      iconColor = "#f5222d"; // Red color for error
      backgroundColor = "#fff1f0"; // Light red background
      break;
    case "warring":
      icon = <IoWarningSharp size={36} />;
      iconColor = "#c0392b"; // Red color for error
      backgroundColor = "#c0392b"; // Light red background
      break;
    case "remove":
      icon = <CloseOutlined size={36} />;
      iconColor = "#f5222d"; // Red color for error
      backgroundColor = "#fff1f0"; // Light red background
      break;
    default:
      icon = <InfoCircleOutlined size={36} />;
      iconColor = "#1890ff"; // Blue color for info
      backgroundColor = "#e6f7ff"; // Light blue background
  }

  notification[type]({
    message,
    description,
    icon: <span style={{ color: iconColor }}>{icon}</span>,
    style: {
      color: "#595959", // Text color
      fontWeight: "bold",
      fontFamily: "Content",

      background: backgroundColor,
      border: `0.5px solid ${backgroundColor}`, // Border matching the icon color
      borderRadius: "6px", // Rounded corners
    },
    placement: "topRight",
  });
};
