import React, { useEffect, useState } from "react";
import { request } from "../../share/request";
import { Select, Space } from "antd";

const { Option } = Select;

const Province = ({
  onSelect,
  province_name,
  city_name,
  commune_name,
  village_name,
}) => {
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listCom, setListCom] = useState([]);
  const [listVillage, setListVillage] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setCity] = useState(null);
  const [selectedCom, setCom] = useState(null);
  const [selectedVillage, setVillage] = useState(null);
  const [list_familyaddress, setListFamilyaddress] = useState([]);
  const [province_name_permanent, setProvinceName_permanent] = useState(null);

  // Fetch the list of provinces and family addresses
  const getListFmailyAddress = async () => {
    const param = new URLSearchParams({
      user_id: 1,
    });
    const res = await request(
      "userfamilyaddress?" + param.toString(),
      "get",
      {}
    );
    if (res) {
      console.table(res.api_user_familyaddress);
      setListFamilyaddress(res.api_user_familyaddress);
    }
  };

  // Fetch the list of provinces
  const getListProvince = async (idNumber = "0885290240") => {
    try {
      const res = await request("useraddresspro/" + idNumber, "get", {});
      if (res && res.API_Server_listProvince) {
        setListProvince(res.API_Server_listProvince);
      } else {
        console.error("Invalid API response for provinces:", res);
      }
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  // Fetch the list of cities based on the selected province
  const onChangeProvince = async (value) => {
    setSelectedProvince(value);
    setCity(null); // Reset city
    setCom(null); // Reset commune
    setVillage(null); // Reset village
    setListCity([]);
    setListCom([]);
    setListVillage([]);
    // Call onSelect to pass selected province to the parent
    onSelect("province", value);

    try {
      const res = await request("useraddresscity/" + value, "get", {});
      if (res && res.API_Server_listCity) {
        setListCity(res.API_Server_listCity);
      } else {
        console.error("Invalid API response for cities:", res);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Fetch the list of communes based on the selected city
  const onChangeCity = async (value) => {
    setCity(value);
    setCom(null); // Reset commune
    setVillage(null); // Reset village
    setListCom([]);
    setListVillage([]);
    // Call onSelect to pass selected city to the parent
    onSelect("city", value);

    try {
      const res = await request("useraddresscom/" + value, "get", {});
      if (res && res.API_Server_listCom) {
        setListCom(res.API_Server_listCom);
      }
    } catch (error) {
      console.error("Error fetching communes:", error);
    }
  };

  // Fetch the list of villages based on the selected commune
  const onChangeCom = async (value) => {
    setCom(value);
    setVillage(null); // Reset village
    setListVillage([]);
    // Call onSelect to pass selected commune to the parent
    onSelect("commune", value);

    try {
      const res = await request("useraddressvillage/" + value, "get", {});
      if (res && res.API_Server_listVillage) {
        setListVillage(res.API_Server_listVillage);
      } else {
        console.error("Invalid API response for villages:", res);
      }
    } catch (error) {
      console.error("Error fetching villages:", error);
    }
  };

  useEffect(() => {
    console.log(" province name is ", province_name);
    getListProvince();
    getListFmailyAddress();
    console.table(list_familyaddress);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Space>
        {/* Province Dropdown */}
        <div>
          <span style={{ marginLeft: 15 }}>រស់នៅខេត្ត ៖ </span>
          <Select
            value={province_name}
            style={{ width: "150px" }}
            placeholder="Select Province"
            onChange={onChangeProvince}
          >
            {listProvince.map((province) => (
              <Option key={province.id} value={province.id}>
                {province.province_name_khmer}
              </Option>
            ))}
          </Select>
        </div>

        {/* City Dropdown */}
        <div>
          <span style={{ marginLeft: 15 }}> ស្រុក/ក្រុង ៖ </span>
          <Select
            style={{ width: "150px" }}
            placeholder="Select City"
            onChange={onChangeCity}
            value={city_name}
            disabled={!selectedProvince} // Disable if no province is selected
          >
            {listCity.map((city) => (
              <Option key={city.city_code} value={city.city_code}>
                {city.city_name_khmer}
              </Option>
            ))}
          </Select>
        </div>

        {/* Commune Dropdown */}
        <div>
          <span style={{ marginLeft: 15 }}> ឃុំ/សង្កាត់ ៖ </span>
          <Select
            style={{ width: "150px" }}
            placeholder="Select Commune"
            onChange={onChangeCom}
            value={commune_name}
            disabled={!selectedCity} // Disable if no city is selected
          >
            {listCom.map((com) => (
              <Option key={com.com_code} value={com.com_code}>
                {com.com_name_khmer}
              </Option>
            ))}
          </Select>
        </div>

        {/* Village Dropdown */}
        <div>
          <span style={{ marginLeft: 15 }}> ភូមិ ៖ </span>
          <Select
            value={village_name}
            style={{ width: "150px" }}
            placeholder="Select Village"
            onChange={(value) => {
              setVillage(value);
              onSelect("village", value); // Call onSelect for village
            }}
            disabled={!selectedCom} // Disable if no commune is selected
          >
            {listVillage.map((village) => (
              <Option key={village.village_code} value={village.village_code}>
                {village.village_name_khmer}
              </Option>
            ))}
          </Select>
        </div>
      </Space>
    </div>
  );
};

export { Province };
