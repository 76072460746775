// export default Layout;
import { Outlet, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { IoPieChart } from "react-icons/io5";
import {
  LockOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  LogoutOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  IssuesCloseOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  UserOutlined,
  UnorderedListOutlined,
  SwapOutlined,
  FormOutlined,
  DownOutlined,
  ProfileFilled,
  ProfileTwoTone,
  UsergroupDeleteOutlined,
  FileDoneOutlined,
  ContactsOutlined,
  MailFilled,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import {
  Grid,
  Input,
  Form,
  Modal,
  Layout,
  Menu,
  Button,
  theme,
  Breadcrumb,
  Tag,
  Dropdown,
  Space,
  Badge,
  Avatar,
} from "antd";
import {
  getCurrentUser,
  getPermission,
  isGetLogin,
} from "../../share/getProfile";
import "../../styles/Home-module.css";
import "../../styles/FontKhmer.css";
import styels from "../../page/auth/LoginPage.module.css";
import { openNotification } from "../../share/message";
import { request } from "../../share/request";
import { Circles } from "react-loader-spinner";
import { linkPath } from "../../share/helper";
import { LiaUserCheckSolid } from "react-icons/lia";
import { BiBarChart } from "react-icons/bi";
import { FaCarOn } from "react-icons/fa6";
import { TbBrandMastercard, TbUsersGroup } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { MdOutlineWarehouse } from "react-icons/md";
import { FaPeopleCarry } from "react-icons/fa";
import { CiInboxOut } from "react-icons/ci";
import { PiTelegramLogoLight } from "react-icons/pi";
import { CiMedicalCase } from "react-icons/ci";
import { CiBank } from "react-icons/ci";
import { CiBadgeDollar } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import GetLoading from "../../share/getLoading";
// import { useTranslation } from "react-i18next";
const { Header, Sider, Content, Footer } = Layout;

function getItem(label, key, icon, disabled = false, children, type) {
  return {
    key,
    icon,
    disabled,
    children,
    label,
    type,
  };
}
const userProfile = getCurrentUser();

const userPermission = getPermission() || []; // Assuming getPermission() returns an array of user permissions

const staffValue = userPermission.find(
  (permission) => permission.pagename === "staff"
) || { pageshow: 0 };
const { pageshow: StaffValue } = staffValue;

const departmentValue = userPermission.find(
  (permission) => permission.pagename === "department"
) || { pageshow: 0 };
const { pageshow: DepartmentValue } = departmentValue;
const positionValue = userPermission.find(
  (permission) => permission.pagename === "position"
) || { pageshow: 0 };
const { pageshow: PositionValue } = positionValue;
const setupValue = userPermission.find(
  (permission) => permission.pagename === "setuptype"
) || { pageshow: 0 };
const { pageshow: SetupValue } = setupValue;
const leavereportValue = userPermission.find(
  (permission) => permission.pagename === "leavereport"
) || { pageshow: 0 };
const { pageshow: LeaveReportValue } = leavereportValue;
const staffreportValue = userPermission.find(
  (permission) => permission.pagename === "staffreport"
) || { pageshow: 0 };
const { pageshow: StaffReportValue } = staffreportValue;

const staffscheduleValue = userPermission.find(
  (permission) => permission.pagename === "staffschedule"
) || { pageshow: 0 };
const { pageshow: StaffScheduleValue } = staffscheduleValue;

const letterspageValue = userPermission.find(
  (permission) => permission.pagename === "letterspage"
) || { pageshow: 0 };
const { pageshow: LettersValue } = letterspageValue;
const skillpageValue = userPermission.find(
  (permission) => permission.pagename === "skillpage"
) || { pageshow: 0 };
const { pageshow: SkillValue } = skillpageValue;

const attendance = userPermission.find(
  (permission) => permission.pagename === "attendance"
) || { pageshow: 0 };
const { pageshow: AttendanceValue } = attendance;
const caractivity = userPermission.find(
  (permission) => permission.pagename === "caractivity"
) || { pageshow: 0 };
const { pageshow: CarActivityValue } = caractivity;
const rightmanager = userPermission.find(
  (permission) => permission.pagename === "rightmanager"
) || { pageshow: 0 };
const { pageshow: RightManagerValue } = rightmanager;

const roleValue = userPermission.find(
  (permission) => permission.pagename === "role"
) || { pageshow: 0 };
const { pageshow: RoleValue } = roleValue;

const caseValue = userPermission.find(
  (permission) => permission.pagename === "case"
) || { pageshow: 0 };
const { pageshow: CaseValue } = caseValue;

const bankValue = userPermission.find(
  (permission) => permission.pagename === "bankreport"
) || { pageshow: 0 };
const { pageshow: BankValue } = bankValue;

const CaseGraphic_Values = userPermission.find(
  (permission) => permission.pagename === "casegraphic"
) || { pageshow: 0 };
const { pageshow: CaseGraphicValue } = CaseGraphic_Values;

const CaseWeekendReport_Values = userPermission.find(
  (permission) => permission.pagename === "caseweekendreport"
) || { pageshow: 0 };
const { pageshow: CaseWeekendReportValues } = CaseWeekendReport_Values;

const Telegrambotsetting_Values = userPermission.find(
  (permission) => permission.pagename === "telegrambotsetting"
) || { pageshow: 0 };
const { pageshow: TelegramBotSettingValues } = Telegrambotsetting_Values;

const sub2 =
  StaffValue + DepartmentValue + PositionValue + SkillValue + SetupValue;
const sub3 = StaffReportValue + LeaveReportValue + AttendanceValue;
const sub2_addmin = CarActivityValue + LettersValue + RightManagerValue;

const sub4 = CaseValue + CaseWeekendReportValues + CaseGraphicValue;
const sub5 = BankValue;
const sub6 = CarActivityValue;
const sub7 = RoleValue;

const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

function LayoutMaster() {
  const { i18n, t } = useTranslation();
  const linklogo_kh = process.env.PUBLIC_URL + "/flag/kh.jpg";
  const linklogo_eng = process.env.PUBLIC_URL + "/flag/eng.png";

  const [isMobile, setIsMobile] = useState(false); // Track mobile view state
  const [linkLanguage, setLinkLanguage] = useState(linklogo_kh);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Mobile size
        setIsMobile(true);
        setCollapsed(true); // Collapse the menu by default on mobile
      } else if (window.innerWidth <= 1390) {
        // Tablet size
        setIsMobile(true);
        setCollapsed(true); // Optionally collapse the menu on tablet
      } else {
        // Desktop size
        setIsMobile(false);
        setCollapsed(false); // Ensure the menu is not collapsed on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the correct state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
      alert(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (!isGetLogin()) {
      window.location.href = "/login"; // if not login  it will go back to login page
    }
  });

  const items = [
    getItem(t("Dashboard.label"), "sub1", <MailOutlined />, false, [
      getItem(t("Dashboard.subItems.Home"), "1", <HomeOutlined />),
      getItem(t("Dashboard.subItems.Profile"), "24", <ContactsOutlined />),
      getItem(t("Dashboard.subItems.Attendance"), "25", <ContactsOutlined />),
    ]),
    sub2 &&
      getItem(t("Management.label"), "sub2", <AppstoreOutlined />, false, [
        StaffValue &&
          getItem(t("Management.subItems.Staff"), "2", <LiaUserCheckSolid />),
        DepartmentValue &&
          getItem(
            t("Management.subItems.Department"),
            "3",
            <AppstoreOutlined />
          ),
        PositionValue &&
          getItem(
            t("Management.subItems.Position"),
            "4",
            <AppstoreAddOutlined />
          ),
        SkillValue &&
          getItem(
            t("Management.subItems.Skill"),
            "12",
            <AppstoreAddOutlined />
          ),
        SetupValue &&
          getItem(
            t("Management.subItems.SetupType"),
            "5",
            <IssuesCloseOutlined />
          ),
      ]),
    sub2_addmin &&
      getItem(t("Administrative.label"), "sub2-1", <FormOutlined />, false, [
        CarActivityValue &&
          getItem(t("Administrative.subItems.CarMission"), "9", <FaCarOn />),
        LettersValue &&
          getItem(
            t("Administrative.subItems.MissionLetters"),
            "13",
            <SwapOutlined />
          ),
        RightManagerValue &&
          getItem(
            t("Administrative.subItems.RightManager"),
            "15",
            <TbUsersGroup />
          ),
      ]),
    sub3 &&
      getItem(t("Report.label"), "sub3", <UnorderedListOutlined />, false, [
        StaffReportValue &&
          getItem(t("Report.subItems.StaffReport"), "6", <PicLeftOutlined />),
        LeaveReportValue &&
          getItem(t("Report.subItems.LeaveReport"), "7", <PicRightOutlined />),
        AttendanceValue &&
          getItem(t("Report.subItems.Attendance"), "14", <BiBarChart />),
        StaffScheduleValue &&
          getItem(t("Report.subItems.StaffScheduler"), "16", <SlCalender />),
      ]),
    sub4 &&
      getItem(t("DataManagement.label"), "sub4", <FormOutlined />, false, [
        CaseValue &&
          getItem(
            t("DataManagement.subItems.CaseManager"),
            "20",
            <CiMedicalCase />
          ),
        CaseWeekendReportValues &&
          getItem(
            t("DataManagement.subItems.CaseWeekly"),
            "23",
            <CiMedicalCase />
          ),
        CaseGraphicValue &&
          getItem(t("DataManagement.subItems.CaseChart"), "22", <IoPieChart />),
      ]),
    sub5 &&
      getItem(t("AccountingManagement.label"), "sub7", <CiBank />, false, [
        BankValue &&
          getItem(
            t("AccountingManagement.subItems.BankingReport"),
            "21",
            <CiBadgeDollar />
          ),
      ]),
    sub6 &&
      getItem(
        t("WarehouseManagement.label"),
        "sub6",
        <MdOutlineWarehouse />,
        false,
        [
          CarActivityValue &&
            getItem(
              t("WarehouseManagement.subItems.StockIn"),
              "17",
              <FaPeopleCarry />
            ),
          CarActivityValue &&
            getItem(
              t("WarehouseManagement.subItems.StockOut"),
              "18",
              <CiInboxOut />
            ),
        ]
      ),
    sub7 &&
      getItem(t("Setting.label"), "sub5", <SettingOutlined />, false, [
        RoleValue &&
          getItem(t("Setting.subItems.Role"), "11", <UserOutlined />),
        TelegramBotSettingValues &&
          getItem(
            t("Setting.subItems.Management"),
            "19",
            <SafetyCertificateOutlined />
          ),
      ]),
  ];
  const onLogout = async () => {
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 600));
      localStorage.setItem("isLogin", null);
      localStorage.setItem("permission", null);
      window.location.href = "/login";
      // Clear all cookies
      window.localStorage.clear();
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const [visableUpdatePassword, setVisableUpdatePassword] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const onLinkPage = (routeName) => {
    // use for link to other page
    navigate(routeName); // /category , /login
  };

  if (!isGetLogin()) {
    return null; // if not login  it will go back to login page
  }

  const onClickMenu = (e) => {
    if (e.key == 1) {
      onLinkPage("/");
    } else if (e.key == 2) {
      onLinkPage("/user");
    } else if (e.key == 3) {
      onLinkPage("/department");
    } else if (e.key == 4) {
      onLinkPage("/position");
    } else if (e.key == 5) {
      onLinkPage("/setuptype");
    } else if (e.key == 6) {
      onLinkPage("/employee");
    } else if (e.key == 7) {
      onLinkPage("/leave");
    } else if (e.key == 8) {
      onLinkPage("/usersetting");
    } else if (e.key == 9) {
      onLinkPage("/caractivity");
    } else if (e.key == 10) {
      onLinkPage("/setting");
    } else if (e.key == 11) {
      onLinkPage("/rolepage");
    } else if (e.key == 12) {
      onLinkPage("/skillpage");
    } else if (e.key == 13) {
      onLinkPage("/letterspage");
    } else if (e.key == 14) {
      onLinkPage("/attendanceqrpage");
    } else if (e.key == 15) {
      onLinkPage("/rightmanager");
    } else if (e.key == 16) {
      onLinkPage("/staffschedule");
    } else if (e.key == 17) {
      onLinkPage("/stockin");
    } else if (e.key == 18) {
      onLinkPage("/stockout");
    } else if (e.key == 19) {
      onLinkPage("/telegrambot");
    } else if (e.key == 20) {
      onLinkPage("/case");
    } else if (e.key == 21) {
      onLinkPage("/bankingreport");
    } else if (e.key == 22) {
      onLinkPage("/casegraphic");
    } else if (e.key == 23) {
      onLinkPage("/weekendreport");
    } else if (e.key == 24) {
      onLinkPage("/profile");
    } else if (e.key == 25) {
      onLinkPage("/leaveuserprofile");
    }
  };
  const loginItem = [
    {
      label: "អំពីខ្ញុំ", // Correct translation key for Profile Settings
      key: "0",
      icon: <UserOutlined />, // Icon for Profile
      onClick: () => {
        onLinkPage("/profile");
      }, // Navigates to the profile page
    },
    {
      label: "ផ្លាស់ប្តូរលេខសង្ងាត់", // Translation key for Change Password
      key: "1",
      icon: <LockOutlined />, // Icon for Change Password
      onClick: () => setVisableUpdatePassword(true), // Opens the password update modal
    },
    {
      label: "ចាកចេញ", // Translation key for Logout
      key: "2",
      danger: true, // Marks this item as a danger action
      icon: <LogoutOutlined />, // Icon for Logout
      onClick: onLogout, // Logs the user out
    },
  ];

  const onClickLanguageKH = () => {
    setLinkLanguage(linklogo_kh);
    Handel_changeLanguage("kh");
  };
  const onClickLanguageEng = () => {
    setLinkLanguage(linklogo_eng);
    Handel_changeLanguage("en");
  };
  const ItemLanguage = [
    {
      label: "Khmer",
      key: "0",
      onClick: onClickLanguageKH,
    },

    {
      label: "English",
      key: "1",
      onClick: onClickLanguageEng,
    },
  ];

  const onFinish = async (values) => {
    try {
      var data = {
        username: userProfile.username,
        password: values.password,
        confirmPassword: values.passwordConfirm,
      };
      const res = await request("user/set-password", "post", data);
      // Simulate an asynchronous save operation (replace with your actual save logic)

      if (res.isSuccess) {
        setVisableUpdatePassword(false);
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        localStorage.setItem("isLogin", null || "");
        window.location.href = "/login";
      } else if (values.password != values.confirmPassword) {
        openNotification("error", " password is not match !");
      }
    } catch (error) {
      openNotification("error", " password is not match !");
    } finally {
      setLoadingSpin(false);
    }
  };
  const onClose = () => {
    setVisableUpdatePassword(false);
  };

  const Handel_changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <GetLoading />
          </div>
        )}
      </div>

      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <Menu
            className="ant-menu-label"
            theme="dark"
            hidden={items.disabled}
            style={{
              display: items.disabled ? "none" : "block",
            }}
            defaultSelectedKeys={["1"]}
            mode="inline"
            onClick={onClickMenu}
            items={items}
          />
        </Sider>
        <Layout style={{ backgroundColor: "#f8f9f9" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 50,
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                paddingTop: 10,
                width: 40,
                height: 40,
              }}
            />

            <div style={{ display: "flex" }}>
              <Dropdown
                menu={{
                  items: ItemLanguage,
                }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                    }}
                    className="styleTageStatusForm"
                  >
                    <Avatar className="ionavatarLaout" src={linkLanguage} />
                  </Space>
                </a>
              </Dropdown>

              <Dropdown
                menu={{
                  items: loginItem,
                }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                      paddingRight: 30,
                    }}
                    className="styleTageStatusForm"
                  >
                    <Badge count={5} size="small">
                      <Avatar
                        className="ionavatarLaout"
                        src={linkPath + userProfile.image}
                      />
                    </Badge>
                    <div
                      style={{
                        backgroundColor: "#e5e7e9",
                        padding: 5,
                        borderRadius: 50,
                        paddingRight: 20,
                      }}
                    >
                      <UserOutlined
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      />

                      {userProfile.fullname}
                      <DownOutlined />
                    </div>

                    {/* <Button onClick={onLogout} style={{height:'30px', marginTop:20,marginRight:10}} type="primary" shape="round" ghost icon={<LogoutOutlined />} > Logout </Button> */}
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          <Content
            style={
              {
                //  margin: '0 16px',
              }
            }
          >
            <div
              style={{
                padding: 10,
                minHeight: 360,
                background: colorBgContainer,
                height: "90vh",

                overflow: "auto",
              }}
            >
              <Content>
                <Outlet />
              </Content>
            </div>
          </Content>
          {/* Add Footer Here */}
          <Footer
            style={{
              textAlign: "center",
              fontSize: "8px",
              padding: "10px 50px",
            }}
          >
            © 2024 Dev Computing. All rights reserved. Tell: 088 5 290 240 / 089
            213 339
          </Footer>
        </Layout>
      </Layout>

      <Modal
        title="កែប្រែលេខសម្ងាត់ / Update Password "
        className={styels.textStyle}
        open={visableUpdatePassword}
        footer={[]}
        onCancel={onClose}
      >
        <div>
          <Form
            name="normal_login"
            onFinish={onFinish}
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              className={styels.textStyle}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className={styels.textStyle}
                prefix={<LockOutlined />}
                type="password"
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className={styels.textStyle}
                prefix={<LockOutlined />}
                type="password"
                placeholder="Comfirm New Password"
              />
            </Form.Item>

            <Form.Item>
              <div>
                <Button
                  className={styels.textStyle}
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>

          <div></div>
        </div>
      </Modal>
    </div>
  );
}

export default LayoutMaster;

{
  /*  */
}
