import React, { useEffect, useState } from "react";
import { request } from "../../share/request";
import { Circles } from "react-loader-spinner";
import {
  Alert,
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Pagination,
} from "antd";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";

import "../../styles/Home-module.css";
import {
  SettingOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { openNotification } from "../../share/message";
import { formateDateClient, formateDateSystem } from "../../share/helper";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const GroupOrganization = () => {
  const { Option } = Select;
  const [isCheck, setIsCheck] = useState(false);
  const [list_ManageGroup, setManageGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list_Organization, setListOrganization] = useState([]);
  const [get_list_group, setListGroup] = useState([]); // List for groups
  const [get_list_not_main_group, setListNotMainGroup] = useState([]); // List for groups
  const [openModelAddNew, setOpenModelAddNew] = useState(false);
  const [listProvince, setListProvince] = useState([]);
  // action on form
  const [ID, setID] = useState(null);
  const [value_group_name, setValueGroupName] = useState(null);
  const [value_group_des, setValueGroupDes] = useState(null);
  //---------
  // group organization
  const [master_group_id, setMasterGroupId] = useState(false);
  const [province_name, setProvinceName] = useState(null);
  const [openModel_ListOrganization, setOpenModel_ListOrganization] =
    useState(false);
  const [province_id, setprovince_id] = useState(null);
  const [listGroupByOrg, setListGroupByOrg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total_item, setTotalItem] = useState(0);
  const [objFilter, setObjFilter] = useState({
    group_id: null,
    org_code: null,
    province_name: null,
  });
  const [objFilter_MainGroup, setObjFilterMainGroup] = useState({
    group_id: null,
    province_id: null,
  });
  const itemsPerPage = 10;
  const [objFilterList, setObjFilterList] = useState({
    page: 1,
    itemPerPage: itemsPerPage,
    txt_search: "",
  });
  const getList = async (objFilterList) => {
    const param = new URLSearchParams({
      page: objFilterList.page,
      itemPerPage: objFilterList.itemPerPage,
      txt_search: objFilterList.txt_search,
    });
    const res = await request("settingorggroup?" + param.toString(), "", {});
    console.log("settingorggroup by province_name", res);
    if (res) {
      console.log("settingorggroup by province_name", res);
      setListOrganization(res.list_org_by_province);
      setListGroup(res.list_group[0] || []); // Set the groups here
      setTotalItem(res.list_group[1][0].totalCount);
      console.log(" total items count", total_item);
      setListProvince(res.listprovince);
    } else {
      console.log("Server is not responding");
    }
  };

  // settingorgmanagenot - maingroup;
  const getListNotMainGroup = async (objFilter_MainGroup) => {
    const param = new URLSearchParams({
      province_id: objFilter_MainGroup.province_id,
      group_id: objFilter_MainGroup.group_id,
    });
    const res = await request(
      "settingorgmanagenot-maingroup?" + param.toString(),
      "",
      {}
    );
    if (res) {
      console.log("this is list group is not main group : ", res);
      setListNotMainGroup(res.list_group);
    } else {
      console.log("Server is not responding");
    }
  };

  const getList_ManageGroup = async (ID) => {
    const param = new URLSearchParams({
      group_id: ID,
    });
    const res = await request(
      "settingorgmanagegroup?" + param.toString(),
      "",
      {}
    );
    if (res) {
      setManageGroup(res.manage_grop_list); // Set the groups here
    } else {
      console.log("Server is not responding");
    }
  };

  useEffect(() => {
    setLoading(true);
    getList(objFilterList).finally(() => setLoading(false));
  }, []);

  const onClickDeleteGroupOrg = async (item) => {
    const url =
      master_enable == 1 ? "settingorgmanagegroup" : "settingorgmultiple";

    const res = await request(
      `${url}?${new URLSearchParams({ delete_id: item.id }).toString()}`,
      "delete",
      {}
    );

    if (res) {
      message.success("Success! Organization removed from the group.");
      master_enable == 1
        ? getList_ManageGroup(ID)
        : getListOrgByOrganization(objFilter);
    } else {
      message.error("Error removing organization from the group.");
    }
  };

  const Column_ListByOrganization = [
    {
      key: "id",
      title: "លេខកូដ",
      dataIndex: "id",
      render: (value, item, index) => {
        return (
          <dvi>
            <span
              onClick={() => onClickDeleteGroupOrg(item)}
              style={{ cursor: "pointer" }}
            >
              <CiSquareMinus
                style={{ height: 30, width: 30, color: "rgb(162, 14, 41)" }}
              />
            </span>
            {index + 1}
          </dvi>
        );
      },
    },

    {
      key: "group_name",
      title: "ឈ្មោះ Group",
      dataIndex: "group_name",
    },
    {
      key: "org_name_kh",
      title: "ឈ្មោះ អង្គភាព",
      dataIndex: "org_name_kh",
    },
  ];
  const Column_GroupMangeGroup = [
    {
      key: "id",
      title: "លេខកូដ",
      dataIndex: "id",
      render: (value, item, index) => {
        return (
          <dvi>
            <span
              onClick={() => onClickDeleteGroupOrg(item)}
              style={{ cursor: "pointer" }}
            >
              <CiSquareMinus
                style={{ height: 30, width: 30, color: "rgb(162, 14, 41)" }}
              />
            </span>
            {index + 1}
          </dvi>
        );
      },
    },

    {
      key: "main_group_name",
      title: "Master Group ",
      dataIndex: "main_group_name",
    },

    // id
    // main_group_name
    // sub_group_name

    {
      key: "sub_group_name",
      title: "Sub-Group",
      dataIndex: "sub_group_name",
    },
  ];
  const ColumnOrganizations = [
    {
      key: "id",
      title: "លេខកូដ",
      dataIndex: "id",
    },

    {
      key: "province_name_khmer",
      title: "ខេត្ត",
      dataIndex: "province_name_khmer",
      render: (text, record, index) => {
        const color = record.type === 0 ? "red" : "blue";
        return (
          <div index={index}>
            {record.type == 0 ? (
              <Tag color={color}>{record.province_name_khmer}</Tag>
            ) : (
              <Tag color={color}>{record.province_name_khmer}</Tag>
            )}
          </div>
        );
      },
    },
    {
      key: "type",
      title: "Type Group",
      dataIndex: "type",
      render: (text, record, index) => {
        const color = record.type === 0 ? "red" : "blue";
        return (
          <div index={index}>
            {record.type == 0 ? (
              <Tag color={color}>Sub Group</Tag>
            ) : (
              <Tag color={color}>Master Group</Tag>
            )}
          </div>
        );
      },
    },

    {
      key: "groupname",
      title: "ឈ្មោះ Group",
      dataIndex: "group_name",
      render: (text, record, index) => {
        const color = record.type === 0 ? "red" : "blue";
        return (
          <div index={index}>
            {record.type == 0 ? (
              <Tag color={color}>{record.group_name}</Tag>
            ) : (
              <Tag color={color}>{record.group_name}</Tag>
            )}
          </div>
        );
      },
    },
    {
      key: "description",
      title: "បរិយាយអំពី Group",
      dataIndex: "description",
    },
    {
      key: "created_at",
      title: "Created Date",
      dataIndex: "created_at",
      render: (text, record, index) => {
        const color = record.type === 0 ? "red" : "blue";
        return <div index={index}>{formateDateSystem(record.created_at)}</div>;
      },
    },
    {
      key: "created_by",
      title: "Created By",
      dataIndex: "created_by",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => onOpenEditModel(record)}
          />
          <Button
            icon={<SettingOutlined />}
            type="primary"
            onClick={() => openModelListOrganization(record)}
          />
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            danger
            // onClick={() => onOpenModelDelete(record)}
          />
        </Space>
      ),
    },
  ];
  const [master_enable, setMasterEnable] = useState(0);
  const [group_name, setGroupName] = useState(null);
  const [group_id, setGroupId] = useState(null);
  const openModelListOrganization = async (record) => {
    setprovince_id(record.province_id);
    setGroupId(record.group_id);
    const newfilter = {
      ...objFilter,
      group_id: record.id,
      province_name: record.province_name_khmer,
    };

    const newObjFilter = {
      province_id: record.province_id,
      group_id: record.id,
    };
    setObjFilterMainGroup(newObjFilter);
    console.table(newObjFilter);
    setObjFilter(newfilter);
    console.table(newfilter);
    //   console.log("province_name_khmer: " + record.province_name_khmer);
    setListGroupByOrg([]);
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 600));
    try {
      setMasterEnable(record.type);
      setGroupName(record.group_name);
      console.log(" enable is " + record.type);

      setProvinceName(record.province_name_khmer);
      getListOrgByOrganization(newfilter);
      setID(record.id);
      console.log("openModelListOrganization: ", record.id);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setLoading(false);
      setOpenModel_ListOrganization(true);
    }
  };
  const onOpenEditModel = (record) => {
    setID(record.id);
    setprovince_id(record.province_id);
    setValueGroupName(record.group_name);
    setValueGroupDes(record.description);

    setOpenModelAddNew(true);
  };
  const onNew = () => {
    setIsCheck(false);
    setMasterGroupId(false);
    setID(null);
    setValueGroupName(null);
    setValueGroupDes(null);
    setOpenModelAddNew(true);
  };
  const StyleText_Label = {
    margin: 5,
    color: "darkgray",
    paddingTop: 10,
    fontFamily: "Content",
  };

  const onChange_group_name = (event) => {
    setValueGroupName(event.target.value);
  };
  const onChange_group_des = (event) => {
    setValueGroupDes(event.target.value);
  };
  const onSaveGroup = async () => {
    // Ensure required fields are not null
    if (!value_group_name || !value_group_des) {
      openNotification(
        "error",
        "Error",
        "Group name and description are required."
      );
      return;
    }

    const data = {
      id: ID,
      group_name: value_group_name,
      group_description: value_group_des,
      province_id: province_id,
      type: isCheck === false ? 0 : 1,
    };

    // Determine request method and success message
    const method = ID === null ? "post" : "put";
    const message =
      ID === null ? "Data Save is successful" : "Data Update is successful";

    setLoading(true); // Show loading spinner during the request

    try {
      const res = await request("settingorggroup", method, data);
      if (res && res.total == 1) {
        openNotification("error", "Error", res.message);
        getList(objFilterList);
      } else if (res.success == true) {
        openNotification("success", "success", res.message);
        setOpenModelAddNew(false);
        getList(objFilterList);
      }
    } catch (error) {
      console.error("Error in onSaveGroup:", error);
      openNotification(
        "error",
        "Error",
        error.response?.data?.message || "An unexpected error occurred."
      );
    } finally {
      setLoading(false);
    }
  };

  const onChangeProvinceName = (value) => {
    console.log("on change", value);
    setprovince_id(value);
  };

  const getListOrgByOrganization = async (objFilter) => {
    const param = new URLSearchParams({
      group_id: objFilter.group_id,
      org_code: objFilter.org_code,
      province_name: objFilter.province_name,
    });
    const res = await request(
      "settingorggroupbyorg?" + param.toString(),
      "get",
      {}
    );
    if (res) {
      setListGroupByOrg(res.listGroup_by_Org);
      setListOrgByProvince(res.api_org_by_province);
      console.log("setListGroupByOrg", res);
    }
  };
  const [listOrgByProvince, setListOrgByProvince] = useState([]);

  const [save_id_org, setSaveIDOrg] = useState(null);
  const onCLickOrganization = (value) => {
    getListOrgByOrganization(objFilter);
    console.log("lcick onCLickOrganization", value);
    setSaveIDOrg(value);
  };

  const onClickData = async () => {
    const url =
      master_enable == 1 ? "settingorgmanagegroup" : "settingorgmultiple";
    const data =
      master_enable == 1
        ? { main_group_id: ID, sub_group_id: sub_group_id }
        : { id_organization: save_id_org, group_id: ID };

    console.table(data);

    try {
      const res = await request(url, "post", data);

      if (res && res.success === true) {
        message.success(res.message);
        master_enable == 1
          ? getList_ManageGroup(ID)
          : getListOrgByOrganization(objFilter);
      } else if (res && res.success === false) {
        message.warning(res.message);
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.error("Save Error:", error);
    }
  };

  useEffect(() => {
    if (master_enable == 1) {
      getListNotMainGroup(objFilter_MainGroup);
      getList_ManageGroup(ID);
    } else {
      getListOrgByOrganization(objFilter);
    }
  }, [master_enable]);
  const [sub_group_id, setSubGroup_id] = useState(null);
  const onChangeSubGroup = (value) => {
    setSubGroup_id(value);
    console.log("onChangeSubGroup", sub_group_id);
    getListNotMainGroup(objFilter_MainGroup);
  };
  const onChangeCheckboxMasterGroup = (e) => {
    setIsCheck(e.target.checked);
    console.log("onChangeCheckbox  MasterGroup  =", isCheck);
  };
  const handlePageChange = async (pageNumber) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      setCurrentPage(pageNumber);
      const objTem = {
        ...objFilterList,
        page: pageNumber,
      };
      setObjFilterList(objTem);
      getList(objTem);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const onChangeSearch = (e) => {
    const KeyWork_Search = e.target.value;
    setCurrentPage(1);
    const objTemSearch = {
      ...objFilterList,
      txt_search: KeyWork_Search,
      page: 1,
    };

    setObjFilterList(objTemSearch);
    getList(objTemSearch);
  };

  return (
    <div>
      {loading && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ Group ")}
            <Input
              style={{ width: 200 }}
              onChange={onChangeSearch}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              className="buttonstylekhmer"
              //  disabled={!addvalue}
              onClick={onNew}
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>
        </div>
      </div>

      {/* Table for displaying groups */}
      <Table
        pagination={false}
        columns={ColumnOrganizations}
        size="small"
        dataSource={get_list_group} // Using list of groups here
        rowKey="id" // Ensures each row has a unique identifier
      />
      <Pagination
        style={{ marginTop: 5 }}
        onChange={handlePageChange}
        size="large"
        current={currentPage}
        pageSize={itemsPerPage}
        total={total_item}
      />
      <Modal
        open={openModelAddNew}
        title={ID == null ? "បង្កើតថ្មី" : "កែប្រែ"}
        footer={
          <div>
            <Space>
              <Button onClick={() => setOpenModelAddNew(false)}>Cancel</Button>
              <Button type="primary" onClick={onSaveGroup}>
                {ID == null ? "Save" : "Update"}
              </Button>
            </Space>
          </div>
        }
      >
        <div style={StyleText_Label}>
          <Tag color="blue" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Checkbox
              style={{ fontWeight: "bold" }}
              checked={isCheck}
              onChange={onChangeCheckboxMasterGroup}
            >
              Master Group
            </Checkbox>
          </Tag>
          <p style={StyleText_Label}>ឈ្មោះ ខេត្ត ៖ </p>
          <Select
            className="styleTageStatusForm"
            style={{ width: "100%" }}
            value={province_id}
            onChange={onChangeProvinceName}
            allowClear
          >
            {listProvince.map((record) => (
              <Option
                key={record.id}
                value={record.id}
                className="styleTageStatusForm"
              >
                {record.province_name_khmer}
              </Option>
            ))}
          </Select>
          <p style={StyleText_Label}>ឈ្មោះ Group ៖ </p>
          <Input
            className="styleTageStatusForm"
            placeholder="input groupName"
            value={value_group_name}
            onChange={onChange_group_name}
            style={{ height: 40 }}
          />

          <p style={({ marginTop: 15 }, StyleText_Label)}>បរិយាយអំពី Group ៖</p>
          <Input
            className="styleTageStatusForm"
            value={value_group_des}
            onChange={onChange_group_des}
            placeholder="input group desction"
            style={{ height: 40 }}
          />
        </div>
      </Modal>

      <Modal
        width={700}
        open={openModel_ListOrganization}
        title={ID == null ? "បង្កើតថ្មី" : "កែប្រែ " + group_name}
        footer={
          <div>
            <Space>
              <Button onClick={() => setOpenModel_ListOrganization(false)}>
                Cancel
              </Button>
            </Space>
          </div>
        }
      >
        <div>
          <Space style={{ paddingBottom: 5 }}>
            <span onClick={onClickData} style={{ cursor: "pointer" }}>
              <CiSquarePlus
                style={{ height: 40, width: 40, color: "rgb(11, 145, 112)" }}
              />
            </span>

            <div style={{ width: "100%" }}>
              {master_enable == 0 && (
                <Select
                  // value={province_id}
                  onChange={onCLickOrganization}
                  style={{ width: "400px" }}
                  placeholder={"Select Organization"}
                  allowClear
                  showSearch
                >
                  {listOrgByProvince.map((item) => (
                    <Option
                      key={item.org_code}
                      value={item.org_code}
                      className="styleTageStatusForm"
                    >
                      {item.province_name} | {item.org_name_kh}
                    </Option>
                  ))}
                </Select>
              )}
              {master_enable == 1 && (
                <Select
                  // value={province_id}

                  onChange={onChangeSubGroup}
                  style={{ width: "400px" }}
                  placeholder={"Select Manage Groups"}
                  allowClear
                  showSearch
                >
                  {get_list_not_main_group.map((record) => (
                    <Option
                      key={record.id}
                      value={record.id}
                      className="styleTageStatusForm"
                    >
                      {record.province_name_khmer} | {record.group_name}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </Space>
          {master_enable == 0 && (
            <Table
              columns={Column_ListByOrganization}
              size="small"
              dataSource={listGroupByOrg} // Using list of groups here
              rowKey="id" // Ensures each row has a unique identifier
            />
          )}

          {master_enable == 1 && (
            <Table
              columns={Column_GroupMangeGroup}
              size="small"
              dataSource={list_ManageGroup} // Using list of groups here
              rowKey="id" // Ensures each row has a unique identifier
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default GroupOrganization;
