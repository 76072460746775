import axios from "axios";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { request } from "../../share/request";
import { Stack } from "react-bootstrap";

import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  onSearch,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Flex,
  Pagination,
} from "antd";
import "../../styles/Home-module.css";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { getTageStyle } from "../../share/getStatusContent";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
const { Option } = Select;
const DepartmentPage = () => {
  const getProfileOrg = getCurrentUser();
  const [Total, setTotalCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);

  const [visableNewDepartment, setVisableNewDepartment] = useState(false);
  const [visableDeleteModel, setVisableDeleteModel] = useState(false);
  const [visableTextbox, setVisableTextbox] = useState(false);
  const [id, setID] = useState();
  const [code, setCode] = useState("");
  const [department, setDepartment] = useState("");
  const [department_en, setDepartment_EN] = useState("");
  const [des, setDes] = useState("");
  const [other, setOther] = useState("");
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const [objFilter, setObjFilter] = useState({
    page: 1,
    department_name: "",
    org_code: getProfileOrg.org_code,
  });

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const deapartmentValue = userPermission.find(
    (permission) => permission.pagename === "department"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = deapartmentValue;
  // -----------------  end permisstion page --------------------

  useEffect(() => {
    // block form load
    getList(objFilter);
    if (OnSearchText == undefined) {
      setCurrentPage(1);
    }
  }, []);

  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page || 1, // Default to page 1 if not provided
      department_name: objFilter.department_name || "", // Ensure name consistency
      org_code: objFilter.org_code || 1, // Default org_code to 1 if not provided
    });

    console.log("Parameters for department:", param.toString());

    try {
      // Optional: Simulate a delay if testing loading states
      await new Promise((resolve) => setTimeout(resolve, 400));

      const res = await request("department?" + param.toString(), "get", {});

      if (res) {
        console.log("Response data:", res); // Check the response
        setList(res.listdepartment);

        const Total = res.total_records; // Ensure this is directly accessed
        console.log("Total records:", Total); // Log it for debugging
        setTotalCount(Total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const buttonStyle = {
    fontFamily: "Content", // Replace 'YourFontFamily' with your desired font family
  };
  const custom_model = {
    fontFamily: "Content", // Replace 'YourFontFamily' with your desired font family
  };
  const onOpenNewDepartment = () => {
    setVisableTextbox(true);
    setVisableNewDepartment(true);
    setCode(null);
    setDepartment("");
    setDepartment_EN("");
    setDes("");
    setOther("");
  };
  const onCloseDepartment = () => {
    setVisableNewDepartment(false);
  };

  const divStyle = {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "start",
  };

  const onChangeCode = (event) => {
    setCode(event.target.value);
  };
  const onChangeDeparment = (event) => {
    setDepartment(event.target.value);
  };
  const onChangeDeparment_en = (event) => {
    setDepartment_EN(event.target.value);
  };
  const onChageDes = (event) => {
    setDes(event.target.value);
  };

  const onChangeOther = (event) => {
    setOther(event.target.value);
  };

  const onSaveDepartment = async () => {
    if (code === null) {
      var data = {
        code: code,
        department_name: department,
        department_en: department_en,
        department_des: des,
        other: other,
        status: enable == true ? 1 : disable == true ? 0 : 1,
        org_code: getProfileOrg.org_code,
      };

      try {
        const res = await request("department", "post", data);
        setVisableNewDepartment(false);

        if (res && res.success) {
          console.log(res.success);
          getList(objFilter); // re call function list
          setLoadingSpin(true);
          await new Promise((resolve) => setTimeout(resolve, 400));
          openNotification("success", "Success", res.message);
        } else {
          openNotification(
            "error",
            "Error",
            res.message || "Something went wrong"
          );
        }
      } catch (error) {
        console.error("Error:", error);
        openNotification("error", "Error", "Something went wrong");
      } finally {
        setLoadingSpin(false);
      }
    } else {
      var data = {
        code: code,
        department_name: department,
        department_en: department_en,
        department_des: des,
        other: other,
        status: enable == true ? 1 : disable == true ? 0 : 1,
      };

      try {
        const res = await request("department", "put", data);
        setVisableNewDepartment(false);

        if (res && res.success) {
          getList(objFilter);
          setLoadingSpin(true);
          await new Promise((resolve) => setTimeout(resolve, 400));
          openNotification("success", "Success", "Update Success");
          // re call function list
        } else {
          openNotification(
            "error",
            "Error",
            res.message || "Something went wrong"
          );
        }
      } catch (error) {
        console.error("Error:", error);
        openNotification("error", "Error", "Something went wrong");
      } finally {
        setLoadingSpin(false);
      }
    }
  };

  const onOpenEditModel = (item) => {
    setVisableTextbox(true);
    setVisableNewDepartment(true);

    setCode(item.code);
    setDepartment(item.department_name);
    setDepartment_EN(item.department_en);
    setDes(item.department_des);
    setOther(item.other);
    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };
  const onclickDeleteNo = () => {
    setVisableDeleteModel(false);
  };

  const onOpenModelDelete = (item) => {
    setCode(item.code);
    setDepartment(item.department_name);
    setVisableDeleteModel(true);
  };
  const onClickDeleteYes = async () => {
    console.log("ID delete is : " + code);
    const res = await request("department/" + code, "delete", {});
    if (res) {
      getList(objFilter);
    } else {
      alert("Error!");
    }
    setVisableDeleteModel(false);
  };

  const getStatusContent = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            Enable{" "}
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            Disable{" "}
          </Button>
        );

      default:
        return null;
    }
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------
  const onCancel = () => {
    setVisableNewDepartment(false);
  };
  const onCancelDelete = () => {
    setVisableDeleteModel(false);
  };
  const [pageNumber, setPageNumber] = useState(1);
  const onShowSizeChange = async (current) => {
    console.log(current);
    setPageNumber(current);
    try {
      setLoadingSpin(true);
      var objTmp = {
        ...objFilter,
        page: current,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const OnSearchText = (value) => {
    setCurrentPage(1); // Reset to page 1 on search
    try {
      var objTmp = {
        ...objFilter,
        department_name: value.target.value, // Set the department name
        page: value.target.value === "" ? 1 : objFilter.page, // Set page to 1 if input is empty, otherwise retain current page
      };
      setObjFilter(objTmp); // Update the filter state
      getList(objTmp); // Fetch the updated list
    } catch (error) {
      console.error("Error in OnSearchText:", error); // Log any errors
    }
  };

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {boxHeaderTagText("ការគ្រប់គ្រង់ ព៏តមានផ្នែក")}
          <Input
            style={{ width: "auto" }}
            placeholder="Search..."
            onChange={OnSearchText}
            allowClear
          />
        </div>

        <div style={{ display: "flex", justifyContent: "start" }}>
          <Button
            style={buttonStyle}
            disabled={!addvalue}
            onClick={onOpenNewDepartment}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            បន្ថែមផ្នែកថ្មី
          </Button>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            //   striped
            //   bordered
            //   hover

            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-success">
                <th>No</th>
                <th>លេខកូដផ្នែក Code</th>
                <th>ផ្នែក Khmer</th>
                <th>ផ្នែក English</th>
                <th>បរិយាយ</th>
                <th>ផ្សេងៗ</th>
                <th>ស្ថានភាព</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{10 * (pageNumber - 1) + (index + 1)}</td>
                  <td>{getTageStyle("CODE:" + item.code)}</td>
                  <td>{item.department_name}</td>
                  <td>{item.department_en}</td>
                  <td>{item.department_des}</td>
                  <td>{item.other}</td>
                  <td>{getStatusContent(item.status)}</td>

                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        type="primary"
                        disabled={!Editvalue}
                        onClick={() => onOpenEditModel(item)}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        disabled={!deletevalue}
                        onClick={() => onOpenModelDelete(item)}
                        type="primary"
                        danger
                      >
                        Delete
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          //   showSizeChanger
          onChange={onShowSizeChange}
          defaultCurrent={currentPage}
          total={Total}
        />
      </div>
      <Modal
        title={code == null ? "បង្កើតផ្នែកថ្មី" : "កែប្រែផ្នែក"}
        style={custom_model}
        width={580}
        open={visableNewDepartment}
        onCancel={onCancel}
        footer={[
          <div>
            <Space>
              <Button type="primary" onClick={onCloseDepartment}>
                Cancel
              </Button>
              <Button type="primary" onClick={onSaveDepartment}>
                {code == null ? "Save" : "Update"}
              </Button>
            </Space>
          </div>,
        ]}
      >
        <Form style={buttonStyle}>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                លេខកូដផ្នែក /Deparment Code
              </p>
              <Input
                className="styleTageStatusForm"
                placeholder="input department"
                value={code}
                onChange={onChangeCode}
                disabled={visableTextbox}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                ឈ្មោះផ្នែក / ភាសាខ្មែរ
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder="input department"
                value={department}
                rows={2}
                onChange={onChangeDeparment}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                ឈ្មោះផ្នែក / English
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                placeholder="input department"
                value={department_en}
                rows={2}
                onChange={onChangeDeparment_en}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                ស្ថានភាព / Status
              </p>
              <Tag style={{ marginTop: 10 }}>
                <div style={{ padding: 10, width: 235 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                បរិយាយផ្នែក / Description
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={3}
                placeholder="input description"
                value={des}
                onChange={onChageDes}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Content",
                }}
              >
                ផ្សេងៗ / other
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={3}
                placeholder="input other"
                value={other}
                onChange={onChangeOther}
                style={{ width: 250 }}
              />
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="លុបផ្នែក "
        style={buttonStyle}
        open={visableDeleteModel}
        onCancel={onCancelDelete}
        footer={[
          <div>
            <Button onClick={onclickDeleteNo}>No</Button>
            <Button onClick={() => onClickDeleteYes()} type="primary">
              Yes
            </Button>
          </div>,
        ]}
      >
        <p style={buttonStyle}>តើអ្នកចង់លុប {department} នេះចេញពីប្រព័ន្ធ ?</p>
      </Modal>
    </div>
  );
};

export default DepartmentPage;
