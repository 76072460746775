import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Tabs, message, Avatar } from "antd";
import {
  IoCloudUploadOutline,
  IoCloudUploadSharp,
  IoIdCard,
} from "react-icons/io5";
import {
  ApiTwoTone,
  DeleteOutlined,
  IdcardOutlined,
  SettingOutlined,
  FileDoneOutlined,
  UserAddOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { getCurrentUser } from "../../share/getProfile";
import { request } from "../../share/request";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { linkPathIDCard } from "../../share/helper";
import BotTele from "../telegrambot/BotPage";
import UserSystem from "./UserSystem";
import LeaveTemplate from "./LeaveTemplate";
import CardProfilePhotoOrg from "./CardProfilePhotoOrg";
import GroupOrganization from "./GroupOrganization";
import SubGroup from "./SubGroup";
import GPSMapSettingPage from "./GPSMapSettingPage";

const { TabPane } = Tabs;

const MainOrgPage = ({ idorg, province_id }) => {
  const inputRefFront = useRef(null);
  const inputRefBack = useRef(null);

  const [fileFront, setFileFront] = useState(null);
  const [fileBack, setFileBack] = useState(null);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [IDFrontCard, setIDFrontCard] = useState();
  const [IDBackCard, setIDBackCard] = useState();

  const [imagePreviewFrontSide, setImagePreviewFrontSide] = useState(null);
  const [imagePreviewBackSide, setImagePreviewBackSide] = useState(null);
  const [list_role, setListRole] = useState([]);
  const getProfileOrg = getCurrentUser();

  useEffect(() => {
    setIDBackCard("");
    setIDFrontCard("");
    setImagePreviewFrontSide(null);
    setImagePreviewBackSide(null);
    if (idorg) {
      getImageLinkOrganization();
    }
  }, [idorg]);

  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: idorg });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        setListRole(res.list_role_api);
        const frontSide = res.list_api.find(
          (item) => item.param === "fs-idcard"
        );

        const backSide = res.list_api.find(
          (item) => item.param === "bs-idcard"
        );

        if (frontSide) {
          setImagePreviewFrontSide(linkPathIDCard + frontSide.imagelink);
          setIDFrontCard(frontSide.id);
        }

        if (backSide) {
          setImagePreviewBackSide(linkPathIDCard + backSide.imagelink);
          setIDBackCard(backSide.id);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  const handleFileChangeFrontSideCard = (e) => {
    try {
      const file = e.target.files[0];
      setFileFront(file);
      setImagePreviewFrontSide(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChangeBackSideCard = (e) => {
    try {
      const file = e.target.files[0];
      setFileBack(file);
      setImagePreviewBackSide(URL.createObjectURL(file));
    } catch (error) {
      console.error(error);
    }
  };

  const onHandleNew_Update_FrontSideCard = async () => {
    if (!fileFront) return;
    const formData = new FormData();
    formData.append("id", IDFrontCard);
    formData.append("org_code", idorg);
    formData.append("param", "fs-idcard");
    formData.append("image_name", fileFront.name);
    formData.append("create_by", getProfileOrg.fullname);
    formData.append("file_image_card", fileFront);

    setLoadingSpin(true);
    try {
      let method = ""; // Initialize method as let so we can reassign it
      method =
        IDFrontCard == null || IDFrontCard == undefined || IDFrontCard == ""
          ? "post"
          : "put";

      const res = await request("settingorgidcard", method, formData);
      if (res) {
        openNotification("success", "Success", "Front Side Saved Successfully");
        setFileFront(null);
        getImageLinkOrganization();
        // setImagePreviewFrontSide("");
      }
    } catch (error) {
      console.error("Save front file failed:", error);
      openNotification("error", "Error", "Save failed");
    } finally {
      setLoadingSpin(false);
    }
  };

  const onHandleNew_Update_BackSideCard = async () => {
    if (!fileBack) return;
    const formData = new FormData();
    formData.append("id", IDBackCard);
    formData.append("org_code", idorg);
    formData.append("param", "bs-idcard");
    formData.append("image_name", fileBack.name);
    formData.append("create_by", getProfileOrg.fullname);
    formData.append("file_image_card", fileBack);

    setLoadingSpin(true);
    try {
      let method = ""; // Initialize method as let so we can reassign it
      method =
        IDBackCard == null || IDBackCard == undefined || IDBackCard == ""
          ? "post"
          : "put";

      const res = await request("settingorgidcard", method, formData);
      if (res) {
        openNotification("success", "Success", "Back Side Saved Successfully");
        setFileBack(null);
        getImageLinkOrganization();
      }
    } catch (error) {
      console.error("Save back file failed:", error);
      openNotification("error", "Error", "Save failed");
    } finally {
      setLoadingSpin(false);
    }
  };
  const stylesCard = () => {
    return {
      width: 280,
      height: 450,
      border: "2px solid #ccc", // Adds a border
      borderRadius: "8px", // Rounds the corners
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Adds shadow
      padding: "10px", // Adds padding inside the container
      backgroundColor: "#fff",
    };
  };
  return (
    <div>
      {loadingSpin && (
        <div className="sprinSave">
          <Circles
            height="80"
            width="80"
            color="#2e86c1"
            ariaLabel="circles-loading"
            visible={true}
          />
        </div>
      )}
      <Tabs defaultActiveKey="1">
        <TabPane tab="ការកំណត់​ កាតបុគ្គលិក" key="1" icon={<IdcardOutlined />}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ padding: 10, display: "flex", flexDirection: "column" }}
            >
              <div
                className="khmer-font"
                style={{
                  color: "rebeccapurple",
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
                ក. កាតបុគ្គលិកខាងមុខ
              </div>
              {imagePreviewFrontSide ? (
                <img
                  src={imagePreviewFrontSide}
                  alt="Front Preview"
                  style={stylesCard()}
                />
              ) : (
                <di style={stylesCard()}>Loading....</di>
              )}
              <input
                type="file"
                ref={inputRefFront}
                style={{ display: "none" }}
                onChange={handleFileChangeFrontSideCard}
              />

              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {fileFront && (
                  <Button
                    style={{ margin: "10px" }}
                    type="primary"
                    icon={<IoCloudUploadOutline />}
                    onClick={onHandleNew_Update_FrontSideCard}
                  >
                    Save Front Side
                  </Button>
                )}

                <div>
                  <Space>
                    <Button
                      color="default"
                      variant="solid"
                      icon={<IoCloudUploadSharp />}
                      style={{}}
                      onClick={() => inputRefFront.current.click()}
                    >
                      Upload
                    </Button>
                    {imagePreviewFrontSide && (
                      <Button
                        color="danger"
                        variant="solid"
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </Space>
                </div>
              </div>
            </div>

            <div
              style={{ padding: 10, display: "flex", flexDirection: "column" }}
            >
              <div
                className="khmer-font"
                style={{
                  color: "rebeccapurple",
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
                ខ. កាតបុគ្គលិកខាងក្រោយ
              </div>
              {imagePreviewBackSide ? (
                <img
                  src={imagePreviewBackSide}
                  alt="Back Preview"
                  style={stylesCard()}
                />
              ) : (
                <div style={stylesCard()}>Loading....</div>
              )}
              <input
                type="file"
                ref={inputRefBack}
                style={{ display: "none" }}
                onChange={handleFileChangeBackSideCard}
              />
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {fileBack && (
                  <Button
                    style={{ margin: "10px", padding: "10px" }}
                    type="primary"
                    icon={<IoCloudUploadOutline />}
                    onClick={onHandleNew_Update_BackSideCard}
                  >
                    Save Back Side
                  </Button>
                )}
                <div>
                  <Space>
                    <Button
                      color="default"
                      variant="solid"
                      icon={<IoCloudUploadSharp />}
                      style={{}}
                      onClick={() => inputRefBack.current.click()}
                    >
                      Upload
                    </Button>
                    {imagePreviewBackSide && (
                      <Button
                        color="danger"
                        variant="solid"
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </Space>
                </div>
              </div>
            </div>
            <div
              className="khmer-font"
              style={{ padding: 10, display: "flex", flexDirection: "column" }}
            >
              <p style={{ color: "rebeccapurple", fontWeight: "bold" }}>
                គ. ទាញយកគំរូកាតបុគ្គលិក
              </p>
              <Button
                className="khmer-font"
                type="primary"
                onClick={() =>
                  window.open(linkPathIDCard + "idcard_simple.png")
                }
              >
                ទាញយក
              </Button>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab="អ្នកកាន់ប្រព័ន្ធ User System"
          key="2"
          icon={<UserAddOutlined />}
        >
          <UserSystem list_role={list_role} idorg={idorg} />
        </TabPane>
        <TabPane
          tab="ផ្លាស់ប្តូរទំរង់ច្បាប់​ (Leave-Tamplate)"
          key="3"
          icon={<FileDoneOutlined />}
        >
          <LeaveTemplate idorg={idorg} />
        </TabPane>
        <TabPane
          tab="ផ្លាស់ប្តូរអង្គភាព Prolfile Background"
          key="5"
          icon={<FileDoneOutlined />}
        >
          <CardProfilePhotoOrg idorg={idorg} />
        </TabPane>
        <TabPane tab="Telegram AutoBot" key="4" icon={<ApiTwoTone />}>
          <BotTele idorg={idorg} />
        </TabPane>
        <TabPane tab="Group Organization" key="6" icon={<GroupOutlined />}>
          <SubGroup province_id={province_id} org_code={idorg} />
        </TabPane>
        <TabPane tab="GPS Map Setting" key="7" icon={<GroupOutlined />}>
          <GPSMapSettingPage org_code={idorg} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MainOrgPage;
