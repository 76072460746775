import { useEffect, useState, useRef } from "react";
import Icon, {
  PlusCircleOutlined,
  CloseOutlined,
  DownloadOutlined,
  CheckOutlined,
  EditOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Space,
  Tag,
  DatePicker,
  Tabs,
  Avatar,
  Select,
  message,
} from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";

import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

import GetLoading from "../../share/getLoading";
import { getCurrentUser } from "../../share/getProfile";
import dayjs from "dayjs";
import { linkPathIDCard } from "../../share/helper";
import { QRCodeCanvas } from "qrcode.react"; // Import from qrcode.react
import { IoLocationOutline } from "react-icons/io5";
const { Option } = Select;

const UserQRSannPage = () => {
  const getProfile = getCurrentUser();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [totalItem, setTotalItem] = useState(0);
  const qrCodeRef = useRef(null);
  const [id, setID] = useState();
  const [listlocation, setListlocation] = useState([]);
  const [fullname, setFullname] = useState();
  const [frontname, setFrontname] = useState();
  const [locationname, setLocationame] = useState();
  const [objFiler, setObjFiler] = useState({
    page: 1,
    itemperpage: 5,
    text_search: "",
    org_code: getProfile.org_code,
  });
  const getList = async (objFiler) => {
    const param = new URLSearchParams({
      page: objFiler.page,
      itemperpage: objFiler.itemperpage,
      text_search: objFiler.text_search,
      org_code: objFiler.org_code,
    });
    try {
      const res = await request(
        "leaveuserqrbylocation?" + param.toString(),
        "get",
        {}
      );
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res && res.isOk === true) {
        setList(res.api_list_qrby_location);
        setTotalItem(res.totalitem);
        setListlocation(res.list_location);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getList(objFiler);
  }, []);

  // Function to generate random colors
  const getRandomColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return randomColor;
  };
  const downloadCanvasQRCode = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current; // Directly reference the canvas element
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpg", 1.0); // Get the image as a high-res PNG
      link.download = getProfile.fullname + "-QRCodeScannAttendan -" + ".png"; // Set the file name for download
      link.click();
    }
  };
  const columns = [
    {
      key: "id",
      title: "ល.រ",
      dataIndex: "id",
    },

    {
      key: "fullname",
      title: "ពត៍មានបុគ្គលិក | information",
      dataIndex: "fullname",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "100%",
              margin: "5px",
              borderRadius: "8px",
              backgroundColor: "",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ padding: 10 }}>
                <Avatar
                  size={60}
                  src={linkPathIDCard + record.image}
                  style={{ marginBottom: 16 }}
                />
              </div>
              <div style={{ padding: 10, fontWeight: "bold", color: "gray" }}>
                {record.idnumber} | {record.fullname}
                <div>{record.department_name}</div>
                <div>{record.position_kh}</div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: "name_location",
      title: "ទីតាំង QR Code",
      dataIndex: "name_location",
      render: (text, item, index) => {
        return (
          <div>
            <Tag
              color="processing"
              style={{
                margin: 5,
                padding: 5,
                borderRadius: 20,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: "bold",
              }}
            >
              <CheckOutlined /> {/* Correct usage of CheckOutlined */}
              {item.name_location}
            </Tag>
            <div></div>
          </div>
        );
      },
    },
    {
      key: "lat",
      title: "លេខចំណុច | Pointer",
      dataIndex: "lat",
      render: (text, item, index) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", padding: 10 }}
          >
            <Tag
              color="success"
              style={{
                margin: 5,
                padding: 5,
                borderRadius: 20,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: "bold",
              }}
            >
              <IoLocationOutline /> Lat: {item.lat}
            </Tag>
            <Tag
              color="success"
              style={{
                margin: 5,
                padding: 5,
                borderRadius: 20,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: "bold",
              }}
            >
              <IoLocationOutline /> Lng: {item.lng}
            </Tag>
          </div>
        );
      },
    },

    {
      key: "full_link_qrcode",
      title: "QR Code",
      dataIndex: "full_link_qrcode",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <QRCodeCanvas
              ref={qrCodeRef} // Attach the ref directly to the QRCodeCanvas
              value={item.full_link_qrcode}
              style={{ height: "70px", width: "70px" }}
              size={500} // Set a large size for high resolution
              includeMargin={true} // Add margin to the QR code
              level="H" // Error correction level: High
              fgColor={getRandomColor()}
            />
            <Space>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                onClick={downloadCanvasQRCode}
                style={{
                  padding: 5,
                  borderRadius: 20,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: "bold",
                }}
              />
              <Button
                style={{
                  padding: 5,
                  borderRadius: 20,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                onClick={() => handleEditItem(item)}
                icon={<EditOutlined />}
                color="default"
                variant="solid"
              />
            </Space>
          </div>
        );
      },
    },
  ];

  const handleEditItem = (item) => {
    setIsModalVisible(true);
    setID(item.id);
    setFullname(item.fullname);
    setFrontname(item.frontname);
    setLocationame(item.location_id);
  };

  const handleNewHoliday = () => {
    form.resetFields();
    setIsModalVisible(true);
  };
  const handleChangeSearch = (e) => {
    const text = e.target.value;
    const newFilter = {
      ...objFiler,
      text_search: text,
      page: 1,
    };
    setObjFiler(newFilter);
    getList(newFilter);
  };

  const onChangePage = async (page) => {
    try {
      setLoadingSpin(true);
      var objTmp = {
        ...objFiler,
        page: page,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      await setObjFiler(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const handleSelectLocation = (value) => {
    setLocationame(value);
  };
  const handleUpdate = async () => {
    setLoadingSpin(true);
    const data = {
      id: id, // Replace `id` with the actual variable or state holding the user ID.
      location_id: locationname, // Replace `locationname` with the selected location ID.
    };
    console.table(data);
    setIsModalVisible(false);
    const res = await request("leaveuserqrbylocation", "put", data);
    console.log(res);

    if (res && res.isOk) {
      await new Promise((resolve) => setTimeout(resolve, 400));
      message.success("Update Successfully!");
      getList(objFiler);
      setLoadingSpin(false);
    } else {
      message.error("Can't update at this time!");
    }
  };

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <GetLoading />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ទីតាំងស្កេន QR Code")}
            <div>
              <Input
                placeholder="ស្វែងរក ...."
                allowClear
                onChange={handleChangeSearch}
              />
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleNewHoliday}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <GetLoading />
          </div>
        ) : (
          <Table
            pagination={{
              total: totalItem, // total record data from database server
              pageSize: 5,
              onChange: onChangePage,
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}

        <Modal
          title={"កែប្រែ"}
          open={isModalVisible}
          footer={[
            <Space>
              <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
              <Button onClick={handleUpdate}>Updae</Button>
            </Space>,
          ]}
        >
          {frontname} {fullname} <ArrowRightOutlined />
          {"    ​ "}
          {/* <p>{listlocation[0]?.name_location}</p>  it work  */}
          <Select
            name={locationname}
            value={locationname}
            onSelect={handleSelectLocation}
            style={{ width: 300 }}
          >
            {listlocation.map((item, index) => (
              <Option value={item.id} key={index}>
                {item.name_location}
              </Option>
            ))}
          </Select>
        </Modal>
      </div>
    </div>
  );
};
export default UserQRSannPage;
