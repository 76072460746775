import React, { useRef } from "react";
import "../../styles/Home-module.css";
import { linkPathReport, linkPathIDCard } from "../../share/helper";
import { formateDateClient } from "../../share/helper";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { getCurrentUser } from "../../share/getProfile";
export default class ComponentToPrint extends React.Component {
  render() {
    const textX = 212; // Adjust X coordinate as needed
    const textY = 305; // Adjust Y coordinate as needed
    const textX_Gender = 350;
    const textX_ID = 460;
    const textX_Position = 615;
    const textY2 = 335;
    const textX_RHName = 210;
    const textX_Date = 430;
    const OrgProfile = getCurrentUser();
    const {
      name,
      gender,
      idnumber,
      position_p,
      phone_p,
      date_request_p,
      date_start_p,
      date_end_p,
      date_work_p,
      reason_p,
      Num_using,
      Num_Remaining,
      Num_Request,
      Num_using_short,
      Num_Remaining_short,
      Num_Request_short,
      Num_Request_Maternity,
      Num_using_Maternity,
      Num_Remaining_Maternity,
      Num_Request_Medical,
      Num_using_Medical,
      Num_Remaining_Medical,
      Num_using_person,
      Num_Remaining_person,
      Num_Request_person,
      template_org,
      count_day_request,
      Org_ImagePath,
    } = this.props;
    const printpath = (value) => {
      if (value > 0) {
        var imagebackground = linkPathIDCard + template_org;
      } else {
        var imagebackground = linkPathIDCard + template_org;
      }
      return imagebackground;
    };
    const ProfileOrganization = getCurrentUser();
    return (
      <div className="image-container-report">
        <img
          src={printpath(
            Num_Request || Num_Request_short || Num_Request_person
          )}
          alt="Leave Report"
          className="print-image"
        />
        <div className="text-overlay" style={{ top: textY, left: textX }}>
          <p className="text-on-image-name">{name}</p>
        </div>

        <div className="text-overlay" style={{ top: 60, left: 30 }}>
          <p className="text-on-image-name">
            <img
              src={Org_ImagePath}
              alt="Organization Logo"
              style={{ width: "70px", height: "70px" }}
            />
            {OrgProfile.org_type <= 3 && (
              <span>
                <br /> រដ្ឋបាលខេត្ត{OrgProfile.province_name}
              </span>
            )}
            {OrgProfile.org_type >= 4 && (
              <span>
                <br /> មន្ទីរសុខាភិបាលនៃរដ្ឋបាលខេត្ត
              </span>
            )}
            {OrgProfile.org_type != 1 && (
              <span>
                <br /> {OrgProfile.org_cover_by}
              </span>
            )}
            <br /> {OrgProfile.org_name_kh} <br />
            {"លេខ៖.........."}
          </p>
        </div>

        <div
          className="text-overlay"
          style={{ top: textY, left: textX_Gender }}
        >
          <p className="text-on-image-name">{gender}</p>
        </div>
        <div className="text-overlay" style={{ top: textY, left: textX_ID }}>
          <p className="text-on-image-name">{idnumber}</p>
        </div>
        <div
          className="text-overlay"
          style={{ top: textY, left: textX_Position }}
        >
          <p className="text-on-image-name">{position_p}</p>
        </div>
        <div
          className="text-overlay"
          style={{ top: textY2, left: textX_RHName }}
        >
          <p className="text-on-image-name">
            {ProfileOrganization.org_name_kh}
          </p>
        </div>
        <div className="text-overlay" style={{ top: textY2, left: textX_ID }}>
          <p className="text-on-image-name">{phone_p}</p>
        </div>
        <div className="text-overlay" style={{ top: 460, left: textX_Date }}>
          <p className="text-on-image-name">{count_day_request}</p>
        </div>
        <div className="text-overlay" style={{ top: 487, left: textX_Date }}>
          <p className="text-on-image-name">{date_start_p}</p>
        </div>
        <div className="text-overlay" style={{ top: 512, left: textX_Date }}>
          <p className="text-on-image-name">{date_end_p}</p>
        </div>
        <div className="text-overlay" style={{ top: 537, left: textX_Date }}>
          <p className="text-on-image-name">{date_work_p}</p>
        </div>
        <div className="text-overlay" style={{ top: 562, left: textX_RHName }}>
          <p className="text-on-image-name">{reason_p}</p>
        </div>
        <div
          className="text-overlay"
          style={{ top: 595, left: textX_RHName + 320 }}
        >
          <p className="text-on-image-name" style={{ fontSize: 13 }}>
            {date_request_p}
          </p>
        </div>
        <div
          className="text-overlay"
          style={{ top: 645, left: textX_RHName - 90 }}
        >
          <p className="text-on-image-name" style={{ fontSize: 13 }}>
            {OrgProfile.province_name} {date_request_p}
          </p>
        </div>
        {/* ចំពោះ ច្បាប់ប្រចាំឆ្នាំ */}
        <div className="text-overlay" style={{ top: 865, left: 180 }}>
          <p className="text-on-image-name">{Num_Remaining}</p>
        </div>
        <div className="text-overlay" style={{ top: 890, left: 180 }}>
          <p className="text-on-image-name"> {Num_Request}</p>
        </div>
        <div className="text-overlay" style={{ top: 915, left: 180 }}>
          <p className="text-on-image-name">{Num_using}</p>
        </div>
        {/* ចំពោះ ច្បាប់រយះពេលខ្លី */}
        <div className="text-overlay" style={{ top: 865, left: 260 }}>
          <p className="text-on-image-name">{Num_Remaining_short}</p>
        </div>
        <div className="text-overlay" style={{ top: 890, left: 260 }}>
          <p className="text-on-image-name">{Num_Request_short}</p>
        </div>
        <div className="text-overlay" style={{ top: 915, left: 260 }}>
          <p className="text-on-image-name">{Num_using_short}</p>
        </div>

        {/* ចំពោះ ច្បាប់ មាតុភាព */}
        <div className="text-overlay" style={{ top: 865, left: 390 }}>
          <p className="text-on-image-name">{Num_Remaining_Maternity}</p>
        </div>
        <div className="text-overlay" style={{ top: 890, left: 390 }}>
          <p className="text-on-image-name">{Num_Request_Maternity}</p>
        </div>
        <div className="text-overlay" style={{ top: 915, left: 390 }}>
          <p className="text-on-image-name">{Num_using_Maternity}</p>
        </div>
        {/* ចំពោះ ច្បាប់ ព្យាបាលជំងឺ */}
        <div className="text-overlay" style={{ top: 865, left: 510 }}>
          <p className="text-on-image-name">{Num_Remaining_Medical}</p>
        </div>
        <div className="text-overlay" style={{ top: 890, left: 510 }}>
          <p className="text-on-image-name">{Num_Request_Medical}</p>
        </div>
        <div className="text-overlay" style={{ top: 915, left: 510 }}>
          <p className="text-on-image-name">{Num_using_Medical}</p>
        </div>
        {/* ចំពោះ ច្បាប់ មានកិច្ចការផ្ទាល់ខ្លួន */}
        <div className="text-overlay" style={{ top: 865, left: 650 }}>
          <p className="text-on-image-name">{Num_Remaining_person}</p>
        </div>
        <div className="text-overlay" style={{ top: 890, left: 650 }}>
          <p className="text-on-image-name">{Num_Request_person}</p>
        </div>
        <div className="text-overlay" style={{ top: 915, left: 650 }}>
          <p className="text-on-image-name">{Num_using_person}</p>
        </div>
        <div className="text-overlay" style={{ top: 380, left: 160 }}>
          {Num_Request ? <BiCheckboxChecked /> : <BiCheckbox />}
        </div>
        <div className="text-overlay" style={{ top: 380, left: 332 }}>
          {Num_Request_short ? <BiCheckboxChecked /> : <BiCheckbox />}
        </div>

        <div className="text-overlay" style={{ top: 380, right: 220 }}>
          {Num_Request_Maternity ? <BiCheckboxChecked /> : <BiCheckbox />}
        </div>
        <div className="text-overlay" style={{ top: 405, left: 160 }}>
          {Num_Request_Medical ? <BiCheckboxChecked /> : <BiCheckbox />}
        </div>
        <div className="text-overlay" style={{ top: 405, left: 332 }}>
          {Num_Request_person ? <BiCheckboxChecked /> : <BiCheckbox />}
        </div>
        <div className="text-overlay" style={{ top: 405, left: 160 }}>
          <BiCheckbox />
        </div>
        <div className="text-overlay" style={{ top: 380, left: 557 }}>
          <BiCheckbox />
        </div>
      </div>
    );
  }
}
