import { useEffect, useState, useRef } from "react";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Table, Modal, Button, Input, Space, Tabs, message } from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import { getStatusContent, getTageStyle } from "../../share/getStatusContent";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

import { getCurrentUser } from "../../share/getProfile";

const LeaveTypeMaster = () => {
  const getProfile = getCurrentUser();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const param = new URLSearchParams({
      org_code: getProfile.org_code,
    });
    try {
      const res = await request("leavetype?" + param.toString(), "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setList(res.list_typeleave);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      key: "typ_id",
      title: "លេខកូដ",
      dataIndex: "typ_id",
    },
    {
      key: "type_Leave",
      title: "ប្រភេទឈប់សម្រាក",
      dataIndex: "type_Leave",
    },
    {
      key: "type_leave_eng",
      title: "ប្រភេទឈប់សម្រាក​ ENG",
      dataIndex: "type_leave_eng",
    },

    {
      key: "terget",
      title: "ចំនួនកំណត់",
      dataIndex: "terget",
    },
    {
      key: "usercreate",
      title: "អ្នកបង្កើត",
      dataIndex: "usercreate",
      render: getTageStyle,
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
    },

    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button type="primary" onClick={() => handleEdit(item)}>
                Edit
              </Button>
              <Button danger type="primary" icon={<CloseOutlined />} />
            </Space>
          </div>
        );
      },
    },
  ];

  const [openModel, setOpenModel] = useState(false);
  const [id, setID] = useState("");
  const [leave_typename_kh, setleave_typename_kh] = useState("");
  const [leave_typename_eng, setleave_typename_eng] = useState("");
  const [leave_value_kh, setleave_value_kh] = useState("");
  const [leave_value_eng, setleave_value_eng] = useState("");
  const handleEdit = (item) => {
    setOpenModel(true);
    setID(item.typ_id);
    setleave_typename_kh(item.type_Leave);
    setleave_typename_eng(item.type_leave_eng);
    setleave_value_kh(item.terget);
    setleave_value_eng(item.target_count);
  };

  const onchange_typename_kh = (e) => {
    setleave_typename_kh(e.target.value);
  };
  const onchange_typename_eng = (e) => {
    setleave_typename_eng(e.target.value);
  };
  const onchange_value_kh = (e) => {
    setleave_value_kh(e.target.value);
  };
  const onchange_value_eng = (e) => {
    setleave_value_eng(e.target.value);
  };
  const handleUpdate = async () => {
    const param = new URLSearchParams({
      id: id,
      typename_kh: leave_typename_kh,
      typename_eng: leave_typename_eng,
      value_kh: leave_value_kh,
      value_eng: leave_value_eng,
    });
    const res = await request("leavetype?" + param.toString(), "put", {});
    if (res && res.isOk == true) {
      message.success("កែប្រែ បានជោគជ័យ!");
      setOpenModel(false);
      getList();
    } else {
      message.success("សូមព្យាយាម នៅពេលក្រោយ!");
    }
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ប្រភេទច្បាប់")}
            <div>
              <Input></Input>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}
      </div>
      <Modal
        footer={[
          <Space>
            <Button key="cancel" onClick={() => setOpenModel(false)}>
              Cancel
            </Button>

            <Button type="primary" onClick={handleUpdate}>
              {"Update"}
            </Button>
          </Space>,
        ]}
        open={openModel}
        title={"កែប្រែ"}
      >
        <div style={{ marginTop: 20, fontFamily: "Content" }}>
          <span style={{ fontFamily: "Content" }}>
            ប្រភេទឈប់សម្រាក Khmer ៖​
          </span>
          <Input
            value={leave_typename_kh}
            style={{ fontFamily: "Content" }}
            onChange={onchange_typename_kh}
          />
        </div>
        <div style={{ marginTop: 20, fontFamily: "Content" }}>
          <span>ប្រភេទឈប់សម្រាក Englis ៖​</span>
          <Input
            value={leave_typename_eng}
            style={{ fontFamily: "Content" }}
            onChange={onchange_typename_eng}
          />
        </div>
        <div style={{ marginTop: 20, fontFamily: "Content" }}>
          <span> ចំនួនកំណត់ Khmer ៖​</span>
          <Input
            value={leave_value_kh}
            style={{ fontFamily: "Content" }}
            onChange={onchange_value_kh}
          />
        </div>
        <div style={{ marginTop: 20, fontFamily: "Content" }}>
          <span> ចំនួនកំណត់ English ៖​</span>
          <Input
            type="number"
            style={{ fontFamily: "Content" }}
            value={leave_value_eng}
            onChange={onchange_value_eng}
          />
        </div>
      </Modal>
    </div>
  );
};
export default LeaveTypeMaster;
